import { useCallback, useState } from "react";

import { Person } from "@mui/icons-material";
import { IconButton, Skeleton, Stack, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { useUser } from "./hooks";
import {
  fetchAllUsers,
  followUser,
  selectCurrentUser,
  unfollowUser,
} from "./usersSlice";

export function FollowButton({
  userId,
  onClick,
}: {
  userId: string;
  onClick: () => void;
}) {
  const user = useUser({ userId });
  const currentUser = useAppSelector(selectCurrentUser);
  const currentUserFollows =
    user && currentUser && currentUser.followedUsers[userId];
  const dispatch = useAppDispatch();
  const isCurrentUser = user?.id === currentUser?.id;
  const [error, setError] = useState<boolean>(false);
  const supabase = useClient();
  const handleApiError = useCallback((error: string) => {
    setError(true);
  }, []);

  if (!user || !currentUser) return <Skeleton width={80} height={40} />;

  const handleFollowUser = async () => {
    if (currentUser === null) return;
    onClick();
    if (currentUserFollows) {
      await dispatch(
        unfollowUser({
          errorCallback: handleApiError,
          successCallback: () => {},
          supabase,
          currentUser,
          userToUnfollow: user,
        }),
      );
    } else {
      await dispatch(
        followUser({
          errorCallback: handleApiError,
          successCallback: () => {},
          supabase,
          currentUser,
          userToFollow: user,
        }),
      );
    }

    // refetch the user to update them i hate this
    await dispatch(
      fetchAllUsers({
        users: [currentUser.id, user.id],
        supabase,
        successCallback: () => {},
        errorCallback: console.log,
      }),
    );
  };

  if (isCurrentUser) return <Typography variant="caption">(you)</Typography>;

  return (
    <Stack>
      <IconButton
        onClick={handleFollowUser}
        disabled={error}
        color={currentUserFollows ? "default" : "primary"}
      >
        <Person />
      </IconButton>
      <Typography
        variant="caption"
        color={currentUserFollows ? "default" : "primary"}
      >
        {currentUserFollows ? "Unfollow" : "Follow"}
      </Typography>
    </Stack>
  );
}
