import "../../common/Common.css";

import { useCallback, useRef, useState } from "react";
import { ViewportList, ViewportListRef } from "react-viewport-list";

import {
  FiberManualRecord,
  FiberManualRecordOutlined,
} from "@mui/icons-material";
import { Box, Stack } from "@mui/material";

import { PhotoMetadata } from "../photos";
import ExpandedUploadedPhoto from "../photos/ExpandedUploadedPhoto";

export function PostPhotoCarousel({ photos }: { photos: PhotoMetadata[] }) {
  const ref = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<ViewportListRef | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleAdvance = useCallback(() => {
    setCurrentIndex(Math.min(photos.length - 1, currentIndex + 1));
    listRef.current?.scrollToIndex({ index: currentIndex + 1 });
  }, [currentIndex, photos.length]);
  const handleBackpedal = useCallback(() => {
    setCurrentIndex(Math.max(0, currentIndex - 1));
    listRef.current?.scrollToIndex({ index: currentIndex - 1 });
  }, [currentIndex]);
  const handlers = {
    onSwipedRight: handleBackpedal,
    onSwipedLeft: handleAdvance,
  };
  if (!photos || photos.length === 0) return null;

  return (
    <>
      <Box
        className="scroll-container-y"
        ref={ref}
        sx={{
          position: "relative",
        }}
      >
        <ViewportList
          ref={listRef}
          viewportRef={ref}
          items={photos}
          axis="x"
          initialIndex={currentIndex}
        >
          {(photo) => {
            return (
              <div key={photo.id} style={{ position: "relative" }}>
                <ExpandedUploadedPhoto metadata={photo} handlers={handlers} />
                {photos.length > 1 && (
                  <Stack
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      backgroundColor: "rgba(0,0,0,0.5)",
                      width: "100vw",
                    }}
                    direction="row"
                    justifyContent="center"
                    gap={2}
                  >
                    {photos.map((_, index) => {
                      return index === currentIndex ? (
                        <FiberManualRecord
                          key={index}
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <FiberManualRecordOutlined
                          key={index}
                          sx={{ color: "white" }}
                        />
                      );
                    })}
                  </Stack>
                )}
              </div>
            );
          }}
        </ViewportList>
      </Box>
    </>
  );
}
