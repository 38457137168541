import { useEffect } from "react";

import { Divider, Stack } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { UserAvatar } from "./UserAvatar";
import { fetchAllUsers, selectAllUsers } from "./usersSlice";

import fuzzysort from "fuzzysort";

export function UserList({ searchText }: { searchText?: string }) {
  const rawUsers = useAppSelector(selectAllUsers);
  const matchingUsers =
    !searchText || searchText === ""
      ? rawUsers
      : fuzzysort
          .go(searchText, rawUsers, { key: "username" })
          .map((res) => res.obj);
  const dispatch = useAppDispatch();
  const supabase = useClient();

  useEffect(() => {
    dispatch(
      fetchAllUsers({
        supabase,
        successCallback: () => {},
        errorCallback: console.log,
      }),
    );
  }, [dispatch, supabase]);

  return (
    <Stack sx={{ paddingTop: 2 }}>
      {matchingUsers.map((u) => {
        if (u.username === null) return null;
        return (
          <Stack gap={2} sx={{ marginBottom: 2 }} key={u.id}>
            <Divider sx={{ width: "100%" }} />
            <Stack sx={{ width: "100%" }} alignItems="center">
              <UserAvatar userId={u.id} height={48} width={48} showName />
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
