import { createContext } from "react";

export type RecipeSelectionActions = {
  handleSelectRecipeId?: (recipeId: string) => void;
  handleDeselectRecipeId?: (recipeId: string) => void;
};

export const SelectableRecipeContext = createContext<RecipeSelectionActions>(
  {},
);

export const SelectableRecipeContextProvider = SelectableRecipeContext.Provider;
export const SelectableRecipeContextConsumer = SelectableRecipeContext.Consumer;
