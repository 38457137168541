import { useParams } from "react-router-dom";

import { Avatar, Chip, Stack, Typography } from "@mui/material";

import { PageLayout, useAppSelector } from "../common";
import {
  rouxBottomChromeHeightPixels,
  rouxTopChromeHeightPixels,
} from "../common/constants";
import {
  CalendarDayList,
  CalendarStats,
  CalendarTopChrome,
  useCalendar,
} from "../features/calendar";
import { CalendarFollowOrEditButton } from "../features/calendar/CalendarFollowOrEditButton";
import { usePublisher } from "../features/publishers";
import { selectCurrentUser } from "../features/users";

export function IndividualCalendarPage() {
  const params = useParams();
  const currentUser = useAppSelector(selectCurrentUser);
  const calendarId = params?.id ?? "unknown";
  const calendar = useCalendar({ calendarId });
  const publisher = usePublisher({
    publisherId: calendar?.publisher_id ?? "unknown",
  });

  const imageSource = !!publisher
    ? `http://www.google.com/s2/favicons?domain=${publisher.url}&sz=256`
    : calendar?.calendar_url ?? "";

  if (!calendar || !currentUser) return null;

  return (
    <PageLayout topChrome={<CalendarTopChrome calendarId={calendar.id} />}>
      <Stack
        sx={{
          overflow: "hidden",
          paddingTop: "12px",
          height: `calc(100vh - ${rouxTopChromeHeightPixels} - ${rouxBottomChromeHeightPixels})`,
        }}
      >
        <Stack
          sx={{
            // this is to prevent calendar recipe cards from going infront of the shadow
            zIndex: 1000,
            margin: 1,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            sx={{ width: 1 }}
          >
            <Avatar
              alt="logo"
              src={imageSource}
              sx={{ width: "120px", height: "120px" }}
            />
            <CalendarStats calendarId={calendarId} />
          </Stack>
          <Stack direction="row" sx={{ margin: 1 }}>
            <Typography variant="caption" sx={{ width: 1 }}>
              {calendar.description
                ? calendar.description
                : "No description yet"}
            </Typography>
            <CalendarFollowOrEditButton calendarId={calendarId} />
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            {calendar.is_private && <Chip label="Private" color="primary" />}
          </Stack>
        </Stack>
        <Stack
          sx={{
            height: 0.05,
            width: 1,
            boxShadow: "0 2px 2px grey",
            zIndex: 1000,
          }}
        />
        <CalendarDayList
          calendarIds={[calendarId]}
          hideCalendar
          hideCalendarControls
        />
      </Stack>
    </PageLayout>
  );
}
