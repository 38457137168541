import { useCallback, useState } from "react";

import { Button, Stack, Typography } from "@mui/material";

import { RouxModal, useAppSelector } from "../../common";
import { UserChip } from "./UserChip";
import { selectAllUsers } from "./usersSlice";

interface Props {
  isOpen: boolean;
  currentSelectedUsers: {
    [key: string]: boolean;
  };
  onSave: (editorsToBeAdded: { [key: string]: boolean }) => void;
  onClose?: {
    bivarianceHack(event: {}, reason: "backdropClick" | "escapeKeyDown"): void;
  }["bivarianceHack"];
}

export function UsersPicker({
  isOpen,
  onSave,
  onClose,
  currentSelectedUsers,
}: Props) {
  const users = useAppSelector(selectAllUsers).filter(
    (u) => !!!currentSelectedUsers[u.id],
  );
  const [editorsToBeAdded, setEditorsToBeAdded] = useState<{
    [key: string]: boolean;
  }>({});

  const addEditor = useCallback(
    async ({ userId }: { userId: string }) => {
      setEditorsToBeAdded({
        ...editorsToBeAdded,
        [userId]: !!!editorsToBeAdded[userId],
      });
    },
    [editorsToBeAdded, setEditorsToBeAdded],
  );

  return (
    <RouxModal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h6">Add Editors</Typography>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {users.map((u) => (
          <UserChip
            key={u.id}
            userId={u.id}
            onSelect={addEditor}
            toBeAdded={editorsToBeAdded[u.id]}
            adding
          />
        ))}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-around"
        sx={{ width: "80%" }}
      >
        <Button
          variant="contained"
          disabled={Object.values(editorsToBeAdded).length === 0}
          onClick={() => onSave(editorsToBeAdded)}
        >
          Add
        </Button>
        <Button
          onClick={(e) => {
            if (onClose) {
              onClose(e, "escapeKeyDown");
            }
          }}
        >
          Close
        </Button>
      </Stack>
    </RouxModal>
  );
}
