import "../../common/Common.css";

import { useRef } from "react";
import { ViewportList } from "react-viewport-list";

import { Box, Skeleton, Stack } from "@mui/material";

import { UserPhotosListItem } from "./UserPhotosListItem";
import { useUserPhotoMetadata } from "./hooks";

export function UserPhotosList({ userId }: { userId: string }) {
  const photosMetadata = useUserPhotoMetadata({ userId });
  const ref = useRef<HTMLDivElement | null>(null);

  if (!photosMetadata) return <Skeleton />;

  return (
    <Box
      className="scroll-container"
      ref={ref}
      onScroll={(event) => {
        const scrollTop = event.currentTarget.scrollTop;
        console.log(`scrollTop: ${scrollTop}`);
      }}
    >
      <ViewportList viewportRef={ref} count={photosMetadata.length}>
        {(index: number) => {
          // TODO - make this dymanic.
          return (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <UserPhotosListItem metadata={photosMetadata[index]} />
            </Stack>
          );
        }}
      </ViewportList>
    </Box>
  );
}
