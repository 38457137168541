// Keep in line with supabase-functions/_models/calendars.ts#Calendar
// Note there's a few things that are not in keeping.
// TODO: make CalendarAPI and remove editors and recipes.

export type CalendarSchema = {
  id: string;
  user_id: string | null;
  publisher_id: string | null;
  is_private: boolean;
  title: string;
  description: string;
  calendar_photo_url_fragment: string | null;
};

// Things that are client-only (for now...)
export type Calendar = CalendarSchema & {
  // TODO: rename "calendar_photo_url"
  calendar_url: string | null;

  editors: Array<string>;
  recipes: Array<string>;
  followers: Array<string>;
};

export function calendarToSchema({ calendar }: { calendar: Calendar }) {
  return {
    id: calendar.id,
    user_id: calendar.user_id,
    publisher_id: calendar.publisher_id,
    is_private: calendar.is_private,
    title: calendar.title,
    description: calendar.description,
    calendar_photo_url_fragment: calendar.calendar_photo_url_fragment,
  };
}

// TODO: move this to recipes or users.
export type ScheduledRecipe = {
  calendar_id: string;
  added_by: string;
  recipe_id: string;
  scheduled_date: string;
};
