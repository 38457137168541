import { createContext } from "react";

export type SupabaseConfiguration = {
  hostname: string;
};

export const SupabaseConfigContext = createContext<SupabaseConfiguration>({
  // NOTE: Expected to include "https://"
  hostname: "UNKNOWN",
});
export const SupabaseConfigContextProvider = SupabaseConfigContext.Provider;
export const SupabaseConfigContextConsumer = SupabaseConfigContext.Consumer;
SupabaseConfigContext.displayName = "SupabaseConfigContext";

export type EnvironmentConfiguration = {
  environment: string;
  feedback_form_url: string;
  hostname: string;
  sentry_url: string;
  supabase_url: string;
  release: string;
  is_prod: boolean;
};

export const EnvironmentContext = createContext<EnvironmentConfiguration>({
  environment: "UNKNOWN",
  feedback_form_url: "UNKNOWN",
  hostname: "UNKNOWN",
  sentry_url: "UNKNOWN",
  supabase_url: "UNKNOWN",
  release: "UNKNOWN",
  is_prod: false,
});
export const EnvironmentContextProvider = EnvironmentContext.Provider;
export const EnvironmentContextConsumer = EnvironmentContext.Consumer;
EnvironmentContext.displayName = "EnvironmentContext";
