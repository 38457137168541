import "./Recipes.css";

import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";

import { useAppSelector } from "../../common";
import { RecipeListItem } from "./RecipeListItem";
import { selectRecipes } from "./recipesSlice";

import fuzzysort from "fuzzysort";

export function SearchableSelectableRecipes({
  searchText,
}: {
  searchText?: string;
}) {
  const rawRecipes = Object.values(useAppSelector(selectRecipes));
  const recipes =
    !searchText || searchText === ""
      ? rawRecipes.sort(
          (a, b) =>
            new Date(b.addedTs ?? 0).valueOf() -
            new Date(a.addedTs ?? 0).valueOf(),
        )
      : fuzzysort
          .go(searchText, rawRecipes, { key: "title" })
          .map((res) => res.obj);

  if (recipes.length === 0) return <Skeleton />;

  return (
    <Stack gap={2} className="scroll-container" sx={{ height: "50vh" }}>
      {recipes.map((recipe) => {
        return <RecipeListItem recipeId={recipe.id} key={recipe.id} />;
      })}
    </Stack>
  );
}
