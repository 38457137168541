import React from "react";

import { Stack } from "@mui/material";

import { BottomChrome } from "../../features/chrome";
import { NuxModal } from "../../features/profile";
import { selectCurrentUser } from "../../features/users";
import {
  rouxBottomChromeHeight,
  rouxBottomChromeHeightPixels,
  rouxTopChromeHeightPixels,
} from "../constants";
import { useAppSelector } from "../hooks";

interface PageLayoutProps {
  topChrome: React.ReactNode;
  bottomChrome?: React.ReactNode;
  bottomChromeHeight?: number;
  children?: React.ReactNode;
}

export const PageLayout = ({
  topChrome,
  bottomChrome,
  bottomChromeHeight,
  children,
}: PageLayoutProps) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const invalidUser = currentUser && currentUser.username === null;

  return (
    <>
      <NuxModal isOpen={!!invalidUser} />
      <Stack alignItems="center">
        {topChrome}
        <Stack
          sx={{
            // Keep in line with {Top,Bottom}Chromes' heights.
            marginTop: rouxTopChromeHeightPixels,
            maxWidth: 1200,
            width: "100vw",
          }}
        >
          {children}
        </Stack>
        <Stack
          sx={{
            bottom: 0,
            height: bottomChromeHeight
              ? bottomChromeHeight + rouxBottomChromeHeight
              : rouxBottomChromeHeightPixels,
            position: "fixed",
            width: 1,
          }}
        >
          {bottomChrome}
          <BottomChrome />
        </Stack>
      </Stack>
    </>
  );
};
