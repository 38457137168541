import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowUpward, Comment, Share } from "@mui/icons-material";
import { IconButton, Skeleton, Stack, Typography } from "@mui/material";

import { getHumanTimestamp } from "../../common";
import { UserAvatar, useUser } from "../users";
import { RecipeComment } from "./types";

export function RecipeCommentListItem({ comment }: { comment: RecipeComment }) {
  const user = useUser({ userId: comment.user_id });
  const formattedDate = getHumanTimestamp({
    date: new Date(comment.added_ts),
  });

  const handleClick = useCallback(() => alert("Coming soon!"), []);
  const navigate = useNavigate();

  if (!user) return <Skeleton />;

  return (
    <Stack
      sx={{
        width: 1,
        padding: 1,
        backgroundColor: "white",
        marginBottom: "4px",
      }}
      gap={1}
      alignItems="center"
    >
      <Stack
        alignItems="center"
        direction="row"
        sx={{ width: 1 }}
        gap={1}
        justifyContent="start"
        onClick={() => navigate(`/profiles/${user.id}`)}
      >
        <UserAvatar userId={comment.user_id} width={32} height={32} />
        <Typography variant="caption">@{user.username}</Typography>
        <Typography variant="caption">{`${formattedDate}`}</Typography>
      </Stack>
      <Typography variant="body1" sx={{ width: 1 }}>
        {comment.comment}
      </Typography>
      <Stack direction="row" justifyContent="end" sx={{ width: 1 }}>
        <IconButton onClick={handleClick}>
          <ArrowUpward />
          <Typography variant="caption">(0)</Typography>
        </IconButton>
        <IconButton onClick={handleClick}>
          <Comment />
          <Typography variant="caption">(0)</Typography>
        </IconButton>
        <IconButton onClick={handleClick}>
          <Share />
          <Typography variant="caption">(0)</Typography>
        </IconButton>
      </Stack>
    </Stack>
  );
}
