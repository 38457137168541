import { useCallback, useState } from "react";

import { MoreHoriz } from "@mui/icons-material";
import {
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";

import {
  getHumanTimestamp,
  useAppDispatch,
  useAppSelector,
} from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { CalendarAvatar, useCalendar } from "../calendar";
import { EditCalendarPostDrawer } from "../calendar/EditCalendarPostDrawer";
import { UserAvatar, selectCurrentUser, useUser } from "../users";
import { usePost } from "./hooks";
import { deletePost } from "./postsSlice";
import { PostcardProps } from "./types";

export function PostTopChrome({ postId }: PostcardProps) {
  const post = usePost({ postId });
  const calendar = useCalendar({ calendarId: post?.calendarId });
  const postAuthor = useUser({ userId: post?.addedBy });
  const currentUser = useAppSelector(selectCurrentUser);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const isPostAuthor =
    postAuthor && currentUser && postAuthor.id === currentUser.id;
  const supabase = useClient();
  const dispatch = useAppDispatch();

  const humanReadableDate = post
    ? getHumanTimestamp({
        date: new Date(post.addedTs),
      })
    : "no_post";

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDeletePost = useCallback(() => {
    if (!post) return;
    dispatch(
      deletePost({
        supabase,
        post,
        errorCallback: console.log,
        successCallback: () => {},
      }),
    );
  }, [dispatch, supabase, post]);

  let avatar = null;
  let title = null;
  if (postAuthor) {
    avatar = <UserAvatar userId={postAuthor.id} width={24} height={24} />;
    title = `@${postAuthor.username}`;
  }

  if (calendar) {
    avatar = <CalendarAvatar calendarId={calendar.id} width={24} height={24} />;
    title = calendar.title;
  }

  if (!post) return <Skeleton />;

  return (
    <>
      <CardHeader
        avatar={avatar}
        title={title}
        action={
          isPostAuthor && (
            <IconButton
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreHoriz />
            </IconButton>
          )
        }
        subheader={
          <Typography variant="caption">
            {humanReadableDate}
            {post.addedTs !== post.updatedTs && ` (edited)`}
          </Typography>
        }
      />
      <EditCalendarPostDrawer
        post={post}
        open={showEditDrawer}
        onClose={() => setShowEditDrawer(false)}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: "45px" }}
        keepMounted
      >
        <MenuItem
          key="edit"
          onClick={() => {
            setShowEditDrawer(true);
            setAnchorEl(null);
          }}
        >
          <Typography textAlign="center">Edit</Typography>
        </MenuItem>
        <MenuItem key="delete" onClick={handleDeletePost}>
          <Typography textAlign="center">Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
