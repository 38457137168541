import "./CalendarRecipeCard.css";

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Event,
  FavoriteBorder,
  MoreVert,
  OpenInNew,
  Person,
} from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { removeRecipeFromDay } from ".";
import { TruncatedText } from "../../common";
import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { useRecipe } from "../recipes";
import { UserAvatar, fetchUser, selectCurrentUser, useUser } from "../users";
import { CalendarAvatar } from "./CalendarAvatar";
import { RescheduleRecipeModal } from "./RescheduleRecipeModal";
import { useCalendar } from "./hooks";
import { ScheduledRecipe } from "./types";

// TODO - just pass the whole scheduled recipe.
type Props = {
  recipeId: string;
  addedById: string;
  date: Date;
  scheduledRecipe: ScheduledRecipe;
  hideCalendar?: boolean;
  hideCalendarControls?: boolean;
};

export function CalendarRecipeCard({
  recipeId,
  date,
  addedById,
  scheduledRecipe,
  hideCalendar,
  hideCalendarControls,
}: Props) {
  const recipe = useRecipe({ recipeId });
  const calendar = useCalendar({ calendarId: scheduledRecipe.calendar_id });
  const currentUser = useAppSelector(selectCurrentUser);
  const addedByUser = useUser({ userId: addedById });
  const supabase = useClient();
  const appDispatch = useAppDispatch();
  const fetchRecipeAuthor = useCallback(async () => {
    if (!addedByUser) {
      appDispatch(fetchUser({ supabase, userId: addedById }));
    }
  }, [supabase, addedByUser, appDispatch, addedById]);

  useEffect(() => {
    if (!addedByUser) fetchRecipeAuthor();
  }, [addedByUser, fetchRecipeAuthor]);

  const navigate = useNavigate();
  const [showRescheduleRecipeModal, setShowRescheduleRecipeModal] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRemoveRecipeFromSchedule = () => {
    if (currentUser === null || recipe === null) return;
    appDispatch(
      removeRecipeFromDay({
        supabase,
        date,
        scheduledRecipe,
        successCallback: () => {},
        errorCallback: console.log,
      }),
    );
    setAnchorEl(null);
  };

  if (!recipe || !addedByUser || !calendar) {
    return <Skeleton height={400} width={400}></Skeleton>;
  }

  return (
    <Card sx={{ width: 345 }}>
      <CardHeader
        avatar={
          <Avatar
            src={
              recipe?.data?.url
                ? `https://www.google.com/s2/favicons?domain=${recipe.data.url}&sz=256`
                : "https://webaddress.site"
            }
            alt="logo"
          />
        }
        onClick={() => navigate(`/recipes/${recipe.id}`)}
        action={
          !hideCalendarControls && (
            <IconButton
              sx={{ alignSelf: "flex-end" }}
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVert />
            </IconButton>
          )
        }
        title={<TruncatedText text={recipe.title} maxLines={1} variant="h6" />}
        subheader={
          <TruncatedText
            text={recipe?.data?.site_name ?? "unknown"}
            maxLines={1}
            variant="caption"
          />
        }
      />
      <CardMedia
        component="img"
        height="140"
        image={
          recipe.imageUrl ??
          "https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800"
        }
        alt={recipe.title}
      />
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack gap={1}>
            {!hideCalendar && (
              <Stack direction="row" gap={1} alignItems="center">
                <Event />
                <CalendarAvatar
                  calendarId={calendar.id}
                  width={24}
                  height={24}
                  showName
                />
              </Stack>
            )}
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
              sx={{ paddingBottom: 1, width: "100%" }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <Person />
                <UserAvatar
                  userId={addedByUser.id}
                  height={24}
                  width={24}
                  showName
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" gap={1}>
            <IconButton
              aria-label="add to favorites"
              onClick={() => alert("Coming soon!")}
            >
              <FavoriteBorder />
            </IconButton>
            {recipe?.data?.url && (
              <IconButton href={recipe.data.url} target="_blank">
                <OpenInNew />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </CardContent>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: "45px" }}
        keepMounted
      >
        <MenuItem key="remove" onClick={handleRemoveRecipeFromSchedule}>
          <Typography textAlign="center">Remove</Typography>
        </MenuItem>
        <MenuItem
          key="logout"
          onClick={() => {
            setShowRescheduleRecipeModal(true);
          }}
        >
          <Typography textAlign="center">Reschedule</Typography>
        </MenuItem>
      </Menu>
      <RescheduleRecipeModal
        scheduledRecipe={scheduledRecipe}
        isOpen={showRescheduleRecipeModal}
        onClose={() => {
          setShowRescheduleRecipeModal(false);
        }}
        recipeId={recipeId}
        currentDate={date}
      />
    </Card>
  );
}
