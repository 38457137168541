import "./App.css";

import { useCallback, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Route, Routes } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import {
  LoadingState,
  rouxRedThemeColor,
  rouxWhiteThemeColor,
  rouxYellowThemeColor,
  useAppDispatch,
  useAppSelector,
} from "./common";
import { useClient } from "./common/supabase/hooks/useClient";
import {
  initialize as initializeAuth,
  selectCurrentUser as selectCurrentAuthUser,
  selectCurrentSession,
} from "./features/auth";
import { fetchAllRecipes } from "./features/recipes";
import { useCurrentUser } from "./features/users";
import {
  CalendarDayPage,
  CalendarPage,
  FeedPage,
  IndividualCalendarPage,
  LockScreenPage,
  NotFoundPage,
  ProfilePage,
  RecipePage,
  SearchPage,
} from "./pages";
import { NewVersionNotificationDrawer } from "./serviceWorker";

export default function App() {
  const dispatch = useAppDispatch();
  const supabase = useClient();
  const [sessionLoadingState, setSessionLoadingState] = useState<LoadingState>(
    LoadingState.NotStarted,
  );

  // Get the user's account.
  const session = useAppSelector(selectCurrentSession);
  const fetchSession = useCallback(async () => {
    if (sessionLoadingState === LoadingState.NotStarted) {
      dispatch(initializeAuth({ supabase }));
      setSessionLoadingState(LoadingState.Started);
    }
  }, [supabase, sessionLoadingState, dispatch]);

  useMemo(async () => {
    if (sessionLoadingState === LoadingState.NotStarted) {
      fetchSession();
    }
  }, [sessionLoadingState, fetchSession]);

  const currentAuthUser = useAppSelector(selectCurrentAuthUser);
  const currentUser = useCurrentUser({
    userId: currentAuthUser?.id,
    errorCallback: console.log,
    successCallback: () => {},
  });

  // TODO - remove when RecipeGrid uses search.
  useMemo(() => {
    dispatch(fetchAllRecipes({ supabase }));
  }, [dispatch, supabase]);

  const theme = createTheme({
    palette: {
      primary: rouxRedThemeColor,
      secondary: rouxYellowThemeColor,
      info: rouxWhiteThemeColor,
    },
  });

  let children = null;

  if (session) {
    children = (
      <DndProvider backend={HTML5Backend}>
        <Routes>
          <Route path="/" element={<CalendarPage />} />
          <Route path="/calendar/:yyyy/:mm/:dd" element={<CalendarDayPage />} />
          <Route path="/calendars/:id" element={<IndividualCalendarPage />} />
          <Route path="/feed" element={<FeedPage />} />
          <Route
            path="/profile"
            element={<ProfilePage userId={currentUser?.id} />}
          />
          <Route path="/profiles/:id" element={<ProfilePage />} />
          <Route path="/recipes/:id" element={<RecipePage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </DndProvider>
    );
  } else {
    children = <LockScreenPage />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <>
          {children}
          <NewVersionNotificationDrawer />
        </>
      </CssBaseline>
    </ThemeProvider>
  );
}
