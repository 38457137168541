import { useCallback } from "react";

import { Button, Skeleton, Stack } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { followUser, selectCurrentUser, useUser } from "../users";
import { EditProfileDrawerMenu } from "./EditProfileDrawerMenu";

export function ProfileFollowOrEditButton({ userId }: { userId: string }) {
  const user = useUser({ userId });
  const currentUser = useAppSelector(selectCurrentUser);
  const isCurrentUser = user?.id === currentUser?.id;
  const alreadyFollowsUser =
    currentUser && user?.followers && user?.followers[currentUser.id];
  const dispatch = useAppDispatch();
  const supabase = useClient();

  const onFollow = useCallback(() => {
    if (!currentUser || !user || isCurrentUser || alreadyFollowsUser) return;
    dispatch(
      followUser({
        supabase,
        userToFollow: user,
        currentUser,
        errorCallback: console.log,
        successCallback: () => {},
      }),
    );
  }, [
    isCurrentUser,
    alreadyFollowsUser,
    currentUser,
    dispatch,
    user,
    supabase,
  ]);

  if (!user) return <Skeleton />;

  return (
    <Stack sx={{ width: 1 }} alignItems="center">
      {isCurrentUser && <EditProfileDrawerMenu />}
      {!isCurrentUser && !alreadyFollowsUser && (
        <Button onClick={onFollow} sx={{ width: "120px" }}>
          Follow
        </Button>
      )}
    </Stack>
  );
}
