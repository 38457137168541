import { Box, List, Skeleton, Stack, Typography } from "@mui/material";

import { UserAvatar } from "../users";
import { useCalendar } from "./hooks";

export function CalendarContributorsList({
  calendarId,
  showFollowers,
}: {
  calendarId: string;
  showFollowers?: boolean;
}) {
  const calendar = useCalendar({ calendarId });

  if (!calendar) return <Skeleton />;

  const rawUsers = showFollowers ? calendar.followers : calendar.editors;

  const users = rawUsers.map((user) => {
    return (
      <UserAvatar key={user} width={64} height={64} userId={user} showName />
    );
  });

  if (rawUsers.length === 0) {
    return (
      <Box sx={{ width: 380, height: 360 }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: 1 }}
          gap={2}
        >
          <Typography variant="h6">No users?!</Typography>
        </Stack>
      </Box>
    );
  }

  return <List sx={{ height: 360 }}>{users}</List>;
}
