import { useCallback, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { fetchPost, selectPostById } from "./postsSlice";

export function usePost({ postId }: { postId: string }) {
  const post = useAppSelector((state) => {
    return selectPostById(state, postId);
  });

  const appDispatch = useAppDispatch();
  const supabase = useClient();

  const fetchApiPost = useCallback(async () => {
    if (!post && postId) {
      appDispatch(
        fetchPost({
          supabase,
          postId,
          errorCallback: console.log,
          successCallback: () => {},
        }),
      );
    }
  }, [post, appDispatch, supabase, postId]);

  useMemo(async () => {
    if (!post) {
      fetchApiPost();
    }
  }, [fetchApiPost, post]);

  return post;
}
