import { ChangeEvent, useCallback, useMemo, useState } from "react";
import uuid from "react-uuid";

import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import {
  TruncatedText,
  VisuallyHiddenInput,
  rouxRedHex,
  rouxTopChromeHeightPixels,
  useAppDispatch,
  useAppSelector,
} from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { selectCurrentUser, updateCurrentUsersProfile } from "../users";

const labelWidth = "60px";

export function EditProfileDrawerMenu() {
  const currentUser = useAppSelector(selectCurrentUser);
  const handleClose = useCallback(() => {
    // Have to manually reset the state because drawer is still mounted.
    // We do it this way so we get the nice animation of the drawer pulling open.
    setBlobUrl(null);
    setShowDrawer(false);
  }, []);
  const [showDrawer, setShowDrawer] = useState(false);
  const [imageId, setImageId] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(
    currentUser?.username ?? null,
  );
  const [imageIsUploading, setImageIsUploading] = useState(false);
  const [isUsernameInvalid, setIsUsernameInvalid] = useState(false);
  const [isUsernameTooShort, setIsUsernameTooShort] = useState(false);
  const [isUsernameLoading, setIsUsernameLoading] = useState(false);
  const [fullName, setFullName] = useState<string | null>(
    currentUser?.full_name ?? null,
  );
  const [bio, setBio] = useState<string | null>(currentUser?.bio ?? null);
  const [blobUrl, setBlobUrl] = useState<string | null>(
    currentUser?.avatar_url ?? null,
  );
  const supabase = useClient();
  const dispatch = useAppDispatch();

  const handleProfileClick = useCallback(() => setShowDrawer(true), []);

  const handleSave = useCallback(() => {
    if (currentUser === null) {
      return;
    }

    if (isUsernameInvalid) {
      alert("Username already in use");
      return;
    }

    if (isUsernameTooShort) {
      alert("Must pick username with >= 3 characters");
      return;
    }

    dispatch(
      updateCurrentUsersProfile({
        currentUser,
        supabase,
        successCallback: () => {},
        errorCallback: console.log,
        newAvatarUrl: blobUrl ?? currentUser.avatar_url ?? "",
        newProfile: {
          username: username ?? currentUser.username ?? "",
          avatar_url: imageId ?? currentUser.avatar_url ?? "",
          full_name: fullName ?? currentUser.full_name ?? "",
          bio: bio ?? currentUser.bio ?? "",
        },
      }),
    );

    setShowDrawer(false);
  }, [
    dispatch,
    currentUser,
    fullName,
    blobUrl,
    username,
    imageId,
    bio,
    supabase,
    isUsernameInvalid,
    isUsernameTooShort,
  ]);

  const uploadImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;
      if (!currentUser) return;
      const image = event.target.files[0];
      setImageIsUploading(true);
      // TODO - add file extension. you tried mime but...
      const imageId = uuid();
      const imageSlug = `${currentUser.id}/${imageId}`;
      const { error } = await supabase.storage
        .from("avatars")
        .upload(imageSlug, image);
      if (error) {
        throw error;
      }

      setImageId(imageId);
    },
    [currentUser, supabase],
  );

  const checkUsername = useCallback(
    async ({ username }: { username: string | null }) => {
      setUsername(username);

      if (!username) return;

      setIsUsernameLoading(true);

      if (username.length < 3) {
        setIsUsernameLoading(false);
        setIsUsernameTooShort(true);
        return;
      }

      setIsUsernameTooShort(false);

      const { data, error } = await supabase
        .from("profiles")
        .select("username")
        .eq("username", username);

      if (error) alert(error.message);

      if (data && data.length > 0 && username !== currentUser?.username) {
        setIsUsernameInvalid(true);
      } else if (data) {
        setIsUsernameInvalid(false);
      }

      setIsUsernameLoading(false);
    },
    [supabase, currentUser],
  );

  useMemo(async () => {
    if (imageId && currentUser) {
      const { data, error } = await supabase.storage
        .from("avatars")
        .download(`${currentUser.id}/${imageId}`);
      if (error) {
        throw error;
      }
      setBlobUrl(URL.createObjectURL(data));
      setImageIsUploading(false);
    }
  }, [currentUser, supabase, imageId]);

  if (!currentUser) return null;

  return (
    <>
      <Button
        component="label"
        variant="contained"
        sx={{ width: "120px" }}
        onClick={handleProfileClick}
      >
        <Typography variant="caption">Edit Profile</Typography>
      </Button>
      <Drawer anchor="bottom" open={showDrawer} onClose={handleClose}>
        <Box role="presentation" sx={{ width: "100%", height: "100vh" }}>
          <Stack alignItems="center" sx={{ width: 1 }} gap={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-around"
              gap={2}
              sx={{
                backgroundColor: rouxRedHex,
                height: rouxTopChromeHeightPixels,
                width: "100%",
                padding: 1,
              }}
            >
              <Button onClick={handleClose}>
                <Typography color="white" variant="caption">
                  Cancel
                </Typography>
              </Button>
              <TruncatedText
                text="Edit Profile"
                maxLines={1}
                color="white"
                variant="h6"
              ></TruncatedText>
              <Button onClick={handleSave}>
                <Typography variant="caption" color="white">
                  Save
                </Typography>
              </Button>
            </Stack>
            <Stack sx={{ marginTop: 2 }} alignItems="center">
              {imageIsUploading && <CircularProgress />}
              {!imageIsUploading && (
                <Avatar
                  alt={currentUser.username?.toUpperCase()}
                  src={blobUrl ?? ""}
                  sx={{
                    height: "128px",
                    width: "128px",
                    position: "relative",
                  }}
                />
              )}
              <Button component="label">
                <Typography variant="caption">Change Profile Photo</Typography>
                <VisuallyHiddenInput
                  onChange={uploadImage}
                  type="file"
                  accept="image/*"
                />
              </Button>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              sx={{ width: 1, paddingLeft: 2, paddingRight: 2 }}
            >
              <Typography sx={{ width: labelWidth }} variant="caption">
                Username
              </Typography>
              <TextField
                placeholder="username"
                value={username}
                onChange={(event) => {
                  checkUsername({ username: event.target.value });
                }}
                sx={{ flexGrow: 1 }}
                inputProps={{ style: { textTransform: "lowercase" } }}
              />
              {isUsernameLoading && <CircularProgress />}
              {isUsernameInvalid && <Alert severity="error">Used</Alert>}
              {isUsernameTooShort && <Alert severity="error">Too short</Alert>}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              sx={{ width: 1, paddingLeft: 2, paddingRight: 2 }}
            >
              <Typography sx={{ width: labelWidth }} variant="caption">
                Full name
              </Typography>
              <TextField
                placeholder="fullname"
                value={fullName}
                onChange={(event) => setFullName(event.target.value)}
                sx={{ flexGrow: 1 }}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              sx={{ width: 1, paddingLeft: 2, paddingRight: 2 }}
            >
              <Typography sx={{ width: labelWidth }} variant="caption">
                Bio
              </Typography>
              <TextField
                placeholder="Biography"
                value={bio}
                multiline
                onChange={(event) => setBio(event.target.value)}
                sx={{ flexGrow: 1 }}
              />
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
