import { SimplePaletteColorOptions } from "@mui/material";

export const rouxRedHex = "#E22E25";

export const rouxRedThemeColor: SimplePaletteColorOptions = {
  main: "#E22E25",
};

export const rouxYellowThemeColor: SimplePaletteColorOptions = {
  main: "#F4C001",
};

export const rouxWhiteThemeColor: SimplePaletteColorOptions = {
  main: "#000000",
};
