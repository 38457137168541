import React, { ChangeEvent, useCallback, useState } from "react";

import { Box, Stack, Tab, Tabs, TextField } from "@mui/material";

import {
  PageLayout,
  RouxTabPanel,
  rouxTopChromeHeightPixels,
  useAppDispatch,
  useAppSelector,
} from "../common";
import { CalendarList } from "../features/calendar/CalendarList";
import { TopChrome } from "../features/chrome";
import { SearchableRecipeGrid } from "../features/recipes";
import { search, selectLastSearchText } from "../features/search";
import { UserList } from "../features/users";

export function SearchPage() {
  const [value, setValue] = useState(0);
  const lastSearchText = useAppSelector(selectLastSearchText);
  const [searchText, setSearchText] = useState<string>(lastSearchText ?? "");
  const dispatch = useAppDispatch();
  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.target.value) {
        setSearchText(event.target.value);
        dispatch(search({ text: event.target.value }));
      } else {
        setSearchText("");
        dispatch(search({ text: "" }));
      }
    },
    [dispatch],
  );

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  return (
    <PageLayout topChrome={<TopChrome />}>
      <Stack alignItems="center">
        <Stack
          sx={{ padding: 2, width: "100%" }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            placeholder="Search recipes, people & calendars"
            onChange={handleSearch}
            sx={{ width: "100%" }}
            value={searchText}
          />
        </Stack>
        <Box
          sx={{
            width: 1,
            position: "sticky",
            background: "white",
            display: "flex",
            justifyContent: "space-around",
            top: rouxTopChromeHeightPixels,
            zIndex: 1000,
          }}
        >
          <Tabs value={value} onChange={handleChange} sx={{ width: "330px" }}>
            <Tab label="Recipes" />
            <Tab label="People" />
            <Tab label="Calendars" />
          </Tabs>
        </Box>
        <RouxTabPanel value={value} index={0}>
          <SearchableRecipeGrid searchText={searchText ?? ""} />
        </RouxTabPanel>
        <RouxTabPanel value={value} index={1} style={{ width: "100%" }}>
          <UserList searchText={searchText ?? ""} />
        </RouxTabPanel>
        <RouxTabPanel value={value} index={2} style={{ width: "100%" }}>
          <CalendarList searchText={searchText ?? ""} />
        </RouxTabPanel>
      </Stack>
    </PageLayout>
  );
}
