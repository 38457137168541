import { useCallback, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { fetchCalendar, selectCalendarById } from "./calendarSlice";

export function useCalendar({ calendarId }: { calendarId?: string }) {
  const calendar = useAppSelector((state) => {
    return selectCalendarById(state, calendarId);
  });

  const appDispatch = useAppDispatch();
  const supabase = useClient();

  const fetchApiCalendar = useCallback(async () => {
    if (!calendar && calendarId) {
      appDispatch(
        fetchCalendar({
          supabase,
          calendarId,
          errorCallback: console.log,
          successCallback: () => {},
        }),
      );
    }
  }, [calendar, appDispatch, supabase, calendarId]);

  useMemo(async () => {
    if (!calendar) {
      fetchApiCalendar();
    }
  }, [fetchApiCalendar, calendar]);

  return calendar;
}
