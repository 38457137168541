import { Tables } from "../../common/supabase/generated_dbtypes";
import { PhotoMetadata } from "../photos";

export type PostcardProps = {
  postId: string;
};

export enum PostType {
  Generic = "generic",
  Developer = "developer",
  ScheduledRecipe = "scheduled_recipe",
  RecipeInteraction = "recipe_interaction",
  Recipe = "recipe",
  CalendarEntry = "calendar_entry",
}

export type PostData = Record<string | number | symbol, never>;

export type PostRow = {
  id: string;
  added_by: string | null;
  added_ts: string;
  updated_ts: string;
  type: Tables<"posts">["type"];
  calendar_id: string | null;
  title: string | null;
  contents: string | null;
  data: PostData;
};

export type Post = {
  id: string;
  addedBy: string;
  addedTs: string;
  updatedTs: string;
  scheduledTs?: string;
  type: Tables<"posts">["type"];
  data: PostData;
  title: string;
  contents: string;
  calendarId: string;
  recipeIds: string[];

  // Assumed all created by `added_by`, so its just the id
  photos: PhotoMetadata[];
};

export function fromDb({
  row,
  photos,
}: {
  row: PostRow;
  photos?: PhotoMetadata[];
}) {
  return {
    id: row.id,
    addedBy: row.added_by || "",
    addedTs: row.added_ts,
    updatedTs: row.updated_ts,
    type: row.type,
    data: row.data,
    title: row.title || "",
    contents: row.contents || "",
    calendarId: row.calendar_id || "",
    recipeIds: [],
    photos: photos ?? [],
  };
}
