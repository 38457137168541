import React from "react";
import { useNavigate } from "react-router-dom";

import { Link, Stack } from "@mui/material";

import { TruncatedText } from "../../common";
import { useRecipe } from "./hooks";
import { RecipeType } from "./types";

type Props = {
  recipeId: string;
};

const RecipeCard = ({ recipeId }: Props) => {
  const recipe = useRecipe({ recipeId });
  const navigate = useNavigate();

  return (
    <>
      <Stack className="v2-recipe-card">
        <img
          src={
            recipe.imageUrl ??
            "https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800"
          }
          onClick={() => {
            navigate(`/recipes/${recipeId}`);
          }}
          alt="Recipe"
        ></img>
        <Stack sx={{ padding: 1 }}>
          <TruncatedText text={recipe.title} maxLines={2} variant="subtitle2" />
          <Stack direction="row" alignItems="center" gap={1}>
            {recipe?.data?.url && (
              <img
                height="16"
                width="16"
                src={
                  recipe.data.favicon_url
                    ? recipe.data.favicon_url
                    : `http://www.google.com/s2/favicons?domain=${recipe.data.url}&sz=256`
                }
                alt="Logo"
              />
            )}
            {recipe.type === RecipeType.Url && (
              <Stack direction="column">
                <Link
                  href={recipe?.data?.url ?? "example.com"}
                  target="_blank"
                  onClick={(event) => event.stopPropagation()}
                >
                  {recipe?.data?.site_name && (
                    <TruncatedText
                      text={recipe?.data?.site_name}
                      charLimit={34}
                      maxLines={1}
                      variant="caption"
                    />
                  )}
                </Link>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default React.memo(RecipeCard);
