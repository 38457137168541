import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, Box, Skeleton, Stack, SxProps, Theme } from "@mui/material";

import { TruncatedText } from "../../common";
import { usePublisher } from "../publishers";
import { useCalendar } from "./hooks";

export function CalendarAvatar({
  calendarId,
  width,
  height,
  showName,
  disallowNavigation,
  sx,
}: {
  height: number;
  width: number;
  calendarId: string;
  showName?: boolean;
  disallowNavigation?: boolean;
  sx?: SxProps<Theme>;
}) {
  const calendar = useCalendar({ calendarId });
  const publisher = usePublisher({
    publisherId: calendar?.publisher_id ?? "unknown",
  });
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    !disallowNavigation && navigate(`/calendars/${calendarId}`);
  }, [disallowNavigation, navigate, calendarId]);

  const imageSource = !!publisher
    ? `http://www.google.com/s2/favicons?domain=${publisher.url}&sz=256`
    : calendar?.calendar_url ?? "";

  if (!calendar) return <Skeleton width={120} height={64} />;

  return (
    <Box onClick={handleClick}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
      >
        <Avatar alt="Logo" sx={{ ...sx, width, height }} src={imageSource} />
        {showName && (
          <TruncatedText
            variant="caption"
            maxLines={1}
            charLimit={34}
            text={calendar.title}
          />
        )}
      </Stack>
    </Box>
  );
}
