import { CardContent, Skeleton, Stack, Typography } from "@mui/material";

import { UserAvatar, useUser } from "../users";
import { usePost } from "./hooks";
import { PostcardProps } from "./types";

export function CalendarEntryPostCard({ postId }: PostcardProps) {
  const post = usePost({ postId });
  const user = useUser({ userId: post?.addedBy });

  if (!post) return <Skeleton />;

  return (
    <CardContent>
      <Stack gap={1}>
        <Typography sx={{ marginTop: 1 }} variant="body1">
          {post.contents}
        </Typography>
        {user && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{ width: 1 }}
          >
            <UserAvatar userId={user.id} height={24} width={24} />
          </Stack>
        )}
      </Stack>
    </CardContent>
  );
}
