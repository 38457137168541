import "../../common/Common.css";

import { useCallback, useEffect, useRef, useState } from "react";
import { ViewportList } from "react-viewport-list";

import { CircularProgress, Stack } from "@mui/material";

import { useAppDispatch } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { fetchPostsForCalendarsForDateRange } from "../posts/postsSlice";
import { CalendarDayListItem } from "./CalendarDayListItem";
import { fetchScheduledRecipesForDateRange } from "./calendarSlice";

import { addDays } from "date-fns";

export function CalendarDayList({
  calendarIds,
  hideCalendar,
  hideCalendarControls,
}: {
  calendarIds: string[];
  hideCalendar?: boolean;
  hideCalendarControls?: boolean;
}) {
  const numDaysToLoad = 30;
  const dispatch = useAppDispatch();
  const supabase = useClient();
  const [numDays, setNumDays] = useState(numDaysToLoad);
  const [numDaysInThePast, setNumDaysInThePast] = useState(0);
  const [currentScrollTop, setCurrentScrollTop] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const today = new Date();
  // Ensure we're at midnight
  today.setHours(0, 0, 0, 0);
  const future = addDays(today, numDaysToLoad);

  const [minDateLoaded, setMinDateLoaded] = useState<Date>(today);
  const [minDateScrolled, setMinDateScrolled] = useState<Date>(today);
  const [maxDateLoaded, setMaxDateLoaded] = useState<Date>(today);
  const [maxDateScrolled, setMaxDateScrolled] = useState<Date>(future);

  useEffect(() => {
    if (isLoading) return;
    if (calendarIds.length === 0) return;
    if (maxDateLoaded.valueOf() < maxDateScrolled.valueOf()) {
      setIsLoading(true);
      const loadDate = addDays(maxDateLoaded, numDaysToLoad);
      dispatch(
        fetchScheduledRecipesForDateRange({
          from: maxDateLoaded,
          to: loadDate,
          supabase,
          calendarIds,
          successCallback: handleSuccess,
          errorCallback: console.log,
        }),
      );
      dispatch(
        fetchPostsForCalendarsForDateRange({
          from: maxDateLoaded,
          to: loadDate,
          supabase,
          calendarIds,
          successCallback: () => {},
          errorCallback: console.log,
        }),
      );
      setMaxDateLoaded(loadDate);
      setNumDays(numDays + numDaysToLoad);
    } else if (minDateLoaded.valueOf() > minDateScrolled.valueOf()) {
      setIsLoading(true);
      const loadDate = addDays(minDateLoaded, -numDaysToLoad);
      dispatch(
        fetchScheduledRecipesForDateRange({
          from: loadDate,
          to: minDateLoaded,
          supabase,
          calendarIds,
          successCallback: handleSuccess,
          errorCallback: console.log,
        }),
      );
      dispatch(
        fetchPostsForCalendarsForDateRange({
          from: loadDate,
          to: minDateLoaded,
          supabase,
          calendarIds,
          successCallback: () => {},
          errorCallback: console.log,
        }),
      );
      setMinDateLoaded(loadDate);
      setNumDays(numDays + numDaysToLoad);
      setNumDaysInThePast(numDaysInThePast + numDaysToLoad);
    }
  }, [
    dispatch,
    minDateLoaded,
    minDateScrolled,
    maxDateLoaded,
    maxDateScrolled,
    numDays,
    calendarIds,
    isLoading,
    supabase,
    numDaysInThePast,
  ]);

  const dates = [...Array(numDays).keys()].map((index) => {
    const today = new Date();
    // As always, align to midnight.
    today.setHours(0, 0, 0, 0);
    const date = addDays(Date.now(), index - numDaysInThePast);
    date.setHours(0, 0, 0, 0);
    return date;
  });

  const ref = useRef<HTMLDivElement | null>(null);

  const handleSuccess = () => {
    setIsLoading(false);
  };

  const handleScroll = useCallback(() => {
    if (!ref.current) return;

    const scrollTop = ref.current.scrollTop;

    if (scrollTop < 10 && scrollTop !== currentScrollTop) {
      // Scrolling up to load into the past, so load some more days
      // but also change the past pointer in the list. Stupid inefficient
      // way to load the days we need.
      setNumDays(numDays + numDaysToLoad);
      setNumDaysInThePast(numDaysInThePast + numDaysToLoad);
    }

    setCurrentScrollTop(scrollTop);
  }, [numDays, numDaysInThePast, currentScrollTop]);

  return (
    <div className="scroll-container" ref={ref} onScroll={handleScroll}>
      {isLoading && (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
      <ViewportList
        viewportRef={ref}
        items={dates}
        indexesShift={numDaysInThePast}
      >
        {(date, index) => {
          // Ensure we're at midnight
          date.setHours(0, 0, 0, 0);
          if (date.valueOf() < minDateScrolled.valueOf()) {
            setMinDateScrolled(date);
          } else if (date.valueOf() > maxDateScrolled.valueOf()) {
            setMaxDateScrolled(date);
          }

          return (
            <CalendarDayListItem
              key={index}
              date={date}
              calendarIds={calendarIds}
              hideCalendar={hideCalendar}
              hideCalendarControls={hideCalendarControls}
            />
          );
        }}
      </ViewportList>
    </div>
  );
}
