import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../common/store";
import { ApiThunkArgs } from "../../common/types";
import { Publisher } from "./types";

export interface PublisherState {
  publishersById: { [id: string]: Publisher };
}

const initialState: PublisherState = {
  publishersById: {},
};

export const fetchPublishers = createAsyncThunk(
  "publishers/fetchPublishers",
  async ({
    supabase,
    errorCallback,
    successCallback,
    publisherIds,
  }: { publisherIds: string[] } & ApiThunkArgs) => {
    const { data, error } = await supabase.functions.invoke("publishers-get", {
      body: { publisherIds },
    });

    if (error) {
      errorCallback(error.message);
    }

    successCallback();

    return (data as Publisher[]).reduce(
      (acc, p) => {
        acc[p.id] = p;
        return acc;
      },
      {} as { [key: string]: Publisher },
    );
  },
);

export const publisherSlice = createSlice({
  name: "publishers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPublishers.fulfilled, (state, action) => {
      state.publishersById = {
        ...state.publishersById,
        ...action.payload,
      };
    });
  },
});

export const selectPublisherById = (state: RootState, publisherId: string) =>
  state.publisherStore.publishersById[publisherId];

export default publisherSlice.reducer;
