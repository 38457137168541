import { useCallback, useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import {
  fetchPhoto,
  fetchPhotoMetadataForUser,
  selectPhotoById,
  selectPhotoMetadataForUser,
} from "./photosSlice";
import { PhotoMetadata } from "./types";

export function useUserPhotoMetadata({ userId }: { userId: string }) {
  const photos = useAppSelector((state) => {
    return selectPhotoMetadataForUser(state, userId);
  });

  const dispatch = useAppDispatch();
  const supabase = useClient();

  const fetchPhotos = useCallback(async () => {
    if (!photos) {
      dispatch(
        fetchPhotoMetadataForUser({
          supabase,
          errorCallback: console.log,
          successCallback: () => {},
          userId,
        }),
      );
    }
  }, [photos, dispatch, userId, supabase]);

  useMemo(async () => {
    if (!photos) {
      fetchPhotos();
    }
  }, [fetchPhotos, photos]);

  return photos;
}

export function usePhoto({ metadata }: { metadata: PhotoMetadata }) {
  const photo = useAppSelector((state) => {
    return selectPhotoById(state, metadata.id);
  });

  const dispatch = useAppDispatch();
  const supabase = useClient();

  const fetch = useCallback(async () => {
    if (!photo) {
      await dispatch(
        fetchPhoto({
          photoMetadata: metadata,
          supabase,
          errorCallback: console.log,
          successCallback: () => {},
        }),
      );
    }
  }, [metadata, supabase, dispatch, photo]);

  useEffect(() => {
    if (!photo) {
      fetch();
    }
  }, [fetch, photo]);

  return photo;
}
