import { formatRelative, lightFormat } from "date-fns";

export function getHumanTimestamp({ date }: { date: Date }) {
  const today = new Date();
  const formattedDateParts = formatRelative(date, today)
    .replace("last ", "")
    .split(" ");
  if (formattedDateParts.length >= 4) {
    // use a relative time.
    return `${formattedDateParts[0]
      .charAt(0)
      .toUpperCase()}${formattedDateParts[0].slice(1)} • ${
      formattedDateParts[2]
    } ${formattedDateParts[3]}`;
  }
  return lightFormat(date, "MM/dd/yyyy • h:mm a");
}

export function getNextSunday(timestamp: number) {
  const date = new Date(timestamp);
  const dayOfWeek = date.getDay();
  // Monday === 0, I like Sunday being first. Math sucks.
  const diff = dayOfWeek === 6 ? 0 : 7 - (dayOfWeek + 1);
  return new Date(date.setDate(date.getDate() + diff));
}

export function getCorrectDate(rouxDate: string) {
  return new Date(rouxDate + " GMT-0500");
}
