import { useNavigate } from "react-router-dom";

import { Divider, Skeleton, Stack, Typography } from "@mui/material";

import { TruncatedText, getHumanTimestamp } from "../../common";
import { useRecipe } from "../recipes";
import { RecipeComment } from "./types";

export function UserCommentsListItem({ comment }: { comment: RecipeComment }) {
  const recipe = useRecipe({ recipeId: comment.recipe_id });
  const formattedDate = getHumanTimestamp({ date: new Date(comment.added_ts) });
  const navigate = useNavigate();
  if (!recipe) return <Skeleton />;

  return (
    <Stack>
      <Stack
        direction="row"
        sx={{ width: 1, padding: 1 }}
        gap={3}
        alignItems="center"
        onClick={() => {
          navigate(`/recipes/${recipe.id}`);
        }}
      >
        <Stack sx={{ width: "100px", height: "100%" }}>
          <div
            style={{
              backgroundImage: `url("${
                recipe?.imageUrl ?? "/recipe-test-image.jpg"
              }")`,
              backgroundSize: "cover",
              borderRadius: 4,
              height: "60px",
              width: "100px",
            }}
          />
          <TruncatedText text={recipe.title} maxLines={2} />
          <Stack direction="row" alignItems="center" gap={1}>
            {recipe?.data?.url && (
              <img
                height="16"
                width="16"
                src={
                  recipe.data.favicon_url
                    ? recipe.data.favicon_url
                    : `http://www.google.com/s2/favicons?domain=${recipe.data.url}&sz=256`
                }
                alt="Logo"
              />
            )}
            {recipe?.data?.site_name && (
              <TruncatedText
                text={recipe?.data?.site_name}
                charLimit={34}
                maxLines={1}
                variant="caption"
              />
            )}
          </Stack>
        </Stack>
        <Stack sx={{ height: 1 }}>
          <Typography variant="body2">{comment.comment}</Typography>
        </Stack>
      </Stack>
      <Stack alignItems="center">
        <Typography variant="caption">{`${formattedDate}`}</Typography>
      </Stack>
      <Divider />
    </Stack>
  );
}
