import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Card, Skeleton, Stack } from "@mui/material";

import { useAppDispatch } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { SelectableRecipesList, fetchRecipes } from "../recipes";
import { SelectableRecipeContextProvider } from "../recipes/context";
import { CalendarEntryPostCard } from "./CalendarEntryPostCard";
import { GenericPostCard } from "./GenericPostCard";
import { PostBottomChrome } from "./PostBottomChrome";
import { PostTopChrome } from "./PostTopChrome";
import { usePost } from "./hooks";
import { PostType, PostcardProps } from "./types";

const PostTypePostCards: {
  [key in PostType]: ({ postId }: PostcardProps) => ReactNode;
} = {
  [PostType.Generic]: ({ postId }: PostcardProps) => {
    return <GenericPostCard postId={postId} />;
  },
  [PostType.CalendarEntry]: ({ postId }: PostcardProps) => {
    return <CalendarEntryPostCard postId={postId} />;
  },
  [PostType.Developer]: ({ postId }: PostcardProps) => {
    return <GenericPostCard postId={postId} />;
  },
  [PostType.Recipe]: ({ postId }: PostcardProps) => {
    return <GenericPostCard postId={postId} />;
  },
  [PostType.RecipeInteraction]: ({ postId }: PostcardProps) => {
    return <GenericPostCard postId={postId} />;
  },
  [PostType.ScheduledRecipe]: ({ postId }: PostcardProps) => {
    return <GenericPostCard postId={postId} />;
  },
};

export function PostCard({ postId }: PostcardProps) {
  const post = usePost({ postId });
  const dispatch = useAppDispatch();
  const supabase = useClient();
  const navigate = useNavigate();
  useEffect(() => {
    if (!post) return;

    if (post.recipeIds.length) {
      dispatch(
        fetchRecipes({
          recipeIds: post.recipeIds,
          errorCallback: console.log,
          successCallback: () => {},
          supabase,
        }),
      );
    }
  }, [dispatch, post, supabase]);

  if (!post) return <Skeleton />;

  return (
    <Stack sx={{ width: 1, maxWidth: "500px" }}>
      <Card>
        <PostTopChrome postId={postId}></PostTopChrome>
        {PostTypePostCards[post.type]({ postId: postId })}
        {post.recipeIds.length > 0 && (
          <Stack sx={{ backgroundColor: "lightgray" }}>
            <SelectableRecipeContextProvider
              value={{
                handleSelectRecipeId: (recipeId) => {
                  navigate(`/recipes/${recipeId}`);
                },
              }}
            >
              <SelectableRecipesList recipeIds={post.recipeIds} />
            </SelectableRecipeContextProvider>
          </Stack>
        )}
        <PostBottomChrome postId={postId}></PostBottomChrome>
      </Card>
    </Stack>
  );
}
