import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";

import { PostAdd } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { DrawerTopChrome } from "../chrome";
import { PostType } from "../posts";
import { createPost } from "../posts/postsSlice";
import { SelectRecipesButton, SelectableRecipesList } from "../recipes";
import { SelectableRecipeContextProvider } from "../recipes/context";
import { selectCurrentUser } from "../users";
import { CalendarAvatar } from "./CalendarAvatar";

export function CreateCalendarPostDrawer({
  date,
  icon,
  initialRecipeIds,
}: {
  icon?: React.ReactNode;
  date?: Date;
  initialRecipeIds?: string[];
}) {
  const currentUser = useAppSelector(selectCurrentUser);
  const calendarsArray = Object.values(currentUser?.calendars ?? {});
  const [calendarValue, setCalendarValue] = useState<string | null>(
    currentUser?.preferences?.default_calendar_id ??
      (calendarsArray.length > 0 ? calendarsArray[0].id : null),
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const [content, setContent] = useState<string>("");
  const [recipeIds, setRecipeIds] = useState<string[]>(initialRecipeIds ?? []);
  const [dateValue, setDateValue] = useState<Date | null>(date ?? null);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useAppDispatch();
  const supabase = useClient();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    setShowDrawer(false);
  }, []);

  const handleDateChange = useCallback(
    (newValue: Date | null) => {
      setDateValue(newValue);
    },
    [setDateValue],
  );

  const onRecipeSelected = useCallback(
    async (recipeId: string) => {
      setRecipeIds([...recipeIds, recipeId]);
    },
    [recipeIds],
  );

  const onRecipeDeselected = useCallback(
    async (recipeId: string) => {
      setRecipeIds(recipeIds.filter((id) => id !== recipeId));
    },
    [recipeIds],
  );

  const handleCalendarChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setCalendarValue(event.target.value ?? null);
    },
    [setCalendarValue],
  );

  const handleSave = useCallback(async () => {
    if (!currentUser || !dateValue) return;
    setIsSaving(true);

    const postRow = {
      id: uuid(),
      added_by: currentUser.id,
      added_ts: new Date().toISOString(),
      updated_ts: new Date().toISOString(),
      calendar_id: calendarValue,
      type: PostType.CalendarEntry,
      title: "",
      contents: content,
      data: { scheduled_date: dateValue.toISOString().substring(0, 10) },
    };

    await dispatch(
      createPost({
        supabase,
        post: postRow,
        currentUser,
        images: [],
        recipeIds: recipeIds,
        errorCallback: console.log,
        successCallback: () => {},
      }),
    );

    setIsSaving(false);
    setRecipeIds([]);
    setShowDrawer(false);
    setContent("");

    navigate("/");
  }, [
    dispatch,
    navigate,
    content,
    calendarValue,
    dateValue,
    currentUser,
    supabase,
    recipeIds,
  ]);

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          setShowDrawer(true);
        }}
      >
        {icon ?? <PostAdd />}
      </IconButton>
      <Drawer anchor="bottom" open={showDrawer} onClose={handleClose}>
        <Box role="presentation" sx={{ width: "100%", minHeight: "60vh" }}>
          {isSaving && <CircularProgress />}
          {!isSaving && (
            <Stack
              alignItems="center"
              sx={{ height: `100vh`, width: 1 }}
              gap={2}
            >
              <DrawerTopChrome
                title="New calendar entry"
                onClose={handleClose}
              />
              <Stack
                alignItems="center"
                sx={{ height: `75vh`, width: `100vw`, paddingTop: 12 }}
                gap={2}
              >
                <DesktopDatePicker
                  label="Date"
                  value={dateValue}
                  onChange={handleDateChange}
                />
                <Select
                  value={calendarValue ?? undefined}
                  onChange={handleCalendarChange}
                >
                  {Object.values(currentUser?.calendars ?? {}).map((c) => {
                    return (
                      <MenuItem key={c.id} value={c.id}>
                        <CalendarAvatar
                          calendarId={c.id}
                          width={24}
                          height={24}
                          showName
                          disallowNavigation
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
                <TextField
                  placeholder="Ideas, plans, &c."
                  value={content}
                  onChange={(event) => setContent(event.target.value)}
                  rows={6}
                  sx={{ width: 0.8 }}
                  multiline
                />
                <SelectableRecipeContextProvider
                  value={{
                    handleSelectRecipeId: onRecipeSelected,
                    handleDeselectRecipeId: onRecipeDeselected,
                  }}
                >
                  <SelectRecipesButton />
                  <Stack sx={{ height: "300px", width: 0.8 }}>
                    <SelectableRecipesList recipeIds={recipeIds} />
                  </Stack>
                </SelectableRecipeContextProvider>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  sx={{ width: 32 }}
                >
                  <Typography variant="caption">Post</Typography>
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      </Drawer>
    </>
  );
}
