import { Stack } from "@mui/material";

import { PageLayout } from "../common";
import {
  rouxBottomChromeHeightPixels,
  rouxTopChromeHeightPixels,
} from "../common/constants";
import { TopChrome } from "../features/chrome";
import { PostsList } from "../features/posts";

export function FeedPage() {
  return (
    <PageLayout topChrome={<TopChrome />}>
      <Stack
        sx={{
          overflow: "hidden",
          height: `calc(100vh - ${rouxTopChromeHeightPixels} - ${rouxBottomChromeHeightPixels})`,
        }}
      >
        <PostsList />
      </Stack>
    </PageLayout>
  );
}
