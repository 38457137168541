import "../../common/Common.css";

import { ChangeEvent, useCallback, useState } from "react";
import uuid from "react-uuid";

import { Button, Stack, TextField, Typography } from "@mui/material";

import {
  ExpandedPhoto,
  rouxTopChromeHeightPixels,
  useAppDispatch,
  useAppSelector,
} from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { AddPhotosButton } from "../photos";
import { SelectRecipesButton, SelectableRecipesList } from "../recipes";
import { SelectableRecipeContextProvider } from "../recipes/context";
import { selectCurrentUser } from "../users";
import { createPost } from "./postsSlice";
import { PostType } from "./types";

export function AddNewPostForm({
  setIsSaving,
  onClose,
}: {
  setIsSaving: (isSaving: boolean) => void;
  onClose: () => void;
}) {
  const [images, setImages] = useState<File[]>([]);
  const [recipeIds, setRecipeIds] = useState<string[]>([]);
  const [content, setContent] = useState<string>("");
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const supabase = useClient();

  const handleAddImages = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;
      if (!currentUser) return;
      const newImages = event.target.files;
      setImages([...newImages, ...images]);
    },
    [currentUser, images],
  );

  const handleSave = useCallback(async () => {
    if (!currentUser) return;
    setIsSaving(true);

    const postRow = {
      id: uuid(),
      added_by: currentUser.id,
      added_ts: new Date().toISOString(),
      updated_ts: new Date().toISOString(),
      type: PostType.Generic,
      title: "",
      contents: content,
      data: {},
    };

    await dispatch(
      createPost({
        supabase,
        post: postRow,
        currentUser,
        images,
        recipeIds: recipeIds,
        errorCallback: console.log,
        successCallback: () => {},
      }),
    );

    setIsSaving(false);
    setImages([]);
    onClose();
  }, [
    currentUser,
    images,
    supabase,
    dispatch,
    content,
    onClose,
    setIsSaving,
    recipeIds,
  ]);

  const onRecipeSelected = useCallback(
    async (recipeId: string) => {
      setRecipeIds([...recipeIds, recipeId]);
    },
    [recipeIds],
  );

  const onRecipeDeselected = useCallback(
    async (recipeId: string) => {
      setRecipeIds(recipeIds.filter((id) => id !== recipeId));
    },
    [recipeIds],
  );

  return (
    <Stack
      alignItems="center"
      sx={{
        height: `calc(100vh - ${rouxTopChromeHeightPixels})`,
        paddingTop: 12,
      }}
      gap={2}
    >
      <AddPhotosButton handleAddImages={handleAddImages} />
      <Stack
        direction="row"
        gap={2}
        className="scroll-container-y"
        sx={{ paddingLeft: 2 }}
      >
        {images.map((image) => {
          return (
            <ExpandedPhoto
              key={image.name}
              photoSrc={URL.createObjectURL(image)}
              width="160px"
              height="160px"
            />
          );
        })}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        sx={{ width: 1, padding: 2 }}
      >
        <TextField
          placeholder="Post"
          value={content}
          onChange={(event) => setContent(event.target.value)}
          sx={{ flexGrow: 2 }}
          rows={6}
          multiline
        />
      </Stack>
      <SelectableRecipeContextProvider
        value={{
          handleSelectRecipeId: onRecipeSelected,
          handleDeselectRecipeId: onRecipeDeselected,
        }}
      >
        <SelectRecipesButton />
        <SelectableRecipesList recipeIds={recipeIds} />
      </SelectableRecipeContextProvider>
      <Button variant="contained" onClick={handleSave}>
        <Typography variant="caption">Upload</Typography>
      </Button>
    </Stack>
  );
}
