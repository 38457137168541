import { useState } from "react";

import { BookmarkAdd, Event, Warning } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";

import { CreateCalendarPostDrawer } from "../calendar";
import { AddRecipeCommentDrawerMenu } from "../comments";
import { FlagRecipeModal } from "./FlagRecipeModal";
import { UploadRecipePhotoDrawerMenu } from "./UploadRecipePhotoDrawerMenu";
import { useRecipe } from "./hooks";

export const recipeBottomChromeHeight = 40;

export function RecipeBottomChrome({ recipeId }: { recipeId: string }) {
  const recipe = useRecipe({ recipeId });
  useState<boolean>(false);
  const [displayFlagRecipeModal, setDisplayFlagRecipeModal] =
    useState<boolean>(false);
  const handleFlagRecipeModalClose = () => {
    setDisplayFlagRecipeModal(false);
  };
  const comingSoon = () => {
    alert("Coming soon!");
  };
  return (
    <Box sx={{ backgroundColor: "lightgrey" }}>
      <Stack
        direction="row"
        justifyContent={"space-around"}
        alignItems="center"
      >
        <AddRecipeCommentDrawerMenu recipeId={recipeId} />
        <UploadRecipePhotoDrawerMenu recipeId={recipeId} />
        <IconButton color="info" onClick={comingSoon}>
          <BookmarkAdd />
        </IconButton>
        <CreateCalendarPostDrawer
          icon={<Event color="info" />}
          initialRecipeIds={[recipeId]}
          date={new Date()}
        />
        <IconButton
          color="info"
          onClick={() => setDisplayFlagRecipeModal(true)}
        >
          <Warning />
        </IconButton>
      </Stack>{" "}
      {displayFlagRecipeModal && (
        <FlagRecipeModal
          isOpen={displayFlagRecipeModal}
          onClose={handleFlagRecipeModalClose}
          recipeId={recipe.id}
        />
      )}
    </Box>
  );
}
