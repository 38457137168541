import { useContext } from "react";

import { Close } from "@mui/icons-material";
import { Divider, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { RecipeListItem } from "./RecipeListItem";
import { SelectableRecipeContext } from "./context";

export function SelectableRecipesList({ recipeIds }: { recipeIds: string[] }) {
  const { handleDeselectRecipeId } = useContext(SelectableRecipeContext);
  return (
    <Stack
      gap={1}
      className="scroll-container"
      sx={{
        padding: 1,
        width: 1,
        backgroundColor: "lightgray",
        borderRadius: 1,
        border: "1px solid grey",
        maxHeight: "240px",
      }}
    >
      {recipeIds.length === 0 && (
        <Stack
          sx={{ width: 1, height: 1 }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption">No recipes added yet</Typography>
        </Stack>
      )}
      {recipeIds.map((recipeId) => {
        return (
          <Stack key={recipeId} position="relative">
            <RecipeListItem recipeId={recipeId} />
            <Divider />
            {handleDeselectRecipeId && (
              <IconButton
                onClick={() => handleDeselectRecipeId(recipeId)}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 1,
                }}
                color="info"
              >
                <Close />
              </IconButton>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
}
