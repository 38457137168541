import { ReactNode } from "react";

import { Card, CardContent, CardMedia, Modal, Stack } from "@mui/material";

export interface Props {
  children?: ReactNode;
  isOpen: boolean;
  imageUrl?: string;
  imageUrlAlt?: string;
  // Copied from Modal wtf.
  onClose?: {
    bivarianceHack(event: {}, reason: "backdropClick" | "escapeKeyDown"): void;
  }["bivarianceHack"];
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export function RouxModal({
  children,
  isOpen,
  imageUrl,
  imageUrlAlt,
  onClose,
}: Props) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Card sx={modalStyle}>
        {(imageUrl || imageUrlAlt) && (
          <CardMedia
            component="img"
            height="140"
            image={imageUrl}
            alt={imageUrlAlt}
          />
        )}
        <CardContent>
          <Stack gap={3} alignItems="center" justifyContent="space-between">
            {children}
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}
