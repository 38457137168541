import { CurrentUser } from "../features/users";
import { RouxSupabaseClient } from "./supabase/types";

export enum LoadingState {
  NotStarted,
  Started,
  Successful,
  Failed,
}

export interface ApiThunkArgs {
  supabase: RouxSupabaseClient;
  // TODO - make these optional
  successCallback: () => void;
  errorCallback: (errorType: string) => void;
}

export interface AuthedApiThunkArgs extends ApiThunkArgs {
  currentUser: CurrentUser;
}
