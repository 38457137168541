import "../../common/Common.css";

import { useRef } from "react";
import { useNavigate } from "react-router";
import { ViewportList } from "react-viewport-list";

import { Box } from "@mui/material";

import { RecipeListItem } from "./RecipeListItem";
import { SelectableRecipeContextProvider } from "./context";

export function RecipeList({ recipeIds }: { recipeIds: string[] }) {
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const handleSelect = (recipeId: string) => {
    navigate(`/recipes/${recipeId}`);
  };

  return (
    <Box className="scroll-container" ref={ref}>
      <ViewportList viewportRef={ref} items={recipeIds}>
        {(recipeId) => {
          return (
            <SelectableRecipeContextProvider
              value={{ handleSelectRecipeId: handleSelect }}
            >
              <RecipeListItem key={recipeId} recipeId={recipeId} />
            </SelectableRecipeContextProvider>
          );
        }}
      </ViewportList>
    </Box>
  );
}
