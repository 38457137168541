import "../../common/Common.css";

import { useRef } from "react";
import { ViewportList } from "react-viewport-list";

import { Box, Divider } from "@mui/material";

import { RecipePhotoUploadCarouselItem } from "./RecipePhotoUploadCarouselItem";
import { RecipePhotoUpload } from "./types";

export function RecipePhotoUploadCarousel({
  uploads,
}: {
  uploads: RecipePhotoUpload[];
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  if (!uploads || uploads.length === 0) return null;
  return (
    <>
      <Divider sx={{ marginBottom: "8px" }} />
      <Box
        className="scroll-container-y"
        ref={ref}
        sx={{ minHeight: "160px", height: "160px", gap: 1 }}
      >
        <ViewportList viewportRef={ref} items={uploads} axis="x">
          {(upload) => {
            return (
              <RecipePhotoUploadCarouselItem key={upload.id} upload={upload} />
            );
          }}
        </ViewportList>
      </Box>
      <Divider sx={{ paddingTop: "8px" }} />
    </>
  );
}
