import { useCallback, useState } from "react";

import {
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { CalendarAvatar } from "../calendar";
import {
  selectCurrentUser,
  setDefaultCalendarPreference,
  toggleAccountVisibilityPreference,
} from "../users";

export function PreferencesList() {
  const dispatch = useAppDispatch();
  const supabase = useClient();
  const currentUser = useAppSelector(selectCurrentUser);
  const handlePrivacyToggle = useCallback(() => {
    if (!currentUser) return;
    dispatch(
      toggleAccountVisibilityPreference({
        currentUser,
        supabase,
        successCallback: () => {},
        errorCallback: console.log,
      }),
    );
  }, [currentUser, supabase, dispatch]);

  const [calendarValue, setCalendarValue] = useState<string | null>(
    currentUser?.preferences?.default_calendar_id ?? null,
  );

  const handleCalendarChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (!currentUser) return;
      setCalendarValue(event.target.value ?? null);
      dispatch(
        setDefaultCalendarPreference({
          calendarId: event.target.value,
          currentUser,
          supabase,
          errorCallback: console.log,
          successCallback: () => {},
        }),
      );
    },
    [setCalendarValue, currentUser, supabase, dispatch],
  );
  if (!currentUser) return <Skeleton width={200} height={500} />;

  // TODO - add a scrollable list of preferences. wire it up. blah blah.

  return (
    <Stack alignItems={"center"} gap={2} sx={{ height: "500px", padding: 2 }}>
      <Typography variant="h6">Preferences</Typography>
      <Divider sx={{ width: "100%" }} />
      <Typography>Account Privacy</Typography>
      <Typography variant="caption">
        If your account is private it means users have to ask before following
        you. Your posts won't be shown to anyone who doesn't follow you.
      </Typography>
      <Stack direction="row">
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={currentUser.preferences.is_private} />}
            onChange={handlePrivacyToggle}
            label={currentUser.preferences.is_private ? "Private" : "Public"}
          />
        </FormGroup>
      </Stack>
      <Divider sx={{ width: "100%" }} />
      <Typography>Default Calendar</Typography>
      <Typography variant="caption">
        Default calendar to use for scheduling recipes.
      </Typography>
      <Select
        value={calendarValue ?? undefined}
        onChange={handleCalendarChange}
        sx={{ width: "100%" }}
      >
        {Object.values(currentUser?.calendars ?? {}).map((c) => {
          return (
            <MenuItem key={c.id} value={c.id}>
              <CalendarAvatar
                calendarId={c.id}
                width={24}
                height={24}
                showName
                disallowNavigation
              />
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
}
