import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";

import authReducer from "../features/auth/authSlice";
import calendarReducer from "../features/calendar/calendarSlice";
import commentsReducer from "../features/comments/commentsSlice";
import photosReducer from "../features/photos/photosSlice";
import postsReducer from "../features/posts/postsSlice";
import publisherReducer from "../features/publishers/publishersSlice";
import ratingsReducer from "../features/ratings/ratingsSlice";
import recipesReducer from "../features/recipes/recipesSlice";
import searchReducer from "../features/search/searchSlice";
import usersReducer from "../features/users/usersSlice";

export const store = configureStore({
  reducer: {
    authStore: authReducer,
    calendarStore: calendarReducer,
    publisherStore: publisherReducer,
    recipesStore: recipesReducer,
    usersStore: usersReducer,
    commentsStore: commentsReducer,
    photosStore: photosReducer,
    searchStore: searchReducer,
    ratingStore: ratingsReducer,
    postsStore: postsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
