import "../../common/Common.css";
import "./RecipeGrid.css";
import "./Recipes.css";

import { useRef, useState } from "react";
import { ViewportList } from "react-viewport-list";

import { CircularProgress, Stack } from "@mui/material";

import RecipeCard from "./RecipeCard";
import { Recipe } from "./types";

export function RecipeGrid({ recipes }: { recipes: Recipe[] }) {
  // TODO - make this dynamic to fetch more results.
  const [isLoading] = useState(false);
  const [numRecipesFetched] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);

  if (recipes.length === 0) return null;
  const handleScroll = () => {};

  return (
    <div className="scroll-container" ref={ref} onScroll={handleScroll}>
      {isLoading && (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
      <ViewportList
        viewportRef={ref}
        count={recipes.length / 2}
        indexesShift={numRecipesFetched}
      >
        {(index: number) => (
          <Stack
            key={index}
            gap={1}
            direction="row"
            sx={{ padding: "12px" }}
            justifyContent="center"
          >
            <RecipeCard recipeId={recipes[index * 2].id} />
            {index * 2 + 1 < recipes.length && (
              <RecipeCard recipeId={recipes[index * 2 + 1].id} />
            )}
          </Stack>
        )}
      </ViewportList>
    </div>
  );
}
