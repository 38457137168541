import { useNavigate } from "react-router-dom";

import { Avatar, Box, Skeleton, Stack, SxProps, Theme } from "@mui/material";

import { useUser } from ".";
import { TruncatedText } from "../../common/components/TruncatedText";

export function UserAvatar({
  userId,
  width,
  height,
  showName,
  sx,
  clickable,
  notClickable,
}: {
  userId: string;
  height: number;
  width: number;
  showName?: boolean;
  sx?: SxProps<Theme>;
  clickable?: boolean;
  notClickable?: boolean;
}) {
  const user = useUser({ userId });
  const navigate = useNavigate();

  if (!user) return <Skeleton width={width} height={height} />;

  return (
    <Box
      sx={{ height: height }}
      onClick={notClickable ? () => {} : () => navigate(`/profiles/${userId}`)}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Avatar
          alt="Logo"
          src={user.avatar_url ?? ""}
          sx={{ ...sx, width, height }}
        />
        {showName && (
          <TruncatedText
            variant="caption"
            maxLines={1}
            charLimit={34}
            text={`@${user.username}`}
          />
        )}
      </Stack>
    </Box>
  );
}
