import { SxProps, Theme, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

export type TruncatedTextPros = {
  text: string;
  wordLimit?: number;
  charLimit?: number;
  maxLines?: number;
  variant?: Variant;
  color?: string;
  gutterBottom?: boolean;
  sx?: SxProps<Theme>;
};

export function TruncatedText({
  text,
  wordLimit,
  charLimit,
  maxLines,
  variant,
  color,
  gutterBottom,
  sx,
}: TruncatedTextPros) {
  let truncated = text;
  if (charLimit) {
    truncated = text.slice(0, charLimit);
  } else if (wordLimit) {
    const words = text.split(" ");
    truncated = words.slice(0, wordLimit).join(" ");
  }
  const hasMore = text.length > truncated.length;

  return (
    <Typography
      variant={variant ?? "body2"}
      color={color ?? "text.secondary"}
      sx={{
        ...sx,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: `${maxLines}` ?? `1`,
        WebkitBoxOrient: "vertical",
      }}
      gutterBottom={gutterBottom}
    >
      {truncated}
      {hasMore && <span>...</span>}
    </Typography>
  );
}
