import { useParams } from "react-router-dom";

import { PageLayout } from "../common";
import { TopChrome } from "../features/chrome";

export function CalendarDayPage() {
  const params = useParams();
  const yearString = params?.yyyy ?? "unknown";
  const monthString = params?.mm ?? "unknown";
  const dayString = params?.dd ?? "unknown";
  return (
    <PageLayout topChrome={<TopChrome />}>
      {`Year: ${yearString}, Month: ${monthString}, Day: ${dayString}`}
    </PageLayout>
  );
}
