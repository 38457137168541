import { useCallback, useContext, useState } from "react";

import { Button } from "@mui/material";

import { RecipeSearchModal } from "./RecipeSearchModal";
import {
  SelectableRecipeContext,
  SelectableRecipeContextProvider,
} from "./context";

export function SelectRecipesButton() {
  const [displayModal, setDisplayModal] = useState(false);
  const { handleSelectRecipeId } = useContext(SelectableRecipeContext);
  const wrappedOnRecipeSelected = useCallback(
    (recipeId: string) => {
      setDisplayModal(false);
      if (handleSelectRecipeId) {
        handleSelectRecipeId(recipeId);
      }
    },
    [handleSelectRecipeId],
  );
  return (
    <>
      <Button
        onClick={() => {
          setDisplayModal(true);
        }}
      >
        Select Recipes
      </Button>
      <SelectableRecipeContextProvider
        value={{ handleSelectRecipeId: wrappedOnRecipeSelected }}
      >
        <RecipeSearchModal
          isOpen={displayModal}
          onClose={() => {
            setDisplayModal(false);
          }}
        />
      </SelectableRecipeContextProvider>
    </>
  );
}
