import { createContext } from "react";

import { RouxSupabaseClient } from "./types";

export const SupabaseContext = createContext<RouxSupabaseClient | undefined>(
  undefined,
);
export const SupabaseProvider = SupabaseContext.Provider;
export const SupabaseConsumer = SupabaseContext.Consumer;
SupabaseContext.displayName = "SupabaseContext";
