import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../common";
import { fetchPublishers, selectPublisherById } from "./publishersSlice";
import { useClient } from "../../common/supabase/hooks/useClient";

export function usePublisher({ publisherId }: { publisherId: string }) {
  const publisher = useAppSelector((state) => {
    return selectPublisherById(state, publisherId);
  });

  const dispatch = useAppDispatch();
  const supabase = useClient();

  const fetchApiPublisher = useCallback(async () => {
    if (!publisher && publisherId !== "unknown") {
      dispatch(
        fetchPublishers({
          supabase,
          publisherIds: [publisherId],
          errorCallback: console.log,
          successCallback: () => {},
        })
      );
    }
  }, [dispatch, supabase, publisherId, publisher]);

  useMemo(async () => {
    if (!publisher) {
      fetchApiPublisher();
    }
  }, [fetchApiPublisher, publisher]);

  return publisher || null;
}
