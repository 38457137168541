import "../../common/Common.css";

import { useEffect, useRef } from "react";
import { ViewportList } from "react-viewport-list";

import { Divider, Stack } from "@mui/material";

import { CalendarAvatar, fetchAllCalendars, selectAllCalendars } from ".";
import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";

import fuzzysort from "fuzzysort";

export function CalendarList({ searchText }: { searchText?: string }) {
  const rawCalendars = useAppSelector(selectAllCalendars).sort((a, b) =>
    a.title > b.title ? 1 : -1,
  );
  const matchingCalendars =
    !searchText || searchText === ""
      ? rawCalendars
      : fuzzysort
          .go(searchText, rawCalendars, { key: "title" })
          .map((res) => res.obj);
  const ref = useRef<HTMLDivElement | null>(null);
  const handleScroll = () => {};
  const dispatch = useAppDispatch();
  const supabase = useClient();

  useEffect(() => {
    dispatch(
      fetchAllCalendars({
        supabase,
        successCallback: () => {},
        errorCallback: console.log,
      }),
    );
  }, [dispatch, supabase]);

  return (
    <div className="scroll-container" ref={ref} onScroll={handleScroll}>
      <ViewportList viewportRef={ref} count={matchingCalendars.length}>
        {(index: number) => (
          <Stack gap={2} sx={{ marginBottom: 2 }}>
            <Divider sx={{ width: "100%" }} />
            <CalendarAvatar
              calendarId={matchingCalendars[index].id}
              height={48}
              width={48}
              showName
            />
          </Stack>
        )}
      </ViewportList>
    </div>
  );
}
