import "../chrome/Chrome.css";

import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";

import { rouxTopChromeHeightPixels } from "../../common";
import { useCalendar } from "./hooks";

export function CalendarTopChrome({ calendarId }: { calendarId: string }) {
  const calendar = useCalendar({ calendarId });

  return (
    <Box className="top-chrome" sx={{ height: rouxTopChromeHeightPixels }}>
      <AppBar position="static" sx={{ height: "100%" }}>
        <Toolbar sx={{ height: "100%", minHeight: rouxTopChromeHeightPixels }}>
          {calendar && (
            <>
              <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
                <Typography variant="h6">{calendar.title}</Typography>
              </Stack>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
