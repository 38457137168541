import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../common/store";
import { ApiThunkArgs } from "../../common/types";
import { RecipeComment } from "./types";

export interface CommentsState {
  commentsByRecipeId: {
    [recipeId: string]: { [commentId: string]: RecipeComment };
  };
  commentsByUserId: {
    [userId: string]: { [commentId: string]: RecipeComment };
  };
}

const initialState: CommentsState = {
  commentsByRecipeId: {},
  commentsByUserId: {},
};

export const fetchRecipeComments = createAsyncThunk(
  "comments/fetchRecipeComments",
  async ({
    recipeId,
    supabase,
    errorCallback,
    successCallback,
  }: { recipeId: string } & ApiThunkArgs) => {
    const { data, error } = await supabase
      .from("recipe_comments")
      .select()
      .eq("recipe_id", recipeId)
      .order("added_ts", { ascending: false });
    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return data;
  },
);

export const fetchUsersRecipeComments = createAsyncThunk(
  "comments/fetchUsersRecipeComments",
  async ({
    userId,
    supabase,
    errorCallback,
  }: { userId: string } & ApiThunkArgs) => {
    const { data, error } = await supabase
      .from("recipe_comments")
      .select()
      .eq("user_id", userId)
      .order("added_ts", { ascending: false });

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return data;
  },
);

export const saveNewRecipeComment = createAsyncThunk(
  "comments/saveNewRecipeComment",
  async ({
    comment,
    supabase,
    errorCallback,
    successCallback,
  }: { comment: RecipeComment } & ApiThunkArgs) => {
    const { error } = await supabase.from("recipe_comments").insert(comment);
    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return comment;
  },
);

export const commentsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveNewRecipeComment.fulfilled, (state, action) => {
        const newComment = action.payload;
        state.commentsByUserId[newComment.user_id] = {
          ...(state.commentsByUserId[newComment.user_id] ?? {}),
          [newComment.id]: newComment,
        };
        state.commentsByRecipeId[newComment.recipe_id] = {
          ...(state.commentsByRecipeId[newComment.recipe_id] ?? {}),
          [newComment.id]: newComment,
        };
      })
      .addCase(fetchRecipeComments.fulfilled, (state, action) => {
        const comments = action.payload as RecipeComment[];
        comments.forEach((c) => {
          state.commentsByRecipeId[c.recipe_id] = {
            ...(state.commentsByRecipeId[c.recipe_id] ?? {}),
            [c.id]: c,
          };

          state.commentsByUserId[c.user_id] = {
            ...(state.commentsByUserId[c.user_id] ?? {}),
            [c.id]: c,
          };
        });
      })
      .addCase(fetchUsersRecipeComments.fulfilled, (state, action) => {
        const comments = action.payload as RecipeComment[];
        comments.forEach((c) => {
          state.commentsByRecipeId[c.recipe_id] = {
            ...(state.commentsByRecipeId[c.recipe_id] ?? {}),
            [c.id]: c,
          };

          state.commentsByUserId[c.user_id] = {
            ...(state.commentsByUserId[c.user_id] ?? {}),
            [c.id]: c,
          };
        });
      });
  },
});

export const selectCommentsForRecipeId = (state: RootState, id: string) =>
  state.commentsStore.commentsByRecipeId[id];

export const selectCommentsForUserId = (state: RootState, id: string) =>
  state.commentsStore.commentsByUserId[id];

export default commentsSlice.reducer;
