import { useCallback } from "react";

import { Add, Check } from "@mui/icons-material";
import { Chip } from "@mui/material";

import { UserAvatar } from "./UserAvatar";

export function UserChip({
  userId,
  onSelect,
  toBeRemoved,
  toBeAdded,
  adding,
}: {
  userId: string;
  onSelect?: ({ userId }: { userId: string }) => void;
  toBeRemoved?: boolean;
  toBeAdded?: boolean;
  adding?: boolean;
}) {
  const selectFunction = useCallback(() => {
    if (onSelect) {
      onSelect({ userId });
    }
  }, [onSelect, userId]);

  return (
    <Chip
      key={userId}
      icon={
        <UserAvatar
          userId={userId}
          width={24}
          height={24}
          showName
          notClickable
        />
      }
      sx={{ padding: 1 }}
      onDelete={selectFunction}
      deleteIcon={
        (adding && toBeAdded && <Check />) || (adding && <Add />) || undefined
      }
      color={toBeRemoved ? "warning" : toBeAdded ? "success" : "default"}
    />
  );
}
