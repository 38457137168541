import { useCallback, useState } from "react";

import { Button, Skeleton, Stack } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { selectCurrentUser } from "../users";
import { EditCalendarModal } from "./EditCalendarModal";
import { followCalendar, unfollowCalendar } from "./calendarSlice";
import { useCalendar } from "./hooks";

export function CalendarFollowOrEditButton({
  calendarId,
}: {
  calendarId: string;
}) {
  const calendar = useCalendar({ calendarId });
  const currentUser = useAppSelector(selectCurrentUser);
  const isEditor = calendar?.editors?.includes(currentUser?.id ?? "");
  const alreadyFollowsCalendar = calendar?.followers?.includes(
    currentUser?.id ?? "",
  );
  const dispatch = useAppDispatch();
  const supabase = useClient();
  const [showEditModal, setShowEditModal] = useState(false);

  const onEdit = useCallback(() => {
    if (!calendar || !currentUser) {
      return;
    }
    setShowEditModal(true);
  }, [setShowEditModal, calendar, currentUser]);

  const onCloseEditModal = useCallback(() => {
    setShowEditModal(false);
  }, []);

  const onFollow = useCallback(() => {
    if (!currentUser) return;
    if (alreadyFollowsCalendar) {
      dispatch(
        unfollowCalendar({
          supabase,
          calendarId,
          currentUser,
          successCallback: () => {},
          errorCallback: console.log,
        }),
      );
    } else {
      dispatch(
        followCalendar({
          supabase,
          calendarId,
          currentUser,
          successCallback: () => {},
          errorCallback: console.log,
        }),
      );
    }
  }, [dispatch, supabase, calendarId, currentUser, alreadyFollowsCalendar]);

  if (!currentUser) return <Skeleton />;

  return (
    <Stack alignItems="center">
      {isEditor && (
        <Button onClick={onEdit} sx={{ width: "120px" }} variant="contained">
          Edit
        </Button>
      )}
      {!isEditor && (
        <Button
          onClick={onFollow}
          sx={{ width: "120px" }}
          variant={alreadyFollowsCalendar ? "outlined" : "contained"}
        >
          {alreadyFollowsCalendar ? "Unfollow" : "Follow"}
        </Button>
      )}
      {showEditModal && (
        <EditCalendarModal
          calendarId={calendarId}
          isOpen={showEditModal}
          onClose={onCloseEditModal}
        />
      )}
    </Stack>
  );
}
