import uuid from "react-uuid";

import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "../../common/store";
import {
  TablesInsert,
  TablesUpdate,
} from "../../common/supabase/generated_dbtypes";
import { ApiThunkArgs, AuthedApiThunkArgs } from "../../common/types";
import { Post, PostType } from "./types";

import { merge } from "lodash";

type PostTombstone = string;
type PostIdsByDate = { [date: string]: string[] };

export interface PostsState {
  posts: { [postId: string]: Post | PostTombstone };
  postIdsByDate: { [date: string]: string[] };
  postIdsByUser: { [userId: string]: PostIdsByDate };
  postIdsByCalendar: { [calendarId: string]: PostIdsByDate };
}

const initialState: PostsState = {
  posts: {},
  postIdsByCalendar: {},
  postIdsByDate: {},
  postIdsByUser: {},
};

export const deletePost = createAsyncThunk(
  "posts/deletePost",
  async ({
    post,
    supabase,
    errorCallback,
  }: {
    post: Post;
  } & ApiThunkArgs) => {
    const { error } = await supabase
      .from("posts")
      .update({
        deleted_ts: new Date().toISOString(),
      })
      .eq("id", post.id);
    if (error) {
      errorCallback(error.message);
      throw error;
    }
    return post.id;
  },
);

export const createPost = createAsyncThunk(
  "posts/createPost",
  async ({
    post,
    scheduledDate,
    images,
    recipeIds,
    currentUser,
    supabase,
    errorCallback,
  }: {
    post: TablesInsert<"posts">;
    scheduledDate?: Date;
    images: File[];
    recipeIds: string[];
  } & AuthedApiThunkArgs) => {
    // Insert the post itself.
    const { error: postError } = await supabase.from("posts").insert([post]);
    if (postError) {
      errorCallback(postError.message);
      throw postError;
    }

    // Upload the images.
    await Promise.all(
      images.map(async (image) => {
        const imageId = uuid();
        const { error } = await supabase.storage
          .from("post_photos")
          .upload(`${post.id}/${currentUser.id}/${imageId}`, image);
        if (error) {
          console.log(error.message);
          throw error;
        }

        const record = {
          photo_id: imageId,
          post_id: post.id,
          added_by: currentUser.id,
          added_ts: new Date().toISOString(),
        };

        const { error: sqlError } = await supabase
          .from("post_photos")
          .insert(record);
        if (sqlError) {
          errorCallback(sqlError.message);
          throw sqlError;
        }
      }),
    );

    // Attach any recipes.
    await Promise.all(
      recipeIds.map(async (recipeId) => {
        const recipe_row = {
          recipe_id: recipeId,
          post_id: post.id,
        };
        const { error } = await supabase
          .from("post_recipes")
          .insert(recipe_row);
        if (error) {
          errorCallback(error.message);
          throw error;
        }
      }),
    );

    if (
      post.calendar_id &&
      post.type === PostType.CalendarEntry &&
      scheduledDate
    ) {
      const scheduledPostRow = {
        calendar_id: post.calendar_id,
        post_id: post.id,
        scheduled_date: scheduledDate.toISOString(),
      };

      await supabase.from("scheduled_posts").insert([scheduledPostRow]);
    }

    // Fetch the new post, and upsert.
    const { data, error: fetchError } = await supabase.functions.invoke(
      "posts-get",
      {
        body: { postIds: [post.id] },
      },
    );

    if (fetchError) {
      errorCallback(fetchError.message);
      throw fetchError;
    }

    return data != null ? (data[0] as Post) : data;
  },
);

export const editPost = createAsyncThunk(
  "posts/editPost",
  async ({
    post,
    originalPost,
    scheduledDate,
    originalScheduledDate,
    postId,
    images,
    recipeIds,
    supabase,
    errorCallback,
  }: {
    post: TablesUpdate<"posts">;
    originalPost: Post;
    originalScheduledDate?: Date;
    scheduledDate?: Date;
    postId: string;
    images: File[];
    recipeIds: string[];
  } & ApiThunkArgs) => {
    // YOU ARE HERE -- trying to make rescheduling work.
    // You realize you don't need post.data.scheudled_date,
    // since oyu have the scheduled_posts join table. So use that
    const { error } = await supabase
      .from("posts")
      .update(post)
      .eq("id", postId);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    // Update any recipes.
    const recipesToDelete = originalPost.recipeIds.filter(
      (recipeId) => recipeIds.indexOf(recipeId) === -1,
    );
    await Promise.all(
      recipesToDelete.map(async (recipeId) => {
        const { error } = await supabase
          .from("post_recipes")
          .delete()
          .eq("post_id", originalPost.id)
          .eq("recipe_id", recipeId);
        if (error) {
          errorCallback(error.message);
          throw error;
        }
      }),
    );

    const recipesToAdd = recipeIds.filter(
      (recipeId) => originalPost.recipeIds.indexOf(recipeId) === -1,
    );
    await Promise.all(
      recipesToAdd.map(async (recipeId) => {
        const recipe_row = {
          recipe_id: recipeId,
          post_id: originalPost.id,
        };
        const { error } = await supabase
          .from("post_recipes")
          .insert(recipe_row);
        if (error) {
          errorCallback(error.message);
          throw error;
        }
      }),
    );

    if (
      post.calendar_id &&
      originalPost.type === PostType.CalendarEntry &&
      scheduledDate &&
      originalScheduledDate &&
      scheduledDate !== originalScheduledDate
    ) {
      // delete old scheduled date
      await supabase
        .from("scheduled_posts")
        .delete()
        .eq("post_id", postId)
        .eq("calendar_id", originalPost.calendarId)
        .eq(
          "scheduled_date",
          originalScheduledDate.toISOString().substring(0, 10),
        );

      // insert new scheduled date
      const scheduledPostRow = {
        calendar_id: post.calendar_id,
        post_id: postId,
        scheduled_date: scheduledDate.toISOString().substring(0, 10),
      };

      await supabase.from("scheduled_posts").insert([scheduledPostRow]);
    }

    // Fetch the new post, and upsert.
    const { data, error: fetchError } = await supabase.functions.invoke(
      "posts-get",
      {
        body: { postIds: [postId] },
      },
    );

    if (fetchError) {
      errorCallback(fetchError.message);
      throw fetchError;
    }

    return data != null ? (data[0] as Post) : data;
  },
);

export const fetchPost = createAsyncThunk(
  "posts/fetchPost",
  async ({
    postId,
    supabase,
    errorCallback,
  }: {
    postId: string;
  } & ApiThunkArgs) => {
    const { data, error: fetchError } = await supabase.functions.invoke(
      "posts-get",
      {
        body: { postIds: [postId] },
      },
    );

    if (fetchError) {
      errorCallback(fetchError.message);
      throw fetchError;
    }

    return data != null ? (data[0] as Post) : data;
  },
);

export const fetchPostsForCalendarsForDateRange = createAsyncThunk(
  "posts/fetchPostsForCalendarsForDateRange",
  async ({
    from,
    to,
    calendarIds,
    supabase,
    errorCallback,
    successCallback,
  }: {
    from: Date;
    to: Date;
    calendarIds: string[];
  } & ApiThunkArgs) => {
    const { data: postData, error } = await supabase.functions.invoke(
      "posts-get",
      {
        body: {
          calendarIds,
          calendarStartDate: from.toISOString(),
          calendarEndDate: to.toISOString(),
        },
      },
    );

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    successCallback();

    const posts = (postData != null ? (postData as Post[]) : []).reduce(
      (acc, p) => {
        acc[p.id] = p;
        return acc;
      },
      {} as { [postId: string]: Post },
    );

    const postIdsToDates = (
      postData != null ? (postData as Post[]) : []
    ).reduce(
      (acc, p) => {
        acc[p.id] = new Date(p.scheduledTs ?? p.addedTs)
          .toISOString()
          .slice(0, 10);
        return acc;
      },
      {} as { [postId: string]: string },
    );
    const postIdsByCalendar = Object.keys(postIdsToDates).reduce(
      (acc, postId) => {
        const post = posts[postId];
        if (!post.calendarId) return acc;

        const calendarId = post.calendarId;
        if (!acc[calendarId]) {
          acc[calendarId] = {};
        }

        const date = postIdsToDates[postId];
        if (!acc[calendarId][date]) {
          acc[calendarId][date] = [];
        }

        acc[calendarId][date].push(postId);

        return acc;
      },
      {} as { [calendarId: string]: PostIdsByDate },
    );

    return {
      postIdsByCalendar,
      posts,
    };
  },
);

export const fetchAllPosts = createAsyncThunk(
  "posts/fetchAllPosts",
  async ({ supabase, successCallback, errorCallback }: AuthedApiThunkArgs) => {
    const { data, error } = await supabase.functions.invoke("feed-get", {
      body: { olderThanTs: new Date().toISOString() },
    });

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    successCallback();

    const posts = data != null ? (data as Post[]) : [];
    const postIdsByDate = posts.reduce(
      (acc, post) => {
        const day = new Date(post.addedTs);
        day.setHours(0, 0, 0, 0);

        const dayKey = day.toISOString().slice(0, 10);
        if (acc[dayKey] == null) {
          acc[dayKey] = [post.id];
        } else {
          acc[dayKey].push(post.id);
        }

        return acc;
      },
      {} as { [date: string]: string[] },
    );
    const postsById = posts.reduce(
      (acc, post) => {
        acc[post.id] = post;
        return acc;
      },
      {} as { [postId: string]: Post },
    );
    return {
      postIdsByDate,
      posts: postsById,
    };
  },
);

export const fetchPostsByUser = createAsyncThunk(
  "posts/fetchPostsByUser",
  async ({
    supabase,
    userId,
    successCallback,
    errorCallback,
  }: AuthedApiThunkArgs & { userId: string }) => {
    const { data, error } = await supabase.functions.invoke("posts-get", {
      body: { userId },
    });

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    successCallback();

    const posts = data != null ? (data as Post[]) : [];
    const postIdsByUser = posts.reduce(
      (acc, post) => {
        const day = new Date(post.addedTs);
        day.setHours(0, 0, 0, 0);

        const dayKey = day.toISOString().slice(0, 10);
        if (acc[dayKey] == null) {
          acc[dayKey] = [post.id];
        } else {
          acc[dayKey].push(post.id);
        }

        return acc;
      },
      {} as { [date: string]: string[] },
    );
    const postsById = posts.reduce(
      (acc, post) => {
        acc[post.id] = post;
        return acc;
      },
      {} as { [postId: string]: Post },
    );
    return {
      postIdsByUser,
      posts: postsById,
    };
  },
);

export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPosts.fulfilled, (state, action) => {
        merge(state.postIdsByDate, action.payload.postIdsByDate);
        merge(state.posts, action.payload.posts);
      })
      .addCase(fetchPostsByUser.fulfilled, (state, action) => {
        merge(state.postIdsByUser, action.payload.postIdsByUser);
        merge(state.posts, action.payload.posts);
      })
      .addCase(
        fetchPostsForCalendarsForDateRange.fulfilled,
        (state, action) => {
          merge(state.postIdsByCalendar, action.payload.postIdsByCalendar);
          merge(state.posts, action.payload.posts);
        },
      )
      .addCase(deletePost.fulfilled, (state, action) => {
        const postId = action.payload;
        state.posts[postId] = "deleted";
      })
      .addCase(fetchPost.fulfilled, (state, action) => {
        const postId = action.payload.id;
        state.posts[postId] = action.payload;
      })
      .addCase(editPost.fulfilled, (state, action) => {
        const post = action.payload;
        state.posts[post.id] = action.payload;

        if (
          action.payload.type === PostType.CalendarEntry &&
          action.meta.arg.originalScheduledDate &&
          action.meta.arg.scheduledDate
        ) {
          const newDate = action.meta.arg.scheduledDate
            .toISOString()
            .substring(0, 10);
          const oldDate = action.meta.arg.originalScheduledDate
            .toISOString()
            .substring(0, 10);

          if (newDate !== oldDate) {
            if (!state.postIdsByCalendar[post.calendarId]) {
              state.postIdsByCalendar[post.calendarId] = {};
            }

            if (!state.postIdsByCalendar[post.calendarId][newDate]) {
              state.postIdsByCalendar[post.calendarId][newDate] = [];
            }

            // SHouldn't relaly be necssary, but
            if (!state.postIdsByCalendar[post.calendarId][oldDate]) {
              state.postIdsByCalendar[post.calendarId][oldDate] = [];
            }

            state.postIdsByCalendar[post.calendarId][newDate] =
              state.postIdsByCalendar[post.calendarId][newDate].concat(post.id);

            state.postIdsByCalendar[post.calendarId][oldDate] =
              state.postIdsByCalendar[post.calendarId][oldDate].filter(
                (postId) => postId !== post.id,
              );
          }
        }
      })
      .addCase(createPost.fulfilled, (state, action) => {
        const post = action.payload as Post;
        const dateTs = post.scheduledTs ?? post.addedTs;
        const date = new Date(dateTs).toISOString().slice(0, 10);
        if (post.calendarId) {
          if (!state.postIdsByCalendar[post.calendarId]) {
            state.postIdsByCalendar[post.calendarId] = {};
          }

          if (!state.postIdsByCalendar[post.calendarId][date]) {
            state.postIdsByCalendar[post.calendarId][date] = [];
          }

          state.postIdsByCalendar[post.calendarId][date] =
            state.postIdsByCalendar[post.calendarId][date].concat(post.id);
        }
        state.posts[post.id] = post;
        state.postIdsByDate[date] = state.postIdsByDate[date]
          ? [...state.postIdsByDate[date], post.id]
          : [post.id];
      });
  },
});

export const selectPostById = (state: RootState, postId: string) => {
  const post = state.postsStore.posts[postId];
  if (post && typeof post !== "string") {
    return post;
  }
  return undefined;
};

export const selectAllPosts = createSelector(
  (state: RootState) => {
    return {
      postIdsByDate: state.postsStore.postIdsByDate,
      posts: state.postsStore.posts,
    };
  },
  (postsStore) =>
    Object.values(postsStore.postIdsByDate)
      .reduce((acc, postIds) => {
        return acc.concat(postIds);
      }, [] as string[])
      .map((postsId) => {
        return postsStore.posts[postsId];
      })
      .filter<Post>((post): post is Post => typeof post !== "string")
      .filter((post) => post.type === PostType.Generic)
      .reverse(),
);

export const selectPostsByUser = createSelector(
  [
    (state: RootState) => {
      return {
        postIdsByUser: state.postsStore.postIdsByUser,
        posts: state.postsStore.posts,
      };
    },
    (_: any, userId: string) => userId,
  ],
  (postsStore, userId) =>
    Object.values(postsStore.postIdsByUser[userId] ?? {})
      .reduce((acc, posts) => {
        return acc.concat(posts);
      }, [] as string[])
      .map((postId) => {
        return postsStore.posts[postId];
      })
      .filter<Post>((post): post is Post => typeof post !== "string")
      .reverse(),
);

export const selectPostsByCalendarsAndDate = createSelector(
  [
    (state: RootState) => {
      return {
        postIdsByCalendar: state.postsStore.postIdsByCalendar,
        posts: state.postsStore.posts,
      };
    },
    (_: any, calendarIds: string[]) => calendarIds,
    (_0: any, _1: any, date: string) => date,
  ],
  (postsStore, calendarIds, date) =>
    calendarIds
      .reduce((acc, calendarId) => {
        Object.keys(postsStore.postIdsByCalendar[calendarId] ?? {}).forEach(
          (calendarDate) => {
            if (calendarDate !== date) return;
            acc = acc.concat(postsStore.postIdsByCalendar[calendarId][date]);
          },
        );
        return acc;
      }, [] as string[])
      .map((postsId) => {
        return postsStore.posts[postsId];
      })
      .filter<Post>((post): post is Post => typeof post !== "string"),
);

export default postsSlice.reducer;
