// TODO - move to features/photos?
import { useCallback, useState } from "react";
import { useSwipeable } from "react-swipeable";

import { Box, Modal } from "@mui/material";
import { ImageListItem, ImageListItemBar } from "@mui/material";

function ExpandedPhotoModal({
  open,
  photoSrc,
  title,
  actionIcon,
  onTitleClick,
  onClose,
}: {
  open: boolean;
  photoSrc: string;
  actionIcon?: React.ReactNode;
  onTitleClick?: () => void;
  title?: React.ReactNode;
  onClose: () => void;
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ width: "100vw", height: "100vh" }}
    >
      <Box className="centered" sx={{ width: "400px", height: "400px" }}>
        <ImageListItem sx={{}}>
          <img
            src={photoSrc}
            alt={"User uploaded"}
            loading="lazy"
            style={{
              height: "400px",
              width: "400px",
              objectFit: "cover",
              borderRadius: "6px",
            }}
          />
          {(actionIcon || title) && (
            <ImageListItemBar
              title={title}
              actionIcon={actionIcon}
              sx={{
                paddingRight: 2,
              }}
              onClick={onTitleClick}
            />
          )}
        </ImageListItem>
      </Box>
    </Modal>
  );
}

// TODO - move to features/photos?
export function ExpandedPhoto({
  photoSrc,
  actionIcon,
  onTitleClick,
  dontExpand,
  width,
  height,
  title,
  handlers,
}: {
  photoSrc: string;
  actionIcon?: React.ReactNode;
  width?: string;
  height?: string;
  dontExpand?: boolean;
  onTitleClick?: () => void;
  title?: React.ReactNode;
  handlers?: {
    onSwipedRight?: () => void;
    onSwipedLeft?: () => void;
  };
}) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleImageClick = useCallback(() => {
    if (dontExpand) return;
    setShowModal(true);
  }, [setShowModal, dontExpand]);
  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);
  const config = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  };
  const swipeHandlers = useSwipeable({
    ...(handlers ?? {}),
    ...config,
  });
  return (
    <>
      <Box>
        <ImageListItem sx={{}}>
          <img
            {...(swipeHandlers ?? {})}
            src={photoSrc}
            alt={"User uploaded"}
            loading="lazy"
            style={{
              height: height ?? "100vw",
              width: width ?? "100vw",
              objectFit: "cover",
              borderRadius: height ? "6px" : "0px",
            }}
            onClick={handleImageClick}
          />
          {(actionIcon || title) && (
            <ImageListItemBar
              title={title}
              actionIcon={actionIcon}
              sx={{
                paddingRight: 2,
                borderRadius: "6px",
              }}
              onClick={onTitleClick}
            />
          )}
        </ImageListItem>
      </Box>
      <ExpandedPhotoModal
        open={showModal}
        photoSrc={photoSrc}
        onClose={handleModalClose}
      />
    </>
  );
}
