import "./CalendarPage.css";

import { useState } from "react";

import { Add } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";

import { PageLayout, useAppSelector } from "../common";
import {
  rouxBottomChromeHeightPixels,
  rouxTopChromeHeightPixels,
} from "../common/constants";
import {
  AddCalendarModal,
  CalendarAvatar,
  CalendarDayList,
} from "../features/calendar";
import { TopChrome } from "../features/chrome";
import { selectCurrentUser } from "../features/users";

export function CalendarPage() {
  const currentUser = useAppSelector(selectCurrentUser);
  const [displayCreateCalendar, setDisplayCreateCalendar] = useState(false);

  return (
    <PageLayout topChrome={<TopChrome />}>
      <Stack
        sx={{
          overflow: "hidden",
          paddingTop: "12px",
          height: `calc(100vh - ${rouxTopChromeHeightPixels} - ${rouxBottomChromeHeightPixels})`,
        }}
      >
        <div className="calendar-page-first-row">
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ overflowX: "scroll", padding: 1 }}
          >
            <IconButton
              key="add"
              color="primary"
              onClick={() => {
                setDisplayCreateCalendar(true);
              }}
            >
              <Add />
            </IconButton>
            {Object.values(currentUser?.calendars || []).map((c) => {
              return (
                <CalendarAvatar
                  key={c.id}
                  calendarId={c.id}
                  height={36}
                  width={36}
                />
              );
            })}
          </Stack>
        </div>
        <CalendarDayList
          calendarIds={Object.keys(currentUser?.calendars || [])}
        />
      </Stack>
      <AddCalendarModal
        isOpen={displayCreateCalendar}
        onClose={() => {
          setDisplayCreateCalendar(false);
        }}
      />
    </PageLayout>
  );
}
