export enum RecipeType {
  Manual = "manual",
  Url = "url",
  Book = "book",
  Restaurant = "restaurant",
}

export enum RecipeApiErrorType {
  AlreadyExists = "already_exists",
  Unknown = "unknown",
}

export type ManualRecipe = {
  // Required.
  instructions: string;
  // Required.
  ingredients: string;
  // Optional.
  description?: string;
};

export type UrlRecipe = {
  // Required.
  url: string;
  // Required.
  site_name: string;
};

export type Recipe = {
  id: string;
  // Required. What is this recipe called such that you can refer to it?
  // Right now: we don't assume this is enough to uniquely identify a recipe.
  title: string;
  // Optional.
  description?: string;
  // Optional
  imageUrl?: string;
  // Optional. Free-text field for names/users/whatever (corporation?)
  createdBy?: string;
  // Required. 'manual', 'url', 'book', or 'restaurant'
  type: string | number;
  // Optional, user who created. Null if publisher created.
  addedBy?: string;
  // Required.
  addedTs: string;
  // Required. If true this recipe is visible to all of Roux.
  isPublic: boolean;
  updatedBy?: string;
  updatedTs?: number; // ?
  // Optional; Type-specific data for the recipes. Things that are applicable
  // to all should be yanked out of this eventually. Maybe?
  data?: { url?: string; favicon_url?: string; site_name?: string };
  numReviews: number;
  avgReviewScore: number;
  numComments: number;
  numPhotos: number;
  numSaves: number;
};

export type RecipePhotoUpload = {
  id: string;
  user_id: string;
  recipe_id: string;
  added_ts: string;
};
