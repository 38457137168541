import { ChangeEvent, useCallback, useState } from "react";
import uuid from "react-uuid";

import { AddAPhoto, Cancel } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  ImageList,
  ImageListItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import {
  rouxRedHex,
  rouxTopChromeHeightPixels,
  useAppDispatch,
  useAppSelector,
} from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { AddPhotosButton } from "../photos";
import { selectCurrentUser } from "../users";
import { fetchRecipePhotoUploads } from "./recipesSlice";

export function UploadRecipePhotoDrawerMenu({
  recipeId,
}: {
  recipeId: string;
}) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [images, setImages] = useState<File[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const currentUser = useAppSelector(selectCurrentUser);
  const handleClose = useCallback(() => {
    setShowDrawer(false);
    setImages([]);
  }, []);
  const supabase = useClient();
  const dispatch = useAppDispatch();

  const handleSave = useCallback(async () => {
    if (!currentUser) return;
    setIsSaving(true);

    await Promise.all(
      images.map(async (image) => {
        const imageId = uuid();
        const { error } = await supabase.storage
          .from("recipe_photos")
          .upload(`${recipeId}/${currentUser.id}/${imageId}`, image);
        if (error) {
          console.log(error.message);
          throw error;
        }

        const record = {
          id: imageId,
          recipe_id: recipeId,
          user_id: currentUser.id,
          added_ts: new Date().toISOString(),
        };

        const { error: sqlError } = await supabase
          .from("recipe_photos")
          .insert(record);
        if (sqlError) {
          console.log(sqlError.message);
          throw sqlError;
        }
      }),
    );

    await dispatch(
      fetchRecipePhotoUploads({
        recipeId,
        supabase,
        errorCallback: console.log,
        successCallback: () => {},
      }),
    );
    setIsSaving(false);
    setShowDrawer(false);
    setImages([]);
  }, [supabase, currentUser, images, recipeId, dispatch]);

  const handleAddImages = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;
      if (!currentUser) return;
      const newImages = event.target.files;
      setImages([...newImages, ...images]);
    },
    [currentUser, images],
  );

  if (!currentUser) return <Skeleton />;

  return (
    <>
      <IconButton color="info" onClick={() => setShowDrawer(true)}>
        <AddAPhoto />
      </IconButton>
      <Drawer anchor="bottom" open={showDrawer} onClose={handleClose}>
        <Box
          role="presentation"
          sx={{ width: "100%", height: "700px", overflow: "hidden" }}
        >
          <Stack alignItems="center" sx={{ width: 1, height: 1 }} gap={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              sx={{
                backgroundColor: rouxRedHex,
                height: rouxTopChromeHeightPixels,
                width: "100%",
                padding: 1,
              }}
            >
              <Typography variant="h6" color="white">
                Upload photos
              </Typography>
              <IconButton onClick={handleClose}>
                <Cancel />
              </IconButton>
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="space-between"
              sx={{ height: 1 }}
            >
              {!isSaving && (
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  gap={1}
                  justifyContent="center"
                >
                  <AddPhotosButton handleAddImages={handleAddImages} />
                  <ImageList sx={{ height: "350px" }}>
                    {images.map((image, index) => {
                      return (
                        <ImageListItem
                          key={index}
                          sx={{ maxHeight: "160px", maxWidth: "160px" }}
                        >
                          <img
                            src={URL.createObjectURL(image)}
                            alt={"upload"}
                            loading="lazy"
                            style={{
                              borderRadius: 6,
                              height: "160px",
                              width: "160px",
                            }}
                          />
                        </ImageListItem>
                      );
                    })}
                  </ImageList>
                </Stack>
              )}
              {isSaving && <CircularProgress />}
              <Button
                sx={{ marginBottom: "32px" }}
                variant="contained"
                onClick={handleSave}
              >
                <Typography variant="caption">Upload</Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
