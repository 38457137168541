import React, { useState } from "react";

import { ArrowBackIos } from "@mui/icons-material";
import { Menu } from "@mui/icons-material";
import { Box, Button, Drawer, IconButton, Stack, Tooltip } from "@mui/material";

import { PreferencesList } from ".";
import {
  TruncatedText,
  rouxRedHex,
  rouxTopChromeHeightPixels,
} from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { CurrentUser, UserAvatar } from "../users";

export function PreferencesDrawerMenu({
  currentUser,
}: {
  currentUser: CurrentUser;
}) {
  const supabase = useClient();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const handleLogout = async () => {
    supabase.auth.signOut().then(() => {});
  };

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton onClick={() => setShowDrawer(true)}>
          <Menu htmlColor="white" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <Box role="presentation" sx={{ width: "100%", height: "100vh" }}>
          <Stack justifyContent="center" alignItems="space-around">
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              sx={{
                backgroundColor: rouxRedHex,
                width: "100%",
                padding: 1,
                height: rouxTopChromeHeightPixels,
              }}
            >
              <IconButton
                onClick={() => {
                  setShowDrawer(false);
                }}
              >
                <ArrowBackIos htmlColor="white" />
              </IconButton>
              <UserAvatar
                userId={currentUser.id}
                width={36}
                height={36}
                notClickable
              />
              <TruncatedText
                text={`@${currentUser.username ?? ""}`}
                maxLines={1}
                color="#FFFFFF"
                variant="h5"
              ></TruncatedText>
            </Stack>
            <Box sx={{ paddingTop: 4 }}>
              <PreferencesList />
            </Box>
            <Stack direction="row" justifyContent="center">
              <Button
                onClick={handleLogout}
                variant="contained"
                sx={{ width: "100px" }}
              >
                Log out
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
