import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";

import { Add, Edit, EventBusy, MoreVert } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Fab,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { deleteCalendar, updateCalendar } from ".";
import {
  RouxModal,
  VisuallyHiddenInput,
  useAppDispatch,
  useAppSelector,
} from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { fetchUser, selectCurrentUser } from "../users";
import { UserChip } from "../users/UserChip";
import { UsersPicker } from "../users/UsersPicker";
import { useCalendar } from "./hooks";

interface Props {
  calendarId: string;
  isOpen: boolean;
  onClose?: {
    bivarianceHack(event: {}, reason: "backdropClick" | "escapeKeyDown"): void;
  }["bivarianceHack"];
}

export function EditCalendarModal({ calendarId, isOpen, onClose }: Props) {
  const calendar = useCalendar({ calendarId });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [editorsToBeRemoved, setEditorsToBeRemoved] = useState<{
    [key: string]: boolean;
  }>({});
  const [editorsToBeAdded, setEditorsToBeAdded] = useState<{
    [key: string]: boolean;
  }>({});
  const currentUser = useAppSelector(selectCurrentUser);
  const [title, setTitle] = useState(calendar?.title ?? "");
  const [isPrivate, setIsPrivate] = useState<boolean>(
    calendar?.is_private || false,
  );
  const [showUserPickerModal, setShowUserPickerModal] = useState(false);
  const [actionsMenuEl, setActionsMenuEl] = useState<null | HTMLElement>(null);
  // Note: you don't use calendarUrl because this was the easiest way you could
  // write clean code, but it does mean you're potentially doing too many fetches
  // hopefully the browser is caching?
  const [imageUrl, setImageUrl] = useState(
    calendar?.calendar_photo_url_fragment ?? undefined,
  );
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const [description, setDescription] = useState(calendar?.description ?? "");
  const supabase = useClient();

  const handlePrivateToggle = useCallback(() => {
    setIsPrivate(!isPrivate);
  }, [isPrivate]);

  const uploadImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;
      const image = event.target.files[0];
      const imageUrl = `${uuid()}/${image.name}`;
      const { data, error } = await supabase.storage
        .from("calendar_photos")
        .upload(imageUrl, image);
      // Upload to the bucket.
      if (error) {
        throw error;
      }
      setImageUrl(data.path);
    },
    [supabase],
  );

  const removeEditor = useCallback(
    async ({ userId }: { userId: string }) => {
      setEditorsToBeRemoved({
        ...editorsToBeRemoved,
        [userId]: !!!editorsToBeRemoved[userId],
      });
    },
    [editorsToBeRemoved, setEditorsToBeRemoved],
  );

  const removeEditorToBeAdded = useCallback(
    ({ userId }: { userId: string }) => {
      const newEditors = editorsToBeAdded;
      delete newEditors[userId];
      setEditorsToBeAdded(newEditors);
    },
    [editorsToBeAdded, setEditorsToBeAdded],
  );

  const handleDeleteCalendar = useCallback(async () => {
    if (currentUser === null) return;

    await dispatch(
      deleteCalendar({
        calendarId,
        supabase,
        errorCallback: console.log,
        successCallback: () => {},
      }),
    );

    await dispatch(
      fetchUser({
        userId: currentUser.id,
        supabase,
      }),
    );

    navigate("/");
  }, [dispatch, calendarId, supabase, navigate, currentUser]);

  const handleCloseUserMenu = useCallback(() => {
    setActionsMenuEl(null);
  }, [setActionsMenuEl]);

  const addEditors = useCallback(
    (editorsToAdd: { [key: string]: boolean }) => {
      setEditorsToBeAdded({
        ...editorsToAdd,
      });
      setShowUserPickerModal(false);
    },
    [setEditorsToBeAdded, setShowUserPickerModal],
  );

  const onSave = useCallback(async () => {
    if (!currentUser || !calendar) return;

    await dispatch(
      updateCalendar({
        supabase,
        updatedCalendar: {
          ...calendar,
          title: title,
          description: description,
          calendar_photo_url_fragment: imageUrl ?? null,
          is_private: isPrivate,
        },
        editorsToAdd: editorsToBeAdded,
        editorsToRemove: editorsToBeRemoved,
        successCallback: () => {},
        errorCallback: console.log,
      }),
    );

    // Re-fetch user so that we have the updated calendar.
    await dispatch(fetchUser({ userId: currentUser.id, supabase }));

    if (onClose) {
      onClose({}, "escapeKeyDown");
    }
  }, [
    onClose,
    currentUser,
    calendar,
    dispatch,
    editorsToBeAdded,
    editorsToBeRemoved,
    supabase,
    isPrivate,
    description,
    imageUrl,
    title,
  ]);

  useMemo(async () => {
    if (imageUrl) {
      const { data, error } = await supabase.storage
        .from("calendar_photos")
        .download(imageUrl);
      if (error) {
        throw error;
      }

      setBlobUrl(URL.createObjectURL(data));
    }
  }, [supabase, imageUrl]);
  if (!calendar) return null;

  return (
    <RouxModal isOpen={isOpen} onClose={onClose}>
      <Stack alignItems="center" gap={1}>
        <Stack
          direction="row"
          gap={1}
          justifyContent="center"
          alignItems="end"
          sx={{ width: "100%" }}
        >
          <Avatar
            alt="Logo"
            src={blobUrl ?? ""}
            sx={{
              height: "160px",
              width: "160px",
              position: "relative",
            }}
          />
          <Fab component="label">
            <Edit />
            <VisuallyHiddenInput
              onChange={uploadImage}
              type="file"
              accept="image/*"
            />
          </Fab>
          <Stack
            sx={{ position: "absolute", top: 0, right: 0, margin: 2 }}
            alignItems="start"
          >
            <Button
              onClick={(event) => {
                event.stopPropagation();
                setActionsMenuEl(event.currentTarget);
              }}
            >
              <MoreVert />
            </Button>
          </Stack>
        </Stack>
        <TextField
          placeholder="Title"
          onChange={(event) => setTitle(event.target.value)}
          label={calendar.title !== title ? "Changed" : ""}
          sx={{ width: "300px" }}
          value={title}
        />
        <TextField
          placeholder="Description"
          onChange={(event) => setDescription(event.target.value)}
          sx={{ width: "300px" }}
          label={calendar.description !== description ? "Changed" : ""}
          value={description}
        />
        <Divider sx={{ width: 1 }}>
          <Chip label="Editors" />
        </Divider>
        <Stack direction="row" gap={1} flexWrap="wrap" alignItems="center">
          <IconButton onClick={() => setShowUserPickerModal(true)}>
            <Add />
          </IconButton>
          {calendar.editors.map((e) => {
            return (
              <UserChip
                key={e}
                userId={e}
                onSelect={removeEditor}
                toBeRemoved={editorsToBeRemoved[e]}
              />
            );
          })}
          {Object.keys(editorsToBeAdded).map((e) => {
            return (
              <UserChip
                key={e}
                userId={e}
                toBeAdded
                onSelect={removeEditorToBeAdded}
              />
            );
          })}
        </Stack>
        <Stack>
          <FormControlLabel
            control={
              <Switch onChange={handlePrivateToggle} checked={isPrivate} />
            }
            label={
              isPrivate !== calendar.is_private
                ? "Private (changed)"
                : "Private"
            }
            color={isPrivate !== calendar.is_private ? "error" : "default"}
          />
          <Typography variant="caption">
            Private calendars are not shown in search
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          sx={{ marginTop: 4, width: "100%" }}
        >
          <Button variant="contained" onClick={onSave}>
            Save
          </Button>
          <Button
            onClick={(e) => {
              if (onClose) {
                onClose(e, "escapeKeyDown");
              }
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
      <UsersPicker
        isOpen={showUserPickerModal}
        onSave={addEditors}
        currentSelectedUsers={calendar.editors.reduce(
          (acc, e) => {
            acc[e] = true;
            return acc;
          },
          {} as { [key: string]: boolean },
        )}
        onClose={() => setShowUserPickerModal(false)}
      />
      <Menu
        open={Boolean(actionsMenuEl)}
        anchorEl={actionsMenuEl}
        onClose={handleCloseUserMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: "45px" }}
        keepMounted
      >
        <MenuItem key="Delete" onClick={handleDeleteCalendar}>
          <Stack direction="row" gap={1}>
            <EventBusy />
            <Typography textAlign="center">Delete calendar</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </RouxModal>
  );
}
