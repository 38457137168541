import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { InsertLink } from "@mui/icons-material";
import { Button, Link, Stack, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import {
  RouxModal,
  TruncatedText,
  useAppDispatch,
  useAppSelector,
} from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { RecipeType, selectRecipeById } from "../recipes";
import { selectCurrentUser } from "../users";
import { removeRecipeFromDay, scheduleRecipeForDay } from "./calendarSlice";
import { ScheduledRecipe } from "./types";

import { format } from "date-fns";

interface Props {
  scheduledRecipe: ScheduledRecipe;
  recipeId: string;
  currentDate: Date;
  isOpen: boolean;
  onClose?: {
    bivarianceHack(event: {}, reason: "backdropClick" | "escapeKeyDown"): void;
  }["bivarianceHack"];
}

// TODO - allow switching calendars?
export function RescheduleRecipeModal({
  scheduledRecipe,
  recipeId,
  currentDate,
  isOpen,
  onClose,
}: Props) {
  const recipe = useAppSelector((state) => {
    return selectRecipeById(state, recipeId);
  });
  const formattedUrl = (recipe?.data?.url ?? "").replace("https://", "");
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();
  const supabase = useClient();
  const [value, setValue] = useState<Date | null>(currentDate);
  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
  };
  const navigateToNewRecipe = useCallback(
    (scheduledDate: Date) => {
      // When its worth doing, you can switch this to navigating to the
      // calendar day page.
      navigate(`/calendar`);
    },
    [navigate],
  );

  const handleReschedule = async () => {
    if (currentUser === null || value === undefined || value === null) return;

    await dispatch(
      removeRecipeFromDay({
        supabase,
        date: currentDate,
        scheduledRecipe,
        successCallback: () => {},
        errorCallback: console.log,
      }),
    );

    await dispatch(
      scheduleRecipeForDay({
        supabase,
        currentUser,
        calendarId: scheduledRecipe.calendar_id,
        date: value,
        recipe,
        callback: navigateToNewRecipe,
        successCallback: () => {},
        errorCallback: console.log,
      }),
    );
  };

  if (!recipe) return null;

  return (
    <RouxModal
      isOpen={isOpen}
      imageUrl={
        recipe.imageUrl ??
        "https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800"
      }
      imageUrlAlt={"An image of the recipe"}
      onClose={onClose}
    >
      <Stack gap={1}>
        <Typography variant="h5" component="div">
          {recipe.title}
        </Typography>
        <Stack direction="row" gap={1}>
          {recipe.type === RecipeType.Url && <InsertLink />}
          {recipe.type === RecipeType.Url && (
            <Stack direction="column">
              <Link
                href={recipe?.data?.url ?? "example.com"}
                target="_blank"
                onClick={(event) => event.stopPropagation()}
              >
                <TruncatedText text={formattedUrl} charLimit={34} />
              </Link>
            </Stack>
          )}
        </Stack>
        {recipe.description && (
          <TruncatedText text={recipe.description} wordLimit={25} />
        )}
        <Typography
          variant="h6"
          sx={{ marginTop: 1, marginBottom: 1 }}
        >{`Current scheduled date: ${format(
          currentDate,
          "MM/dd/yyyy",
        )}`}</Typography>
        <Stack gap={2} alignItems="center">
          <DesktopDatePicker
            label="Date"
            value={value}
            onChange={handleChange}
          />
          <Button variant="contained" onClick={handleReschedule}>
            Reschedule
          </Button>
        </Stack>
      </Stack>
    </RouxModal>
  );
}
