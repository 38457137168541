import { useNavigate } from "react-router-dom";

import { Skeleton, Stack, Typography } from "@mui/material";
import { ListItem } from "@mui/material";

import { useAppSelector } from "../../common";
import { FollowButton, UserAvatar, selectCurrentUser, useUser } from "../users";

export function FollowerListItem({
  userId,
  onClick,
}: {
  userId: string;
  onClick: () => void;
}) {
  const user = useUser({ userId });
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();

  if (!user || !currentUser) return <Skeleton width={380} height={40} />;

  return (
    <ListItem>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        sx={{ width: "100%" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          onClick={() => {
            onClick();
            navigate(`/profiles/${user.id}`);
          }}
        >
          <UserAvatar userId={userId} width={48} height={48} />
          <Typography variant="body1">{`@${user.username}`}</Typography>
        </Stack>
        <FollowButton userId={userId} onClick={onClick} />
      </Stack>
    </ListItem>
  );
}
