import { Divider, Skeleton, Stack, Typography } from "@mui/material";

import { rouxRedHex, useAppSelector } from "../../common";
import { PostCard } from "../posts";
import { selectPostsByCalendarsAndDate } from "../posts/postsSlice";
import { selectCurrentUser } from "../users";
import { CalendarRecipeCard } from "./CalendarRecipeCard";
import { CreateCalendarPostDrawer } from "./CreateCalendarPostDrawer";
import { selectScheduledRecipesByDate } from "./calendarSlice";

import { format, isToday } from "date-fns";

export function CalendarDayListItem({
  date,
  calendarIds,
  hideCalendar,
  hideCalendarControls,
}: {
  date: Date;
  calendarIds: string[];
  hideCalendar?: boolean;
  hideCalendarControls?: boolean;
}) {
  // https://date-fns.org/v2.29.3/docs/format
  const dayOfWeek = format(date, "iii");
  const dayOfMonth = format(date, "do");
  const month = format(date, "LLL");
  const currentUser = useAppSelector(selectCurrentUser);
  const scheduledRecipes = useAppSelector((state) => {
    return selectScheduledRecipesByDate(
      state,
      calendarIds,
      // Need the format to be 2023-10-20
      date.toISOString().substring(0, 10),
    );
  });
  const scheduledPosts = useAppSelector((state) => {
    return selectPostsByCalendarsAndDate(
      state,
      calendarIds,
      // Need the format to be 2023-10-20
      date.toISOString().substring(0, 10),
    );
  });
  const isTodayToday = isToday(date);

  const numberBadgeStyle = isTodayToday
    ? {
        borderColor: rouxRedHex,
        background: rouxRedHex,
        color: "white",
      }
    : {};

  if (!currentUser) {
    return <Skeleton width={380} height={380}></Skeleton>;
  }

  const recipes = scheduledRecipes.map((scheduledRecipe) => {
    return (
      <CalendarRecipeCard
        key={`${scheduledRecipe.recipe_id}-${scheduledRecipe.added_by}-${scheduledRecipe.calendar_id}-${scheduledRecipe.scheduled_date}`}
        recipeId={scheduledRecipe.recipe_id}
        date={date}
        addedById={scheduledRecipe.added_by}
        scheduledRecipe={scheduledRecipe}
        hideCalendar={hideCalendar}
        hideCalendarControls={hideCalendarControls}
      />
    );
  });

  const posts = scheduledPosts.map((post) => {
    return (
      <Stack key={post.id} sx={{ width: "342px" }}>
        <PostCard postId={post.id} />
      </Stack>
    );
  });

  return (
    <Stack
      gap={1}
      alignItems="center"
      flexWrap="wrap"
      sx={{
        marginTop: 2,
        minHeight: "64px",
        flex: "0 0 auto",
      }}
    >
      <Divider sx={{ width: "100%" }} />
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{ padding: 1, width: "100%" }}
      >
        <Typography
          sx={{
            border: 1,
            borderRadius: "100%",
            textAlign: "center",
            verticalAlign: "middle",
            height: 64,
            width: 64,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...numberBadgeStyle,
          }}
          variant="h5"
        >
          {dayOfMonth}
        </Typography>
        <Typography variant="h4">
          {month},&nbsp;{dayOfWeek}
        </Typography>
        <Stack direction="row" justifyContent="end" sx={{ flexGrow: 2 }}>
          <CreateCalendarPostDrawer date={date} />
        </Stack>
      </Stack>
      {recipes}
      {posts}
    </Stack>
  );
}
