import { useState } from "react";

import { TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { RouxModal } from "../../common";
import { SearchableSelectableRecipes } from "./SearchableSelectableRecipes";

export function RecipeSearchModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose?: {
    bivarianceHack(event: {}, reason: "backdropClick" | "escapeKeyDown"): void;
  }["bivarianceHack"];
}) {
  const [searchText, setSearchText] = useState("");

  return (
    <RouxModal isOpen={isOpen} onClose={onClose}>
      <Stack sx={{ width: 1 }}>
        <Stack direction="row" sx={{ width: 1 }} justifyContent="center">
          <Typography variant="h6" gutterBottom>
            Add recipe
          </Typography>
        </Stack>
        <TextField
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value);
          }}
        ></TextField>
        <SearchableSelectableRecipes searchText={searchText} />
      </Stack>
    </RouxModal>
  );
}
