import { Stack, Typography } from "@mui/material";

export function AboutText() {
  return (
    <>
      <Typography variant="h6" sx={{ padding: 1, textAlign: "center" }}>
        👨‍🍳 We're all cooking together 👨‍🍳
      </Typography>
      <Stack gap={1}>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Create a calendar & plan meals with friends.
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Get inspired by what others are cooking.
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Save and share recipes you find online.
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Create your own recipes (soon!).
        </Typography>
      </Stack>
    </>
  );
}
