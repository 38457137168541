import { useParams } from "react-router-dom";

import { Bookmark, Comment, OpenInNew } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  Rating,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { ExpandedPhoto, PageLayout } from "../common";
import { TopChrome } from "../features/chrome";
import { RecipeCommentsList } from "../features/comments";
import { RecipeRatingControl } from "../features/ratings";
import {
  RecipeBottomChrome,
  RecipePhotoUploadCarousel,
  recipeBottomChromeHeight,
  useRecipe,
  useRecipePhotoUploads,
} from "../features/recipes";

export function RecipePage() {
  const params = useParams();
  const recipeId = params?.id ?? "unknown";
  const recipe = useRecipe({ recipeId });
  const recipeImages = useRecipePhotoUploads({ recipeId });

  if (!recipe) {
    return <Skeleton />;
  }

  return (
    // TODO: Like ProfilePage, adding a top chrome for recipe editors.
    <PageLayout
      topChrome={<TopChrome />}
      bottomChrome={<RecipeBottomChrome recipeId={recipe.id} />}
      bottomChromeHeight={recipeBottomChromeHeight}
    >
      <Stack sx={{ height: 1 }} justifyContent={"space-between"}>
        <Stack sx={{ overflow: "scroll" }}>
          <Stack sx={{ paddingTop: 2 }} alignItems="center"></Stack>
          <Stack
            sx={{ paddingTop: 2 }}
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
              <Stack direction="row" gap={2} alignItems="center">
                <ExpandedPhoto
                  width="160px"
                  height="160px"
                  photoSrc={recipe.imageUrl ?? "/recipe-test-image.jpg"}
                />
                <Stack gap={1}>
                  {recipe.title && (
                    <Typography variant="h5" gutterBottom>
                      {recipe.title && recipe.title}
                    </Typography>
                  )}
                  <Stack direction="row" alignItems="center">
                    <Rating value={recipe.avgReviewScore} readOnly />
                    <Typography variant="body2">{`(${recipe.numReviews})`}</Typography>
                  </Stack>
                  <Stack direction="row" gap={1} alignItems="center">
                    {recipe?.data?.url && (
                      <img
                        height="24"
                        width="24"
                        src={`http://www.google.com/s2/favicons?domain=${recipe.data.url}&sz=256`}
                        alt="Logo"
                      />
                    )}
                    {recipe?.data?.site_name && (
                      <Typography variant="body1">
                        {recipe?.data?.site_name}
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Stack direction="row" alignItems="center">
                      <Comment />
                      <Typography variant="body2">
                        ({recipe.numComments})
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Bookmark />
                      <Typography variant="body2">
                        ({recipe.numSaves ?? 0})
                      </Typography>
                    </Stack>
                    {recipe?.data?.url && (
                      <IconButton href={recipe.data.url} target="_blank">
                        <OpenInNew />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
              </Stack>
              <Typography sx={{ paddingTop: 2, paddingBottom: 2 }}>
                {recipe.description}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <RecipeRatingControl recipeId={recipeId} />
          <RecipePhotoUploadCarousel uploads={recipeImages} />
          <RecipeCommentsList recipeId={recipeId} />
        </Stack>
      </Stack>
    </PageLayout>
  );
}
