import { useCallback, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import {
  fetchRecipePhotoUploads,
  fetchRecipes,
  selectRecipeById,
  selectRecipePhotoUploadsById,
} from "./recipesSlice";

export function useRecipePhotoUploads({ recipeId }: { recipeId: string }) {
  const photos = useAppSelector((state) => {
    return selectRecipePhotoUploadsById(state, recipeId);
  });

  const dispatch = useAppDispatch();
  const supabase = useClient();

  const fetchUploads = useCallback(async () => {
    if (!photos) {
      dispatch(
        fetchRecipePhotoUploads({
          recipeId,
          supabase,
          errorCallback: console.log,
          successCallback: () => {},
        }),
      );
    }
  }, [photos, recipeId, supabase, dispatch]);

  useMemo(async () => {
    if (!photos) {
      fetchUploads();
    }
  }, [fetchUploads, photos]);

  return photos;
}

export function useRecipe({ recipeId }: { recipeId: string }) {
  const recipe = useAppSelector((state) => {
    return selectRecipeById(state, recipeId);
  });

  const dispatch = useAppDispatch();
  const supabase = useClient();

  const fetchApiRecipe = useCallback(async () => {
    if (!recipe && recipeId !== "unknown") {
      dispatch(
        fetchRecipes({
          recipeIds: [recipeId],
          supabase,
          errorCallback: console.log,
          successCallback: () => {},
        }),
      );
    }
  }, [recipe, dispatch, supabase, recipeId]);

  useMemo(async () => {
    if (!recipe) {
      fetchApiRecipe();
    }
  }, [fetchApiRecipe, recipe]);

  return recipe;
}
