import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CalendarMonth, DynamicFeed, Search } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
} from "@mui/material";

import {
  rouxBottomChromeHeightPixels,
  rouxRedHex,
  useAppSelector,
} from "../../common";
import { UserAvatar, selectCurrentUser } from "../users";
import { CreateDrawer } from "./CreateDrawer";

export function BottomChrome() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const currentUser = useAppSelector(selectCurrentUser);
  const pathNamesToValue = useMemo(() => {
    return {
      feed: 0,
      search: 1,
      // this one is not actually a path, but is for acknowledging that
      // the add button takes up a value-based slot.
      add: 2,
      calendar: 3,
      profile: 4,
    };
  }, []);
  let initialValue = 3;
  if (path === "/feed") {
    initialValue = pathNamesToValue.feed;
  } else if (path === "/search") {
    initialValue = pathNamesToValue.search;
    // 2 is the add button
  } else if (path.includes("calendar")) {
    initialValue = pathNamesToValue.calendar;
  } else if (path === "/profile") {
    initialValue = pathNamesToValue.profile;
  }
  const [value, setValue] = useState<number>(initialValue);
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      if (!currentUser && newValue === pathNamesToValue.profile) return;
      setValue(newValue);
    },
    [currentUser, pathNamesToValue],
  );
  const handleNavigateToProfile = useCallback(() => {
    if (value === pathNamesToValue.profile) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (currentUser) {
      navigate("/profile");
    }
  }, [currentUser, navigate, pathNamesToValue, value]);

  return (
    <>
      <BottomNavigation
        // Used to enforce that the selected tab is white and not red.
        className="bottom-chrome"
        sx={{
          backgroundColor: rouxRedHex,
          height: rouxBottomChromeHeightPixels,
        }}
        value={value}
        onChange={handleChange}
      >
        <BottomNavigationAction
          label="Feed"
          icon={<DynamicFeed />}
          onClick={(event) => {
            event.stopPropagation();
            if (value === pathNamesToValue.feed) {
              window.scrollTo({ top: 0, behavior: "smooth" });
              return;
            }

            navigate("/feed");
          }}
          className={value === pathNamesToValue.feed ? "Mui-selected" : ""}
        />
        <BottomNavigationAction
          label="Search"
          icon={<Search />}
          onClick={(event) => {
            event.stopPropagation();
            navigate("/search");
          }}
          className={value === pathNamesToValue.search ? "Mui-selected" : ""}
        />
        <CreateDrawer />
        <BottomNavigationAction
          label="Calendar"
          icon={<CalendarMonth />}
          onClick={(event) => {
            navigate("/");
          }}
          className={value === pathNamesToValue.calendar ? "Mui-selected" : ""}
        />
        <BottomNavigationAction
          label="You"
          icon={
            currentUser ? (
              <UserAvatar userId={currentUser.id} width={36} height={36} />
            ) : (
              <CircularProgress color={"info"} />
            )
          }
          onClick={handleNavigateToProfile}
          className={value === pathNamesToValue.profile ? "Mui-selected" : ""}
        />
      </BottomNavigation>
    </>
  );
}
