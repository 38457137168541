import { useContext } from "react";

import { SupabaseClient } from "@supabase/supabase-js";

import { SupabaseContext } from "../context";

export function useClient(): SupabaseClient {
  const client = useContext<SupabaseClient | undefined>(SupabaseContext);
  if (client === undefined)
    throw Error("No client has been specified using Provider.");
  return client;
}
