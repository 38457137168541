import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Skeleton } from "@mui/material";

import { useClient } from "../../common/supabase/hooks/useClient";
import { PhotoMetadata } from "../photos";
import ExpandedUploadedPhoto from "../photos/ExpandedUploadedPhoto";
import { UserAvatar, useUser } from "../users";
import { RecipePhotoUpload } from "./types";

export function RecipePhotoUploadCarouselItem({
  upload,
}: {
  upload: RecipePhotoUpload;
}) {
  const user = useUser({ userId: upload.user_id });
  const supabase = useClient();
  const [imageUrl, setImageUrl] = useState<string>("");
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchImage() {
      const { data, error } = await supabase.storage
        .from("recipe_photos")
        .download(`${upload.recipe_id}/${upload.user_id}/${upload.id}`);

      if (error) {
        console.log(error.message);
        throw error;
      }

      setImageUrl(URL.createObjectURL(data));
    }

    fetchImage();
  }, [supabase, upload]);

  if (!user || imageUrl === "")
    return <Skeleton sx={{ height: "160px", width: "160px" }} />;

  const metadata = {
    bucket: "recipe_photos",
    path: `${upload.recipe_id}/${upload.user_id}`,
    id: upload.id,
    addedTs: upload.added_ts,
  } as PhotoMetadata;

  return (
    <ExpandedUploadedPhoto
      width="160px"
      height="160px"
      title={`@${user.username}`}
      onTitleClick={() => {
        navigate(`/profiles/${user.id}`);
      }}
      actionIcon={<UserAvatar userId={user.id} width={24} height={24} />}
      metadata={metadata}
    />
  );
}
