import { useCallback, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import {
  fetchRecipeComments,
  fetchUsersRecipeComments,
  selectCommentsForRecipeId,
  selectCommentsForUserId,
} from "./commentsSlice";

export function useRecipeComments({ recipeId }: { recipeId: string }) {
  const comments = useAppSelector((state) => {
    return selectCommentsForRecipeId(state, recipeId);
  });

  const dispatch = useAppDispatch();
  const supabase = useClient();

  const fetch = useCallback(async () => {
    if (!comments) {
      dispatch(
        fetchRecipeComments({
          supabase,
          recipeId,
          errorCallback: console.log,
          successCallback: () => {},
        }),
      );
    }
  }, [recipeId, dispatch, supabase, comments]);

  useMemo(async () => {
    if (!comments) {
      fetch();
    }
  }, [fetch, comments]);

  return Object.values(comments ?? {}) ?? [];
}

export function useUserRecipeComments({ userId }: { userId: string }) {
  const comments = useAppSelector((state) => {
    return selectCommentsForUserId(state, userId);
  });

  const dispatch = useAppDispatch();
  const supabase = useClient();

  const fetch = useCallback(async () => {
    if (!comments) {
      dispatch(
        fetchUsersRecipeComments({
          supabase,
          userId: userId,
          errorCallback: console.log,
          successCallback: () => {},
        }),
      );
    }
  }, [dispatch, supabase, comments, userId]);

  useMemo(async () => {
    if (!comments) {
      fetch();
    }
  }, [fetch, comments]);

  return Object.values(comments ?? {}) ?? [];
}
