import "./LockScreenPage.css";

import { Button, Paper, Stack } from "@mui/material";

import { rouxRedHex } from "../common";
import { useClient } from "../common/supabase/hooks/useClient";

export function LockScreenPage() {
  const supabase = useClient();

  const handleLogin = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${window.location.hostname}/profile`,
          scopes:
            "openid,https://www.googleapis.com/auth/userinfo.profile,https://www.googleapis.com/auth/userinfo.email",
        },
      });
      if (error) throw error;
    } catch (error) {
    } finally {
      // loading state done.
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundColor: rouxRedHex,
      }}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Paper elevation={3}>
          <Stack direction="column" className="lock-screen">
            <Stack className="lock-screen-controls" justifyContent="center">
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="lock-screen-btn"
                  color="inherit"
                  variant="contained"
                  onClick={handleLogin}
                >
                  Log In
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
}
