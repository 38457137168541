import { useEffect, useState } from "react";

import { Box, Stack, Typography } from "@mui/material";

import { getNextSunday } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { UserContributionsGraphTile } from "./UserContributionsGraphTile";
import { Contributions } from "./types";

import { addDays, format } from "date-fns";

export function UserContributionsGraph({ userId }: { userId?: string }) {
  const [contributions, setContributions] = useState<Contributions>({});
  const numWeeks = 34;
  const numDays = numWeeks * 7;
  const startDate = getNextSunday(Date.now());
  const supabase = useClient();

  useEffect(() => {
    async function fetchContributions() {
      const { data, error } = await supabase.functions.invoke(
        "contributions-get",
        {
          body: { userId },
        },
      );

      if (error) {
        console.log(error.message);
        throw error;
      }

      setContributions(data.contributions);
    }

    if (userId) {
      fetchContributions();
    }
  }, [supabase, userId]);

  const dayTiles = Array.from({ length: numDays }, (_, dayNum) => {
    const day = addDays(startDate, -dayNum);
    return (
      <UserContributionsGraphTile
        key={day.toISOString()}
        date={day}
        contributions={contributions}
      />
    );
  }).reverse();

  const weekStacks = Array.from({ length: numWeeks }, (_, weekNum) => {
    const dayStart = addDays(Date.now(), -(weekNum * 7));
    const daySlice = dayTiles.slice(weekNum * 7, weekNum * 7 + 7);
    return (
      <Stack
        key={`${dayStart.toLocaleDateString()}`}
        className={`${dayStart.toLocaleDateString()}`}
        gap={0.25}
      >
        {daySlice}
      </Stack>
    );
  });

  const monthLabels = Array.from({ length: 36 }, (_, weekNum) => {
    const dayStart = addDays(Date.now(), -(weekNum * 7));
    const weekBeforeStart = addDays(Date.now(), -(weekNum * 7 + 7));
    const showMonthLabel = dayStart.getMonth() !== weekBeforeStart.getMonth();
    const monthLabel = format(dayStart, "LLL");
    return (
      <Box key={weekNum} sx={{ height: 20, flexGrow: showMonthLabel ? 2 : 1 }}>
        {showMonthLabel ? (
          <Typography variant="caption">{monthLabel}</Typography>
        ) : (
          ""
        )}
      </Box>
    );
  }).reverse();

  return (
    <Stack sx={{ margin: 1 }} gap={0.5}>
      <Stack gap={0.25} direction="row">
        <Stack sx={{ width: "26px" }} justifyContent="space-between"></Stack>
        {monthLabels}
      </Stack>
      <Stack gap={0.25} direction="row">
        <Stack sx={{ width: "26px" }} justifyContent="space-between">
          <Typography variant="caption" sx={{ lineHeight: "1" }}>
            Sun
          </Typography>
          <Typography variant="caption" sx={{ lineHeight: "1" }}>
            Wed
          </Typography>
          <Typography variant="caption" sx={{ lineHeight: "1" }}>
            Sat
          </Typography>
        </Stack>
        {weekStacks}
      </Stack>
    </Stack>
  );
}
