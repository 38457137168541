import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../common/store";
import { Search } from "./types";

export interface SearchState {
  lastSearchText: string;
}

const initialState: SearchState = {
  lastSearchText: "",
};

export const search = createAsyncThunk(
  "search/search",
  async ({ text }: Search) => {
    return text;
  },
);

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(search.fulfilled, (state, action) => {
      state.lastSearchText = action.payload;
    });
  },
});

export const selectLastSearchText = (state: RootState) =>
  state.searchStore.lastSearchText;

export default searchSlice.reducer;
