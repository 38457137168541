import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Button, Stack, TextField, Typography } from "@mui/material";

import { rouxTopChromeHeightPixels, useAppDispatch } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { saveNewRecipeByUrl } from "./recipesSlice";
import { Recipe } from "./types";

export function AddRecipeForm({
  setIsSaving,
  onClose,
}: {
  setIsSaving: (isSaving: boolean) => void;
  onClose: () => void;
}) {
  const [value, setValue] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const supabase = useClient();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const navigateToNewRecipe = useCallback(
    (newRecipe: Recipe) => {
      setIsSaving(false);
      navigate(`/recipes/${newRecipe.id}`);
      onClose();
    },
    [navigate, onClose, setIsSaving],
  );

  const handleAddRecipe = useCallback(async () => {
    if (!value) return;

    setIsSaving(true);

    // Trim url params
    const index = value.indexOf("?");
    let url = value;
    if (index !== -1) {
      url = value.substring(0, index);
    }

    appDispatch(
      saveNewRecipeByUrl({
        supabase,
        recipeUrl: url,
        callback: navigateToNewRecipe,
        errorCallback: console.log,
        // unused.
        successCallback: () => {},
      }),
    );
  }, [value, appDispatch, supabase, navigateToNewRecipe, setIsSaving]);

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: `calc(100vh - ${rouxTopChromeHeightPixels})` }}
    >
      <Stack justifyContent={"center"} alignItems={"center"} gap={4}>
        <Typography variant="h3" gutterBottom>
          Add by URL
        </Typography>
        <Typography variant="caption" sx={{ textAlign: "center" }}>
          Just enter a URL below, and Roux will save the recipe. Recipes can
          only be added once.
        </Typography>
        {errorMessage && (
          <Alert
            severity="warning"
            onClose={() => {
              setErrorMessage(undefined);
            }}
          >
            {errorMessage}
          </Alert>
        )}
        <TextField
          placeholder="themessingfamily.com/recipes/..."
          onChange={(event) => setValue(event.target.value)}
          sx={{ width: "300px" }}
          value={value}
        />
        <Button variant="contained" onClick={handleAddRecipe}>
          Add
        </Button>
      </Stack>
    </Stack>
  );
}
