import { ChangeEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router";
import uuid from "react-uuid";

import { Edit } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Fab,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import {
  RouxModal,
  VisuallyHiddenInput,
  useAppDispatch,
  useAppSelector,
} from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { fetchUser, selectCurrentUser } from "../users";

interface Props {
  isOpen: boolean;
  onClose?: {
    bivarianceHack(event: {}, reason: "backdropClick" | "escapeKeyDown"): void;
  }["bivarianceHack"];
}

export function AddCalendarModal({ isOpen, onClose }: Props) {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);

  const handlePrivateToggle = useCallback(() => {
    setIsPrivate(!isPrivate);
  }, [isPrivate]);

  const onCloseInternal = useCallback(() => {
    setTitle("");
    setDescription("");
    onClose && onClose({}, "backdropClick");
  }, [onClose]);

  const supabase = useClient();
  const handleImageSelection = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;
      const image = event.target.files[0];
      setSelectedImage(image);
      setSelectedImageUrl(URL.createObjectURL(image));
    },
    [],
  );

  const handleAddCalendar = useCallback(async () => {
    if (!currentUser) return;
    let imageUrl = null;
    if (selectedImage) {
      imageUrl = `${uuid()}/${selectedImage.name}`;
      const { error } = await supabase.storage
        .from("calendar_photos")
        .upload(imageUrl, selectedImage);
      // Upload to the bucket.
      if (error) {
        throw error;
      }
    }

    const calendarId = uuid();
    const calendar = {
      id: calendarId,
      is_private: isPrivate,
      description: description,
      title: title,
      calendar_photo_url_fragment: imageUrl,
      user_id: currentUser.id,
    };
    const { error } = await supabase.from("calendars").insert(calendar);
    if (error) throw error;

    const calendarEditor = {
      calendar_id: calendarId,
      user_id: currentUser.id,
    };

    const { error: error1 } = await supabase
      .from("calendar_editors")
      .insert(calendarEditor);
    if (error1) throw error1;

    // Refetch user so that we have updated calendar.
    await dispatch(fetchUser({ userId: currentUser.id, supabase }));

    navigate(`/calendars/${calendarId}`);
  }, [
    dispatch,
    navigate,
    isPrivate,
    selectedImage,
    supabase,
    currentUser,
    title,
    description,
  ]);

  return (
    <RouxModal isOpen={isOpen} onClose={onCloseInternal}>
      <Stack gap={1} justifyContent={"center"} alignItems={"center"}>
        <Typography variant="h5">Create Calendar</Typography>
        <Typography sx={{ textAlign: "center" }}>
          Create a calendar and invite other editors to make a plan. Or invite
          friends to follow your calendar so they always know what's cooking!
        </Typography>
        <Stack
          direction="row"
          gap={1}
          justifyContent="center"
          alignItems="end"
          sx={{ width: "100%" }}
        >
          <Avatar
            alt="Logo"
            src={selectedImageUrl || ""}
            sx={{
              height: "160px",
              width: "160px",
              position: "relative",
            }}
          />
          <Fab component="label">
            <Edit />
            <VisuallyHiddenInput
              onChange={handleImageSelection}
              type="file"
              accept="image/*"
            />
          </Fab>
        </Stack>
        <TextField
          placeholder="Title"
          onChange={(event) => setTitle(event.target.value)}
          sx={{ width: "300px" }}
          value={title}
        />
        <TextField
          placeholder="Description"
          onChange={(event) => setDescription(event.target.value)}
          sx={{ width: "300px" }}
          value={description}
        />
        <Stack>
          <FormControlLabel
            control={
              <Switch onChange={handlePrivateToggle} checked={isPrivate} />
            }
            label="Private"
          />
          <Typography variant="caption">
            Private calendars are not shown in search
          </Typography>
        </Stack>
        <Button variant="contained" onClick={handleAddCalendar}>
          Create
        </Button>
      </Stack>
    </RouxModal>
  );
}
