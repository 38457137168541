import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../common/store";
import { AuthedApiThunkArgs } from "../../common/types";
import { Rating } from "./types";

export interface RatingState {
  currentUsersRatings: { [key: string]: Rating };
}

const initialState: RatingState = {
  currentUsersRatings: {},
};

export const fetchRatingForRecipe = createAsyncThunk(
  "ratings/fetchRatingForRecipe",
  async ({
    recipeId,
    currentUser,
    supabase,
    errorCallback,
  }: { recipeId: string } & AuthedApiThunkArgs) => {
    // See if this user has rated this recipe.
    const { data, error } = await supabase
      .from("recipe_ratings")
      .select("rating")
      .eq("user_id", currentUser.id)
      .eq("recipe_id", recipeId);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return {
      score: data.length ? (data[0].rating as number) : null,
    } as Rating;
  },
);

export const setRatingForRecipe = createAsyncThunk(
  "ratings/setRatingsForRecipe",
  async ({
    errorCallback,
    recipeId,
    rating,
    supabase,
    currentUser,
  }: { recipeId: string; rating: Rating } & AuthedApiThunkArgs) => {
    if (rating.score) {
      const { error } = await supabase
        .from("recipe_ratings")
        .upsert({
          user_id: currentUser.id,
          recipe_id: recipeId,
          added_ts: new Date().toISOString(),
          rating: rating.score,
        })
        .select();

      if (error) {
        errorCallback(error.message);
        throw error;
      }
    }

    return rating;
  },
);

export const ratingsSlice = createSlice({
  name: "ratings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRatingForRecipe.fulfilled, (state, action) => {
        state.currentUsersRatings[action.meta.arg.recipeId] = action.payload;
      })
      .addCase(setRatingForRecipe.fulfilled, (state, action) => {
        state.currentUsersRatings[action.meta.arg.recipeId] = action.payload;
      });
  },
});

export const selectRatingForRecipe = (state: RootState, recipeId: string) =>
  state.ratingStore.currentUsersRatings[recipeId];

export default ratingsSlice.reducer;
