import "../chrome/Chrome.css";

import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";

import { rouxTopChromeHeightPixels, useAppSelector } from "../../common";
import { selectCurrentUser } from "../users";
import { PreferencesDrawerMenu } from "./PreferencesDrawerMenu";

export function ProfileTopChrome() {
  const currentUser = useAppSelector(selectCurrentUser);
  return (
    <Box className="top-chrome" sx={{ height: rouxTopChromeHeightPixels }}>
      <AppBar position="static" sx={{ height: "100%" }}>
        <Toolbar sx={{ height: "100%", minHeight: rouxTopChromeHeightPixels }}>
          {currentUser && (
            <>
              <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
                <Typography variant="h6">{`@${currentUser.username}`}</Typography>
              </Stack>
              <PreferencesDrawerMenu currentUser={currentUser} />
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
