import { useCallback, useState } from "react";

import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import {
  RouxModal,
  rouxRedHex,
  useAppDispatch,
  useAppSelector,
} from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { AboutText } from "../about";
import { selectCurrentUser, updateCurrentUsersProfile } from "../users";

export const NuxModal = ({ isOpen }: { isOpen: boolean }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const [username, setUsername] = useState("");
  const [isUsernameLoading, setIsUsernameLoading] = useState(false);
  const [isUsernameInvalid, setIsUsernameInvalid] = useState(false);
  const [isUsernameTooShort, setIsUsernameTooShort] = useState(false);

  const supabase = useClient();
  const dispatch = useAppDispatch();

  const checkUsername = useCallback(
    async ({ username }: { username: string | null }) => {
      if (!currentUser) return;

      setUsername(username ?? "");

      if (!username) return;

      setIsUsernameLoading(true);

      if (username.length < 3) {
        setIsUsernameLoading(false);
        setIsUsernameTooShort(true);
        return;
      }

      setIsUsernameTooShort(false);

      const { data, error } = await supabase
        .from("profiles")
        .select("username")
        .eq("username", username);

      if (error) alert(error.message);

      if (data && data.length > 0 && username !== currentUser.username) {
        setIsUsernameInvalid(true);
      } else if (data) {
        setIsUsernameInvalid(false);
      }

      setIsUsernameLoading(false);
    },
    [supabase, currentUser],
  );

  const handleSave = useCallback(() => {
    if (currentUser === null) {
      return;
    }

    if (isUsernameInvalid) {
      alert("Username already in use");
      return;
    }

    if (isUsernameTooShort) {
      alert("Must pick username with >= 3 characters");
      return;
    }

    dispatch(
      updateCurrentUsersProfile({
        currentUser,
        supabase,
        successCallback: () => {},
        errorCallback: console.log,
        newProfile: {
          username: username ?? currentUser.username ?? "",
          avatar_url: currentUser.avatar_url ?? "",
          full_name: currentUser.full_name ?? "",
          bio: currentUser.bio ?? "",
        },
      }),
    );
  }, [
    dispatch,
    currentUser,
    username,
    supabase,
    isUsernameInvalid,
    isUsernameTooShort,
  ]);

  return (
    <RouxModal isOpen={isOpen}>
      <Typography variant="h3" color={rouxRedHex}>
        Roux
      </Typography>
      <Avatar
        alt="Roux Logo"
        src="/logo512.png"
        sx={{ width: 180, height: 180, marginBottom: 1, marginTop: 1 }}
      />
      <AboutText />
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          sx={{ width: 1, paddingLeft: 2, paddingRight: 2 }}
        >
          <TextField
            placeholder="@username"
            value={username}
            onChange={(event) => {
              checkUsername({ username: event.target.value });
            }}
            sx={{ flexGrow: 1 }}
            inputProps={{ style: { textTransform: "lowercase" } }}
          />
        </Stack>
        <Stack
          sx={{ padding: 1, width: 1 }}
          alignItems="center"
          justifyContent="center"
        >
          {isUsernameLoading && <CircularProgress />}
          {isUsernameInvalid && <Alert severity="error">Used</Alert>}
          {isUsernameTooShort && <Alert severity="error">Too short</Alert>}
        </Stack>
        <Typography variant="caption" sx={{ padding: 1, textAlign: "center" }}>
          Pick a unique username to begin!
        </Typography>
        <Button
          disabled={isUsernameInvalid || isUsernameTooShort}
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>
      </Stack>
    </RouxModal>
  );
};
