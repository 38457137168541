import React from "react";

import { Skeleton } from "@mui/material";

import { ExpandedPhoto } from "../../common";
import { usePhoto } from "./hooks";
import { PhotoMetadata } from "./types";

const ExpandedUploadedPhoto = ({
  metadata,
  actionIcon,
  onTitleClick,
  dontExpand,
  title,
  width,
  height,
  handlers,
}: {
  metadata: PhotoMetadata;
  actionIcon?: React.ReactNode;
  width?: string;
  height?: string;
  dontExpand?: boolean;
  onTitleClick?: () => void;
  title?: React.ReactNode;
  handlers?: {
    onSwipedRight?: () => void;
    onSwipedLeft?: () => void;
  };
}) => {
  const photo = usePhoto({ metadata });
  if (!photo) return <Skeleton />;

  return (
    <ExpandedPhoto
      actionIcon={actionIcon}
      onTitleClick={onTitleClick}
      title={title}
      photoSrc={photo.url}
      dontExpand={dontExpand}
      width={width}
      height={height}
      handlers={handlers}
    />
  );
};

// export default ExpandedUploadedPhoto;
export default React.memo(ExpandedUploadedPhoto);
