import { useCallback, useState } from "react";

import { Cancel } from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import {
  TruncatedText,
  rouxRedHex,
  rouxTopChromeHeightPixels,
} from "../../common";
import { UsersCalendarList } from "../calendar";
import { FollowersList } from "./FollowersList";
import { useUser } from "./hooks";

const buttonStyle = {
  textTransform: "none",
  color: "darkslategray",
};

export function UserStats({ userId }: { userId: string }) {
  const user = useUser({ userId });
  const [showFollowers, setShowFollowers] = useState(false);
  const [showFollowed, setShowFollowed] = useState(false);
  const [showCalendars, setShowCalendars] = useState(false);
  const calendars = Object.values(user?.calendars ?? {});

  const handleFollowersClick = useCallback(() => setShowFollowers(true), []);
  const handleFollowedClick = useCallback(() => setShowFollowed(true), []);
  const handleCalendarsClick = useCallback(() => setShowCalendars(true), []);

  const handleCloseFollowers = useCallback(() => {
    setShowFollowers(false);
  }, []);
  const handleCloseFollowed = useCallback(() => {
    setShowFollowed(false);
  }, []);
  const handleCloseCalendars = useCallback(() => {
    setShowCalendars(false);
  }, []);

  if (!user) return null;

  return (
    <>
      <Button sx={buttonStyle} onClick={handleCalendarsClick}>
        <Stack alignItems="center">
          <Typography variant="h6">{calendars.length}</Typography>
          <Typography variant="caption">Calendars</Typography>
        </Stack>
      </Button>
      <Button sx={buttonStyle} onClick={handleFollowedClick}>
        <Stack alignItems="center">
          <Typography variant="h6">
            {Object.values(user.followedUsers).length}
          </Typography>
          <Typography variant="caption">Follows</Typography>
        </Stack>
      </Button>
      <Button sx={buttonStyle} onClick={handleFollowersClick}>
        <Stack alignItems="center">
          <Typography variant="h6">
            {Object.values(user.followers).length}
          </Typography>
          <Typography variant="caption">Followers</Typography>
        </Stack>
      </Button>
      <Drawer
        anchor={"bottom"}
        open={showFollowers}
        onClose={handleCloseFollowers}
      >
        <FollowersList onClick={handleCloseFollowers} userId={userId} />
      </Drawer>
      <Drawer
        anchor={"bottom"}
        open={showFollowed}
        onClose={handleCloseFollowed}
      >
        <FollowersList
          onClick={handleCloseFollowed}
          userId={userId}
          showFollowed
        />
      </Drawer>
      <Drawer
        anchor={"bottom"}
        open={showCalendars}
        onClose={handleCloseCalendars}
      >
        <Box
          role="presentation"
          sx={{ width: "100%", height: "700px", overflow: "hidden" }}
        >
          <Stack alignItems="center" sx={{ width: 1, height: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              sx={{
                backgroundColor: rouxRedHex,
                height: rouxTopChromeHeightPixels,
                width: "100%",
                padding: 1,
              }}
            >
              <TruncatedText
                text={`${calendars.length} calendar${
                  calendars.length > 1 ? "s" : ""
                }`}
                maxLines={1}
                color="white"
                variant="h6"
              ></TruncatedText>
              <IconButton onClick={handleCloseCalendars}>
                <Cancel />
              </IconButton>
            </Stack>
            <UsersCalendarList userId={userId} />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
