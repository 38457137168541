import { useCallback, useState } from "react";

import { PostAdd } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Drawer,
  IconButton,
  Stack,
} from "@mui/material";

import { AddNewPostForm } from "../posts";
import { AddRecipeForm } from "../recipes";
import { DrawerTopChrome } from "./DrawerTopChrome";

export enum CreateType {
  Post = "Post",
  Recipe = "Recipe",
}

export function CreateDrawer() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [createType, setCreateType] = useState<CreateType>(CreateType.Post);
  const [isSaving, setIsSaving] = useState(false);

  const handleClose = useCallback(() => {
    setShowDrawer(false);
  }, []);
  const handleOpen = useCallback(() => {
    setShowDrawer(true);
  }, []);
  const setRecipe = useCallback(() => {
    setCreateType(CreateType.Recipe);
  }, []);
  const setPost = useCallback(() => {
    setCreateType(CreateType.Post);
  }, []);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <PostAdd />
      </IconButton>
      <Drawer anchor="bottom" open={showDrawer} onClose={handleClose}>
        <Box role="presentation" sx={{ width: "100%", minHeight: "60vh" }}>
          <Stack alignItems="center">
            <DrawerTopChrome title="New Post" onClose={handleClose} />
            <Stack
              sx={{
                position: "fixed",
                bottom: "12px",
                backgroundColor: "white",
              }}
            >
              <ButtonGroup sx={{}}>
                <Button
                  variant={
                    createType === CreateType.Post ? "contained" : "outlined"
                  }
                  color="info"
                  sx={{
                    borderTopLeftRadius: "24px",
                    borderBottomLeftRadius: "24px",
                  }}
                  onClick={setPost}
                >
                  Post
                </Button>
                <Button
                  variant={
                    createType === CreateType.Recipe ? "contained" : "outlined"
                  }
                  color="info"
                  sx={{
                    borderTopRightRadius: "24px",
                    borderBottomRightRadius: "24px",
                  }}
                  onClick={setRecipe}
                >
                  Recipe
                </Button>
              </ButtonGroup>
            </Stack>
            {isSaving && <CircularProgress />}
            {!isSaving && createType === CreateType.Post && (
              <AddNewPostForm setIsSaving={setIsSaving} onClose={handleClose} />
            )}
            {!isSaving && createType === CreateType.Recipe && (
              <AddRecipeForm setIsSaving={setIsSaving} onClose={handleClose} />
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
