import "../../common/Common.css";

import { useRef } from "react";
import { ViewportList } from "react-viewport-list";

import { Box, Stack, Typography } from "@mui/material";

import { UserCommentsListItem } from "./UserCommentsListItem";
import { useUserRecipeComments } from "./hooks";

export function UserCommentsList({ userId }: { userId: string }) {
  const comments = useUserRecipeComments({ userId });
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <>
      {comments.length === 0 && (
        <Stack>
          <Typography variant="h6">No comments yet!</Typography>
        </Stack>
      )}
      {comments.length > 0 && (
        <Box className="scroll-container" ref={ref} sx={{ height: "652px" }}>
          <ViewportList viewportRef={ref} items={comments}>
            {(comment) => {
              return (
                <UserCommentsListItem key={comment.id} comment={comment} />
              );
            }}
          </ViewportList>
        </Box>
      )}
    </>
  );
}
