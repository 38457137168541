import { useCallback, useState } from "react";

import { Alert, Box, Button, List, Skeleton, Stack } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { FollowerListItem } from "./FollowerListItem";
import { useUser } from "./hooks";
import { followUser, selectCurrentUser } from "./usersSlice";

export function FollowersList({
  userId,
  onClick,
  showFollowed,
}: {
  userId: string;
  onClick: () => void;
  showFollowed?: boolean;
}) {
  const user = useUser({ userId });
  const currentUser = useAppSelector(selectCurrentUser);
  const isCurrentUser = user?.id === currentUser?.id;
  const dispatch = useAppDispatch();
  const supabase = useClient();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleApiError = useCallback((error: string) => {
    setErrorMessage(error);
  }, []);

  const handleFollowUser = () => {
    if (!currentUser || !user) return;
    dispatch(
      followUser({
        errorCallback: handleApiError,
        successCallback: () => {},
        supabase,
        currentUser,
        userToFollow: user,
      }),
    );
  };

  const followedOrFollowers = showFollowed
    ? Object.values(user?.followedUsers ?? {})
    : Object.values(user?.followers ?? {});
  const users = followedOrFollowers.map((followerId) => {
    return (
      <FollowerListItem
        onClick={onClick}
        key={followerId}
        userId={followerId}
      />
    );
  });

  if (!user) return <Skeleton width={380} height={200} />;

  if (users.length === 0) {
    return (
      <Box sx={{ width: 380, height: 400 }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: "100%" }}
          gap={2}
        >
          <img src="/2_logo192.png" alt="logo" />
          <Stack direction="row" alignItems="center" justifyContent="center">
            {`No ${showFollowed ? "followed users" : "followers"} yet!`}
          </Stack>
          {!isCurrentUser && (
            <Button variant="contained" onClick={handleFollowUser}>
              Follow
            </Button>
          )}
          {errorMessage && (
            <Alert
              severity="warning"
              onClose={() => {
                setErrorMessage(undefined);
              }}
            >
              {errorMessage}
            </Alert>
          )}
        </Stack>
      </Box>
    );
  }

  return <List sx={{ height: 400 }}>{users}</List>;
}
