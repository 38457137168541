import "./Chrome.css";

import { useContext } from "react";

import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";

import { EnvironmentContext, rouxTopChromeHeightPixels } from "../../common";
import { AboutAvatar } from "../about";

export function TopChrome() {
  const { is_prod } = useContext(EnvironmentContext);

  return (
    <Box className="top-chrome">
      <AppBar position="sticky" sx={{ height: rouxTopChromeHeightPixels }}>
        <Toolbar sx={{ minHeight: rouxTopChromeHeightPixels }}>
          <AboutAvatar />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            &nbsp;Roux
          </Typography>
          <Stack direction="row">
            {!is_prod && (
              <>
                <div className="dev-banner">DEV</div>
              </>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
