import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { getCorrectDate, useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { DrawerTopChrome } from "../chrome";
import { Post } from "../posts";
import { editPost } from "../posts/postsSlice";
import { SelectRecipesButton, SelectableRecipesList } from "../recipes";
import { SelectableRecipeContextProvider } from "../recipes/context";
import { selectCurrentUser } from "../users";
import { CalendarAvatar } from "./CalendarAvatar";

export function EditCalendarPostDrawer({
  post,
  open,
  onClose,
}: {
  post: Post;
  open: boolean;
  onClose: () => void;
}) {
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const supabase = useClient();
  const [isSaving, setIsSaving] = useState(false);
  const [content, setContent] = useState<string>(post.contents ?? "");
  const initialDate =
    post && post.scheduledTs ? getCorrectDate(post.scheduledTs) : null;
  const [dateValue, setDateValue] = useState<Date | null>(initialDate);
  const [recipeIds, setRecipeIds] = useState<string[]>(post.recipeIds ?? []);
  const [calendarValue, setCalendarValue] = useState<string | null>(
    post?.calendarId ?? null,
  );

  const handleDateChange = useCallback(
    (newValue: Date | null) => {
      setDateValue(newValue);
    },
    [setDateValue],
  );

  const onRecipeSelected = useCallback(
    async (recipeId: string) => {
      setRecipeIds([...recipeIds, recipeId]);
    },
    [recipeIds],
  );

  const onRecipeDeselected = useCallback(
    async (recipeId: string) => {
      setRecipeIds(recipeIds.filter((id) => id !== recipeId));
    },
    [recipeIds],
  );

  const handleCalendarChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setCalendarValue(event.target.value ?? null);
    },
    [setCalendarValue],
  );

  const handleSave = useCallback(async () => {
    if (!currentUser || !post || !dateValue || !initialDate) return;
    setIsSaving(true);

    const editedPost = {
      contents: content,
      calendar_id: calendarValue,
      updated_ts: new Date().toISOString(),
      data: {
        scheduled_date: dateValue.toISOString().substring(0, 10),
      },
    };

    await dispatch(
      editPost({
        supabase,
        post: editedPost,
        originalPost: post,
        scheduledDate: dateValue,
        originalScheduledDate: initialDate,
        postId: post.id,
        images: [],
        recipeIds: recipeIds,
        errorCallback: console.log,
        successCallback: () => {},
      }),
    );

    setIsSaving(false);
    onClose();
    navigate("/");
  }, [
    navigate,
    dispatch,
    onClose,
    supabase,
    content,
    calendarValue,
    post,
    currentUser,
    initialDate,
    dateValue,
    recipeIds,
  ]);

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box role="presentation" sx={{ width: "100%", minHeight: "60vh" }}>
        {isSaving && <CircularProgress />}
        {!isSaving && (
          <Stack alignItems="center" sx={{ height: `100vh`, width: 1 }} gap={2}>
            <DrawerTopChrome title="Edit calendar entry" onClose={onClose} />
            <Stack
              alignItems="center"
              sx={{ height: `75vh`, width: `100vw`, paddingTop: 12 }}
              gap={2}
            >
              <DesktopDatePicker
                label="Date"
                value={dateValue}
                onChange={handleDateChange}
              />
              <Select
                value={calendarValue ?? undefined}
                onChange={handleCalendarChange}
              >
                {Object.values(currentUser?.calendars ?? {}).map((c) => {
                  return (
                    <MenuItem key={c.id} value={c.id}>
                      <CalendarAvatar
                        calendarId={c.id}
                        width={24}
                        height={24}
                        showName
                        disallowNavigation
                      />
                    </MenuItem>
                  );
                })}
              </Select>
              <TextField
                placeholder="Ideas, plans, &c."
                value={content}
                onChange={(event) => setContent(event.target.value)}
                rows={6}
                sx={{ width: 0.8 }}
                multiline
              />
              <SelectableRecipeContextProvider
                value={{
                  handleSelectRecipeId: onRecipeSelected,
                  handleDeselectRecipeId: onRecipeDeselected,
                }}
              >
                <SelectRecipesButton />
                <Stack sx={{ height: "300px", width: 0.8 }}>
                  <SelectableRecipesList recipeIds={recipeIds} />
                </Stack>
              </SelectableRecipeContextProvider>
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{ width: 32 }}
              >
                <Typography variant="caption">Post</Typography>
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </Drawer>
  );
}
