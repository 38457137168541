import { useCallback, useState } from "react";

import { Box, Button, Drawer, Stack, Typography } from "@mui/material";

import { useServiceWorker } from "./hooks";

export function NewVersionNotificationDrawer() {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [showDrawer, setShowDrawer] = useState<boolean>(
    !!(showReload && waitingWorker),
  );
  const handleClose = useCallback(() => {
    setShowDrawer(false);
  }, []);
  const updateApp = useCallback(() => {
    reloadPage();
  }, [reloadPage]);
  return (
    <Drawer anchor="bottom" open={showDrawer} onClose={handleClose}>
      <Box
        role="presentation"
        sx={{ width: 1, height: "300px", overflow: "hidden" }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: 1 }}
          gap={2}
        >
          <Typography variant="h5">
            A new version of Roux is available!
          </Typography>
          <Stack direction="row" gap={1} alignItems="center">
            <Button onClick={updateApp}>Update</Button>
            <Button onClick={handleClose}>Not now</Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
}
