import "../../common/Common.css";

import { useCallback, useState } from "react";
import uuid from "react-uuid";

import { AddComment, Cancel } from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import {
  TruncatedText,
  rouxRedHex,
  rouxTopChromeHeightPixels,
  useAppDispatch,
  useAppSelector,
} from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { upsertRecipe, useRecipe } from "../recipes";
import { selectCurrentUser } from "../users";
import { saveNewRecipeComment } from "./commentsSlice";

export function AddRecipeCommentDrawerMenu({ recipeId }: { recipeId: string }) {
  const recipe = useRecipe({ recipeId });
  const currentUser = useAppSelector(selectCurrentUser);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const dispatch = useAppDispatch();
  const supabase = useClient();

  const handleClose = useCallback(() => {
    setComment("");
    setShowDrawer(false);
  }, []);

  const handleSave = useCallback(() => {
    if (!currentUser || !recipe) return;
    dispatch(
      saveNewRecipeComment({
        supabase,
        errorCallback: console.log,
        successCallback: () => {},
        comment: {
          id: uuid(),
          recipe_id: recipeId,
          user_id: currentUser.id,
          added_ts: new Date().toISOString(),
          comment: comment,
        },
      }),
    );

    dispatch(
      upsertRecipe({
        recipe: {
          ...recipe,
          numComments: recipe.numComments + 1,
        },
      }),
    );

    setComment("");
    setShowDrawer(false);
  }, [currentUser, recipe, supabase, recipeId, comment, dispatch]);

  if (!recipe) {
    return null;
  }

  return (
    <>
      <IconButton color="info" onClick={() => setShowDrawer(true)}>
        <AddComment />
      </IconButton>
      <Drawer anchor="bottom" open={showDrawer} onClose={handleClose}>
        <Box
          role="presentation"
          sx={{ width: "100%", height: "700px", overflow: "hidden" }}
        >
          <Stack alignItems="center" sx={{ width: 1, height: 1 }} gap={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              sx={{
                backgroundColor: rouxRedHex,
                height: rouxTopChromeHeightPixels,
                width: "100%",
                padding: 1,
              }}
            >
              <TruncatedText
                text={`${recipe.numComments} comments `}
                maxLines={1}
                color="white"
                variant="h6"
              ></TruncatedText>
              <IconButton onClick={handleClose}>
                <Cancel />
              </IconButton>
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: 1,
                paddingLeft: 2,
                paddingRight: 2,
                paddingBottom: 2,
              }}
              alignItems="start"
              justifyContent="space-around"
              gap={1}
            >
              <TextField
                placeholder="comment"
                value={comment}
                multiline
                onChange={(event) => setComment(event.target.value)}
                sx={{ flexGrow: 1, width: 0.75 }}
              />
              <Button
                sx={{ marginTop: "12px" }}
                onClick={handleSave}
                variant="contained"
              >
                <Typography variant="caption" color="white">
                  Post
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
