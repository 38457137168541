import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Session, SupabaseClient, User } from "@supabase/supabase-js";

import { RootState } from "../../common/store";

export interface AuthState {
  currentSession: Session | null;
  user: User | null;
}

const initialState: AuthState = {
  currentSession: null,
  user: null,
};

export const initialize = createAsyncThunk(
  "auth/initialize",
  async ({ supabase }: { supabase: SupabaseClient }) => {
    const sessionData = await supabase.auth.getSession();
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_OUT") {
        window.location.href =
          process.env.REACT_APP_HOSTNAME ?? "http://localhost:3000";
      }
      setSession(session);
    });
    if (!sessionData.data.session) return null;
    return sessionData.data.session;
  },
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetSession: (state) => {
      state.currentSession = null;
      state.user = null;
    },
    setSession: (state, action) => {
      state.currentSession = action.payload;
      state.user = state.currentSession?.user ?? null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, () => {})
      .addCase(initialize.fulfilled, (state, action) => {
        state.currentSession = action.payload;
        state.user = state.currentSession?.user ?? null;
      })
      .addCase(initialize.rejected, () => {});
  },
});

export const selectCurrentSession = (state: RootState) =>
  state.authStore.currentSession;

export const selectCurrentUser = (state: RootState) => state.authStore.user;

export const { setSession, resetSession } = authSlice.actions;

export default authSlice.reducer;
