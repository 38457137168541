import { ChangeEvent } from "react";

import { AddAPhoto } from "@mui/icons-material";
import { Card, IconButton, Stack } from "@mui/material";

import { VisuallyHiddenInput } from "../../common";

export function AddPhotosButton({
  handleAddImages,
}: {
  handleAddImages: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <Card sx={{ width: "64px", height: "64px" }}>
      <Stack alignItems="center" sx={{ height: 1, width: 1 }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
          sx={{ height: "64px", width: "64px" }}
        >
          <IconButton component="label" sx={{ height: "64px", width: "64px" }}>
            <AddAPhoto />
            <VisuallyHiddenInput
              onChange={handleAddImages}
              type="file"
              accept="image/*"
              multiple
            />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
}
