import { useCallback, useState } from "react";

import {
  Box,
  Button,
  Drawer,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { DrawerTopChrome } from "../chrome";
import { RecipeList } from "../recipes";
import { CalendarContributorsList } from "./CalendarContributorsList";
import { useCalendar } from "./hooks";

const buttonStyle = {
  textTransform: "none",
  color: "darkslategray",
};

export function CalendarStats({ calendarId }: { calendarId: string }) {
  const calendar = useCalendar({ calendarId });
  const [showRecipes, setShowRecipes] = useState(false);
  const [showFollowers, setShowFollowers] = useState(false);
  const [showContributors, setShowContributors] = useState(false);

  const handleRecipesClick = useCallback(() => setShowRecipes(true), []);
  const handleFollowersClick = useCallback(() => setShowFollowers(true), []);
  const handleContributorsClick = useCallback(
    () => setShowContributors(true),
    [],
  );

  const handleCloseRecipes = useCallback(() => {
    setShowRecipes(false);
  }, []);
  const handleCloseFollowers = useCallback(() => {
    setShowFollowers(false);
  }, []);
  const handleCloseContributors = useCallback(() => {
    setShowContributors(false);
  }, []);

  if (!calendar) return <Skeleton />;
  return (
    <>
      <Stack direction="row" gap={2}>
        <Button sx={buttonStyle} onClick={handleRecipesClick}>
          <Stack alignItems="center" justifyContent="center">
            <Typography variant="h6">{calendar.recipes.length ?? 0}</Typography>
            <Typography variant="caption">Recipes</Typography>
          </Stack>
        </Button>

        <Button sx={buttonStyle} onClick={handleFollowersClick}>
          <Stack alignItems="center" justifyContent="center">
            <Typography variant="h6">
              {calendar.followers.length ?? 0}
            </Typography>
            <Typography variant="caption">Followers</Typography>
          </Stack>
        </Button>

        <Button sx={buttonStyle} onClick={handleContributorsClick}>
          <Stack alignItems="center" justifyContent="center">
            <Typography variant="h6">{calendar.editors.length ?? 0}</Typography>
            <Typography variant="caption">Contributors</Typography>
          </Stack>
        </Button>
      </Stack>
      <Drawer anchor={"bottom"} open={showRecipes} onClose={handleCloseRecipes}>
        <Box
          role="presentation"
          sx={{ width: "100%", height: "calc(100vh - 260px)" }}
        >
          <Stack alignItems="center">
            <DrawerTopChrome onClose={handleCloseRecipes} />
            {(calendar.recipes ?? []).length === 0 ? (
              <Typography variant="h6">No recipes yet</Typography>
            ) : (
              <></>
            )}
            <RecipeList recipeIds={calendar.recipes ?? []} />
          </Stack>
        </Box>
      </Drawer>
      <Drawer
        anchor={"bottom"}
        open={showContributors}
        onClose={handleCloseContributors}
      >
        <Box
          role="presentation"
          sx={{ width: "100%", height: "calc(100vh - 260px)" }}
        >
          <Stack alignItems="center">
            <CalendarContributorsList calendarId={calendar.id} />
          </Stack>
        </Box>
      </Drawer>
      <Drawer
        anchor={"bottom"}
        open={showFollowers}
        onClose={handleCloseFollowers}
      >
        <Box
          role="presentation"
          sx={{ width: "100%", height: "calc(100vh - 260px)" }}
        >
          <Stack alignItems="center">
            <CalendarContributorsList calendarId={calendar.id} showFollowers />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
