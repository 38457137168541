import "../../common/Common.css";

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Skeleton } from "@mui/material";

import { ExpandedPhoto } from "../../common";
import { useRecipe } from "../recipes";
import { usePhoto } from "./hooks";
import { PhotoMetadata } from "./types";

export function UserPhotosListItem({ metadata }: { metadata: PhotoMetadata }) {
  const photo = usePhoto({ metadata });
  const recipe = useRecipe({ recipeId: metadata.recipeId ?? "unknown" });
  const navigate = useNavigate();
  const onTitleClick = useCallback(() => {
    recipe && navigate(`/recipes/${recipe.id}`);
  }, [recipe, navigate]);

  if (!photo) return <Skeleton />;
  return (
    <ExpandedPhoto
      photoSrc={photo.url}
      title={recipe?.title ?? ""}
      actionIcon={
        recipe && (
          <img
            height="16"
            width="16"
            src={
              recipe.data?.favicon_url
                ? recipe.data.favicon_url
                : `http://www.google.com/s2/favicons?domain=${recipe.data?.url}&sz=256`
            }
            alt="Logo"
          />
        )
      }
      onTitleClick={onTitleClick}
    />
  );
}
