import "../../common/Common.css";

import { useCallback, useEffect, useRef, useState } from "react";
import { ViewportList } from "react-viewport-list";

import { Box, CircularProgress, Stack } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { selectCurrentUser } from "../users";
import { PostCard } from "./PostCard";
import { fetchPostsByUser, selectPostsByUser } from "./postsSlice";

export function UsersPostsList({ userId }: { userId: string }) {
  const dispatch = useAppDispatch();
  const supabase = useClient();
  const currentUser = useAppSelector(selectCurrentUser);
  const [currentScrollTop, setCurrentScrollTop] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const posts = useAppSelector((state) => {
    return selectPostsByUser(state, userId);
  });

  const handleScroll = useCallback(() => {
    if (!ref.current || !currentUser) return;

    const scrollTop = ref.current.scrollTop;
    if (scrollTop < 10 && scrollTop !== currentScrollTop) {
      setIsLoading(true);
      dispatch(
        fetchPostsByUser({
          supabase,
          currentUser,
          userId,
          errorCallback: console.log,
          successCallback: handleSuccess,
        }),
      );
    }

    setCurrentScrollTop(scrollTop);
  }, [currentScrollTop, currentUser, ref, dispatch, supabase, userId]);

  const handleSuccess = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (!currentUser) return;

    dispatch(
      fetchPostsByUser({
        supabase,
        currentUser,
        userId,
        errorCallback: console.log,
        successCallback: handleSuccess,
      }),
    );
  }, [dispatch, currentUser, userId, supabase]);

  return (
    <>
      {isLoading && (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ position: "aboslute", top: 10 }}
        >
          <CircularProgress />
        </Stack>
      )}
      <Box className="scroll-container" ref={ref} onScroll={handleScroll}>
        <ViewportList viewportRef={ref} items={posts}>
          {(post, index) => {
            return <PostCard key={index} postId={post.id} />;
          }}
        </ViewportList>
      </Box>
    </>
  );
}
