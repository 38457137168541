import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import ReactGa from "react-ga4";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import * as Sentry from "@sentry/react";
import { createClient } from "@supabase/supabase-js";
import { Analytics } from "@vercel/analytics/react";

import App from "./App";
import { ReactQueryClientProvider } from "./ReactQueryClientProvider";
import {
  EnvironmentConfiguration,
  EnvironmentContextProvider,
  SupabaseConfigContextProvider,
} from "./common";
import { store } from "./common/store";
import { SupabaseProvider } from "./common/supabase/context";
import { Database } from "./common/supabase/generated_dbtypes";
import reportWebVitals from "./reportWebVitals";

const environmentConfig: EnvironmentConfiguration = {
  environment: process.env.REACT_APP_ENVIRONMENT ?? "UNKNOWN",
  feedback_form_url: process.env.REACT_APP_FEEDBACK_FORM_URL ?? "UNKNOWN",
  hostname: process.env.REACT_APP_HOSTNAME ?? "UNKNOWN",
  sentry_url: process.env.REACT_APP_SENTRY_URL ?? "UNKNOWN",
  supabase_url: process.env.REACT_APP_SUPABASE_URL ?? "UNKNOWN",
  release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA ?? "UNKNOWN",
  is_prod: !process.env.REACT_APP_HOSTNAME?.includes("localhost") ?? true,
  // is_prod: true,
};

environmentConfig.supabase_url !== "UNKNOWN" &&
  Sentry.init({
    dsn: environmentConfig.sentry_url,
    environment: environmentConfig.environment,
    release: environmentConfig.release,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/${environmentConfig.hostname}/,
          /^https:\/\/${environementConfig.supabase_url}/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate:
      process.env.REACT_APP_ENVIRONMENT === "production" ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
  });

// Initialize supabase client.
const supabase = createClient<Database>(
  environmentConfig.supabase_url,
  process.env.REACT_APP_SUPABASE_ANON_KEY ?? "",
);

// Initialize supabase config context.
const supabaseConfig = { hostname: environmentConfig.supabase_url };

// Initialize analytics.
const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
if (googleAnalyticsId) ReactGa.initialize(googleAnalyticsId);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ReactQueryClientProvider>
      <BrowserRouter>
        <EnvironmentContextProvider value={environmentConfig}>
          <SupabaseConfigContextProvider value={supabaseConfig}>
            <SupabaseProvider value={supabase}>
              <ReduxProvider store={store}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <App />
                  <Analytics />
                </LocalizationProvider>
              </ReduxProvider>
            </SupabaseProvider>
          </SupabaseConfigContextProvider>
        </EnvironmentContextProvider>
      </BrowserRouter>
    </ReactQueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((metric) => {
  ReactGa.event({
    category: "Web Vitals",
    action: metric.name,
    value: Math.round(
      metric.name === "CLS" ? metric.value * 1000 : metric.value,
    ),
    label: metric.id,
    nonInteraction: true,
  });
});
