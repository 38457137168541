import { useCallback, useContext } from "react";

import { Avatar, Box, Card } from "@mui/material";
import { Stack } from "@mui/system";

import { TruncatedText } from "../../common";
import { SelectableRecipeContext } from "./context";
import { useRecipe } from "./hooks";

export function RecipeListItem({ recipeId }: { recipeId: string }) {
  const recipe = useRecipe({ recipeId });
  const { handleSelectRecipeId } = useContext(SelectableRecipeContext);
  const onClick = useCallback(() => {
    handleSelectRecipeId && handleSelectRecipeId(recipeId);
  }, [handleSelectRecipeId, recipeId]);

  if (!recipe) return null;

  return (
    <Box onClick={onClick}>
      <Card sx={{ padding: 1 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="start"
        >
          <img
            style={{
              borderRadius: "8px",
              width: "64px",
              height: "64px",
              marginLeft: "8px",
              objectFit: "cover",
            }}
            src={recipe?.imageUrl}
            alt="recipe"
          />
          <Stack>
            <TruncatedText text={recipe.title} maxLines={1} variant="body1" />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
              gap={1}
            >
              <Avatar
                src={
                  recipe?.data?.url
                    ? `https://www.google.com/s2/favicons?domain=${recipe.data.url}&sz=256`
                    : "https://webaddress.site"
                }
                sx={{
                  width: "32px",
                  height: "32px",
                }}
                alt="logo"
              />
              <TruncatedText
                text={recipe?.data?.site_name ?? "unknown"}
                maxLines={1}
                variant="caption"
              />
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
}
