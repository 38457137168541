import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { Skeleton, Stack, Tab, Tabs, Typography } from "@mui/material";

import { PageLayout, TruncatedText, useAppSelector } from "../common";
import { RouxTabPanel } from "../common";
import { TopChrome } from "../features/chrome";
import { UserCommentsList } from "../features/comments/UserCommentsList";
import { UserContributionsGraph } from "../features/contributions";
import { UserPhotosList } from "../features/photos";
import { UsersPostsList } from "../features/posts";
import {
  ProfileFollowOrEditButton,
  ProfileTopChrome,
} from "../features/profile";
import {
  UserAvatar,
  UserStats,
  selectCurrentUser,
  useUser,
} from "../features/users";

export function ProfilePage({ userId: requestedUserId }: { userId?: string }) {
  const [value, setValue] = useState(0);
  const params = useParams();
  const userId = requestedUserId ?? params?.id ?? "unknown";
  const user = useUser({ userId });
  const currentUser = useAppSelector(selectCurrentUser);
  const isCurrentUser = user?.id === currentUser?.id;

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  return (
    <PageLayout
      topChrome={isCurrentUser ? <ProfileTopChrome /> : <TopChrome />}
    >
      <Stack
        sx={{
          marginTop: 1,
          paddingLeft: 0.5,
          paddingRight: 0.5,
          paddingTop: "12px",
          height: "calc(100vh - 100px)",
        }}
        gap={1}
        alignItems="center"
      >
        {user && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            sx={{ width: 1 }}
          >
            <UserAvatar
              userId={user.id}
              height={120}
              width={120}
              notClickable
            />
            <UserStats userId={user.id} />
          </Stack>
        )}
        {!user && <Skeleton height={120} width={380} />}
        <Stack alignItems="center" direction="row" sx={{ width: 1 }}>
          <Stack sx={{ width: "360px" }}>
            <Typography variant="h6" sx={{ marginLeft: 1, marginRight: 1 }}>
              {user?.full_name ?? "Unknown User"}
            </Typography>
            <Typography variant="caption" sx={{ marginLeft: 1 }}>
              Joined: {user && new Date(user.joinedDate).toLocaleDateString()}
            </Typography>
          </Stack>
          {user && <ProfileFollowOrEditButton userId={user.id} />}
        </Stack>
        {user?.bio && (
          <Stack sx={{ width: 1 }}>
            <TruncatedText
              text={user.bio}
              wordLimit={300}
              sx={{ marginLeft: 1 }}
            />
          </Stack>
        )}
        <UserContributionsGraph userId={user?.id} />
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Posts"></Tab>
          <Tab label="Recipes"></Tab>
          <Tab label="Photos"></Tab>
          <Tab label="Comments"></Tab>
        </Tabs>
        <RouxTabPanel
          value={value}
          index={0}
          style={{ width: "100%", height: "100%" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: 1, width: 1 }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ height: 1, width: 1 }}
            >
              {user && <UsersPostsList userId={user.id} />}
              {!user && <Skeleton />}
            </Stack>
          </Stack>
        </RouxTabPanel>
        <RouxTabPanel value={value} index={1}>
          <Stack
            sx={{ width: "300px" }}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Stack alignItems="center" justifyContent="center">
              <Typography align="center">
                Soon you'll be able to add your own recipes!
              </Typography>
            </Stack>
          </Stack>
        </RouxTabPanel>
        <RouxTabPanel
          value={value}
          index={2}
          style={{ height: "100%", width: "100%" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: 1, width: 1 }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ width: 1, height: 1 }}
            >
              {user && <UserPhotosList userId={user.id} />}
              {!user && <Skeleton />}
            </Stack>
          </Stack>
        </RouxTabPanel>
        <RouxTabPanel
          value={value}
          index={3}
          style={{ width: "100%", height: "100%" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: 1, width: 1 }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ height: 1, width: 1 }}
            >
              {user && <UserCommentsList userId={user.id} />}
              {!user && <Skeleton />}
            </Stack>
          </Stack>
        </RouxTabPanel>
      </Stack>
    </PageLayout>
  );
}
