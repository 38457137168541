import "../../common/Common.css";

import { useRef } from "react";
import { ViewportList } from "react-viewport-list";

import { Box, Stack } from "@mui/material";

import { rouxBottomChromeHeight } from "../../common";
import { recipeBottomChromeHeight } from "../recipes";
import { RecipeCommentListItem } from "./RecipeCommentListItem";
import { useRecipeComments } from "./hooks";

export function RecipeCommentsList({ recipeId }: { recipeId: string }) {
  const comments = useRecipeComments({ recipeId });
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <>
      {comments.length === 0 && <Stack></Stack>}
      {comments.length > 0 && (
        <Box
          className="scroll-container"
          ref={ref}
          sx={{
            height: 1,
            backgroundColor: "lightgrey",
            paddingTop: "4px",
            paddingBottom: `${
              rouxBottomChromeHeight + recipeBottomChromeHeight
            }px`,
          }}
        >
          <ViewportList viewportRef={ref} items={comments}>
            {(comment) => {
              return (
                <RecipeCommentListItem key={comment.id} comment={comment} />
              );
            }}
          </ViewportList>
        </Box>
      )}
    </>
  );
}
