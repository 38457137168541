import { useCallback, useState } from "react";

import { Avatar } from "@mui/material";

import { AboutDrawer } from "./AboutDrawer";

export function AboutAvatar() {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const handleClose = useCallback(() => {
    setShowDrawer(false);
  }, []);
  return (
    <>
      <Avatar
        alt="Roux Logo"
        src="/logo512.png"
        onClick={() => {
          setShowDrawer(true);
        }}
        sx={{ width: 36, height: 36, marginBottom: 1, marginTop: 1 }}
      />
      <AboutDrawer open={showDrawer} onClose={handleClose} />
    </>
  );
}
