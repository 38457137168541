import "./AboutDrawer.css";

import { useCallback, useContext, useRef } from "react";

import { CopyAll } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Avatar, Box, Button, Drawer, Stack, Typography } from "@mui/material";

import {
  EnvironmentConfiguration,
  EnvironmentContext,
  useAppSelector,
} from "../../common";
import { useServiceWorker } from "../../serviceWorker/hooks";
import { DrawerTopChrome } from "../chrome";
import { selectCurrentUser } from "../users";
import { AboutText } from "./AboutText";

const debugTextStyle = {
  fontFamily: "monospace",
  whiteSpace: "nowrap",
};

function DebugValue({
  label,
  value,
}: {
  label: string;
  value: string | boolean;
}) {
  const valueTextEl = useRef<HTMLDivElement>(null);
  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(value.toString());
    valueTextEl.current?.classList.add("debug-value-copied");
    setTimeout(() => {
      valueTextEl.current?.classList.remove("debug-value-copied");
    }, 500);
  }, [value, valueTextEl]);
  return (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={copyToClipboard}>
        <CopyAll />
      </IconButton>
      <Typography
        variant="caption"
        sx={debugTextStyle}
      >{`${label}:`}</Typography>
      <Typography variant="caption">&nbsp;</Typography>
      <Typography
        variant="caption"
        sx={debugTextStyle}
        ref={valueTextEl}
      >{`${value}`}</Typography>
    </Stack>
  );
}

export function AboutDrawer({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const currentUser = useAppSelector(selectCurrentUser);
  const environmentConfig =
    useContext<EnvironmentConfiguration>(EnvironmentContext);
  const openFeedbackForm = useCallback(() => {
    if (environmentConfig.feedback_form_url) {
      window.open(environmentConfig.feedback_form_url, "_blank");
    }
  }, [environmentConfig]);
  const { showReload, reloadPage, waitingWorker } = useServiceWorker();
  const updateApp = useCallback(() => {
    reloadPage();
  }, [reloadPage]);
  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box
        role="presentation"
        sx={{
          width: "100vw",
          height: 1,
          overflowX: "hidden",
        }}
      >
        <Stack alignItems="center" sx={{ width: 1, height: 1 }} gap={2}>
          <DrawerTopChrome onClose={onClose} />
          <Avatar
            alt="Roux Logo"
            src="/logo512.png"
            sx={{ width: 360, height: 360, marginBottom: 1, marginTop: 1 }}
          />
          <Typography variant="h5">About</Typography>
          <AboutText />
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ width: 1, padding: 2 }}
            gap={2}
          >
            <Button onClick={updateApp} variant="outlined">
              Update App
            </Button>
            <Button onClick={openFeedbackForm} variant="contained">
              <Typography variant="body2">Send Feedback</Typography>
            </Button>
          </Stack>
          <Stack
            justifyContent="start"
            sx={{
              marginTop: 1,
              padding: 1,
              width: "100vw",
              fontFamily: "monospace",
            }}
          >
            <DebugValue
              key={"user_id"}
              label="user_id"
              value={currentUser?.id ?? "unknown"}
            />
            <DebugValue
              key={"release"}
              label="release"
              value={environmentConfig["release"] ?? "unknown"}
            />
            {!environmentConfig.is_prod && (
              <>
                {Object.entries(environmentConfig).map((key) => {
                  return (
                    <DebugValue key={key[0]} label={key[0]} value={key[1]} />
                  );
                })}
                <DebugValue
                  key={"showReload"}
                  label={"showReload"}
                  value={showReload.toString()}
                />
                <DebugValue
                  key="waitingWorker"
                  label={"waitingWorker"}
                  value={(!!waitingWorker).toString()}
                />
              </>
            )}
          </Stack>
          <div style={{ height: "100px", width: "100px" }}></div>
        </Stack>
      </Box>
    </Drawer>
  );
}
