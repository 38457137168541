import { useRef } from "react";
import { ViewportList } from "react-viewport-list";

import { Divider, Stack } from "@mui/material";

import { CalendarAvatar } from ".";
import { useUser } from "../users";

export function UsersCalendarList({ userId }: { userId: string }) {
  const user = useUser({ userId });
  const ref = useRef<HTMLDivElement | null>(null);
  if (!user) return null;

  return (
    <Stack ref={ref} gap={2} sx={{ width: 1 }}>
      <ViewportList viewportRef={ref} items={Object.values(user.calendars)}>
        {(cal, index) => (
          <div key={index}>
            <CalendarAvatar
              calendarId={cal.id}
              width={36}
              height={36}
              showName
            />
            <Divider />
          </div>
        )}
      </ViewportList>
    </Stack>
  );
}
