import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../common/store";
import { ApiThunkArgs } from "../../common/types";
import { Photo, PhotoMetadata } from "./types";

export interface PhotosState {
  // userId: [photoIds]
  photoMetadataByUser: { [key: string]: PhotoMetadata[] };
  // photoId: Photo[]
  photosById: { [key: string]: Photo };
}

const initialState: PhotosState = {
  photoMetadataByUser: {},
  photosById: {},
};

export const fetchPhotoMetadataForUser = createAsyncThunk(
  "photos/fetchPhotoMetadataForUser",
  async ({
    supabase,
    errorCallback,
    userId,
  }: { userId: string } & ApiThunkArgs) => {
    const { data, error } = await supabase
      .from("recipe_photos")
      .select()
      .eq("user_id", userId)
      .order("added_ts", { ascending: false });

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return data.map((d) => {
      return {
        bucket: "recipe_photos",
        path: `${d.recipe_id}/${d.user_id}`,
        id: d.id,
        addedTs: d.added_ts,
        recipeId: d.recipe_id,
      };
    });
  },
);

export const fetchPhoto = createAsyncThunk(
  "photos/fetchPhoto",
  async ({
    supabase,
    errorCallback,
    photoMetadata,
  }: {
    photoMetadata: PhotoMetadata;
  } & ApiThunkArgs) => {
    const { data, error } = await supabase.storage
      .from(photoMetadata.bucket)
      .download(`${photoMetadata.path}/${photoMetadata.id}`);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return URL.createObjectURL(data);
  },
);

export const photosSlice = createSlice({
  name: "photos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPhotoMetadataForUser.fulfilled, (state, action) => {
        state.photoMetadataByUser[action.meta.arg.userId] = action.payload;
      })
      .addCase(fetchPhoto.fulfilled, (state, action) => {
        state.photosById[action.meta.arg.photoMetadata.id] = {
          ...action.meta.arg.photoMetadata,
          url: action.payload,
        };
      });
  },
});

export const selectPhotoById = (state: RootState, id: string) =>
  state.photosStore.photosById[id];

export const selectPhotoMetadataForUser = (state: RootState, id: string) =>
  state.photosStore.photoMetadataByUser[id];

export default photosSlice.reducer;
