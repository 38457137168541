import { useContext } from "react";

import { Stack, Typography } from "@mui/material";

import { EnvironmentContext } from "../../common";
import { usePost } from "./hooks";
import { PostcardProps } from "./types";

export function PostBottomChrome({ postId }: PostcardProps) {
  const post = usePost({ postId });
  const { is_prod } = useContext(EnvironmentContext);

  if (is_prod || !post) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="end"
      sx={{
        top: -1,
        height: "30px",
        padding: "2px",
        backgroundColor: "lightgrey",
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
      }}
    >
      <Typography>{post.type}</Typography>
    </Stack>
  );
}
