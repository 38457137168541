import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import type { RootState } from "../../common/store";
import { TablesUpdate } from "../../common/supabase/generated_dbtypes";
import { ApiThunkArgs, AuthedApiThunkArgs } from "../../common/types";
import type { Recipe } from "../recipes";
import type { CurrentUser } from "../users";
import { Calendar, ScheduledRecipe, calendarToSchema } from "./types";

import { merge } from "lodash";

export interface CalendarState {
  calendarsById: { [id: string]: Calendar };
  scheduledRecipesPerCalendarPerDay: {
    [calendarId: string]: {
      [date: string]: {
        [recipeId: string]: ScheduledRecipe;
      };
    };
  };
}

const initialState: CalendarState = {
  calendarsById: {},
  scheduledRecipesPerCalendarPerDay: {},
};

export const fetchAllCalendars = createAsyncThunk(
  "calendars/fetchAllCalendars",
  async ({ supabase, errorCallback, successCallback }: ApiThunkArgs) => {
    const { data, error } = await supabase.functions.invoke("calendars-get", {
      body: { calendarIds: [] },
    });

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    successCallback();

    return (data as Calendar[]).reduce(
      (acc, c) => {
        acc[c.id] = c;
        return acc;
      },
      {} as { [key: string]: Calendar },
    );
  },
);

export const fetchCalendar = createAsyncThunk(
  "calendars/fetchCalendar",
  async ({
    supabase,
    calendarId,
    errorCallback,
    successCallback,
  }: {
    calendarId: string;
  } & ApiThunkArgs) => {
    const { data, error } = await supabase.functions.invoke("calendars-get", {
      body: { calendarIds: [calendarId] },
    });
    if (error) {
      errorCallback(error.message);
      throw error;
    }

    const rawCalendar = data[0] as Calendar;
    let calendarUrl = null;
    if (rawCalendar.calendar_photo_url_fragment) {
      try {
        const { data, error } = await supabase.storage
          .from("calendar_photos")
          .download(rawCalendar.calendar_photo_url_fragment);
        if (error) {
          errorCallback(error.message);
          throw error;
        }

        calendarUrl = URL.createObjectURL(data);
      } catch (error) {
        errorCallback(`Error downloading image: ${JSON.stringify(error)}`);
        throw error;
      }
    }

    successCallback();

    return {
      ...rawCalendar,
      calendar_url: calendarUrl,
    };
  },
);

export const removeEditorFromCalendar = createAsyncThunk(
  "calendars/removeEditorFromCalendar",
  async ({
    userId,
    calendarId,
    supabase,
    successCallback,
    errorCallback,
  }: { userId: string; calendarId: string } & ApiThunkArgs) => {
    const { error } = await supabase
      .from("calendar_editors")
      .delete()
      .eq("user_id", userId)
      .eq("calendar_id", calendarId);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    successCallback();

    return { userId };
  },
);

export const insertCalendars = createAsyncThunk(
  "calendars/insertCalendars",
  async ({
    calendars: rawCalendars,
    supabase,
    errorCallback,
    successCallback,
  }: {
    calendars: { [key: string]: Calendar };
  } & ApiThunkArgs) => {
    let calendars = {} as { [key: string]: Calendar };
    const keys = Object.keys(rawCalendars);
    for (let i = 0; i < keys.length; i++) {
      const rawCalendar = rawCalendars[keys[i]];
      let calendarUrl = null;
      if (rawCalendar.calendar_photo_url_fragment) {
        try {
          const { data, error } = await supabase.storage
            .from("calendar_photos")
            .download(rawCalendar.calendar_photo_url_fragment);
          if (error) {
            errorCallback(error.message);
            throw error;
          }

          calendarUrl = URL.createObjectURL(data);
        } catch (error) {
          errorCallback(`Error downloading image: ${JSON.stringify(error)}`);
          throw error;
        }
      }
      calendars[keys[i]] = {
        ...rawCalendar,
        calendar_url: calendarUrl,
      };
    }
    return calendars;
  },
);

// TODO: Add the ability to update .recipes on Calendar.
// TODO: make a cloud function to wrap up all the sql.
export const updateCalendar = createAsyncThunk(
  "calendars/updateCalendar",
  async ({
    updatedCalendar,
    supabase,
    editorsToRemove,
    editorsToAdd,
    successCallback,
    errorCallback,
  }: // TODO: type should be CalendarAPI below when exists
  {
    updatedCalendar: Calendar;
    editorsToRemove: { [key: string]: boolean };
    editorsToAdd: { [key: string]: boolean };
  } & ApiThunkArgs) => {
    const { error } = await supabase
      .from("calendars")
      .update(calendarToSchema({ calendar: updatedCalendar }))
      .eq("id", updatedCalendar.id);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    // Rebuild the in-memory .editors user_id array
    let newEditors = updatedCalendar.editors
      .filter((e) => !editorsToRemove[e])
      .reduce(
        (acc, e) => {
          acc[e] = true;
          return acc;
        },
        {} as { [key: string]: boolean },
      );
    newEditors = {
      ...newEditors,
      ...editorsToAdd,
    };

    const editorIdsToRemove = Object.keys(editorsToRemove);
    if (editorIdsToRemove.length) {
      const { error: removeError } = await supabase
        .from("calendar_editors")
        .delete()
        .in("user_id", editorIdsToRemove)
        .eq("calendar_id", updatedCalendar.id);
      if (removeError) {
        errorCallback(removeError.message);
        throw removeError;
      }
    }

    const editorIdsToAdd = Object.keys(editorsToAdd);
    if (editorIdsToAdd.length) {
      const { error: addError } = await supabase
        .from("calendar_editors")
        .insert(
          editorIdsToAdd.map((eId) => {
            return {
              calendar_id: updatedCalendar.id,
              user_id: eId,
            };
          }),
        );
      if (addError) {
        errorCallback(addError.message);
        throw addError;
      }
    }

    // TODO: Add the ability to update .recipes on Calendar.

    let calendarUrl = null;
    if (updatedCalendar.calendar_photo_url_fragment) {
      try {
        const { data, error } = await supabase.storage
          .from("calendar_photos")
          .download(updatedCalendar.calendar_photo_url_fragment);
        if (error) {
          errorCallback(error.message);
          throw error;
        }

        calendarUrl = URL.createObjectURL(data);
      } catch (error) {
        errorCallback(`Error downloading image: ${JSON.stringify(error)}`);
        throw error;
      }
    }

    successCallback();

    return {
      ...updatedCalendar,
      editors: Object.keys(newEditors),
      calendar_url: calendarUrl,
    };
  },
);

export const deleteCalendar = createAsyncThunk(
  "calendars/delete",
  async ({
    supabase,
    calendarId,
    errorCallback,
  }: { calendarId: string } & ApiThunkArgs) => {
    const { error } = await supabase
      .from("calendars")
      .delete()
      .eq("id", calendarId);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return { calendarId };
  },
);

export const removeRecipeFromDay = createAsyncThunk(
  "calendars/removeRecipeFromDay",
  async ({
    supabase,
    date,
    scheduledRecipe,
    errorCallback,
  }: {
    date: Date;
    scheduledRecipe: ScheduledRecipe;
  } & ApiThunkArgs) => {
    // Ensure we're at midnight.
    date.setHours(0, 0, 0, 0);

    const { error } = await supabase
      .from("scheduled_recipes")
      .delete()
      .eq("added_by", scheduledRecipe.added_by)
      .eq("scheduled_date", date.toDateString())
      .eq("recipe_id", scheduledRecipe.recipe_id)
      .eq("calendar_id", scheduledRecipe.calendar_id);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return;
  },
);

export const followCalendar = createAsyncThunk(
  "calendars/followCalendar",
  async ({
    currentUser,
    supabase,
    calendarId,
    errorCallback,
  }: { calendarId: string } & AuthedApiThunkArgs) => {
    const calendarFollowRow = {
      calendar_id: calendarId,
      user_id: currentUser.id,
      added_ts: new Date().toISOString(),
    };
    const { error } = await supabase
      .from("calendar_follows")
      .insert(calendarFollowRow);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return { currentUser, calendarId };
  },
);

export const unfollowCalendar = createAsyncThunk(
  "calendars/unfollowCalendar",
  async ({
    currentUser,
    supabase,
    calendarId,
    errorCallback,
  }: { calendarId: string } & AuthedApiThunkArgs) => {
    const { error } = await supabase
      .from("calendar_follows")
      .delete()
      .eq("calendar_id", calendarId)
      .eq("user_id", currentUser.id);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return { currentUser, calendarId };
  },
);

export const scheduleRecipeForDay = createAsyncThunk(
  "calendars/scheduleRecipeForDay",
  async ({
    date,
    supabase,
    recipe,
    calendarId,
    successCallback,
    errorCallback,
    callback,
    currentUser: user,
  }: {
    date: Date;
    currentUser: CurrentUser;
    calendarId: string;
    recipe: Recipe;
    callback: (scheduledDate: Date) => void;
  } & ApiThunkArgs) => {
    // Ensure we're at midnight.
    date.setHours(0, 0, 0, 0);

    const scheduledRecipe = {
      added_by: user.id,
      scheduled_date: date.toISOString().substring(0, 10),
      recipe_id: recipe.id,
      calendar_id: calendarId,
    };

    const { data, error } = await supabase.functions.invoke(
      "recipes-schedule",
      {
        body: { scheduledRecipe },
      },
    );

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    callback(date);

    return {
      scheduledRecipe: data,
      user,
      dateString: date.toDateString(),
    };
  },
);

export const fetchScheduledRecipesForDateRange = createAsyncThunk(
  "calendars/fetchScheduledRecipesForDateRange",
  async ({
    from,
    to,
    calendarIds,
    supabase,
    successCallback,
    errorCallback,
  }: { from: Date; to: Date; calendarIds: string[] } & ApiThunkArgs) => {
    // Ensure dates are at midnight.
    from.setHours(0, 0, 0, 0);
    to.setHours(0, 0, 0, 0);

    const { data, error } = await supabase
      .from("scheduled_recipes")
      .select("*")
      .lte("scheduled_date", to.toISOString())
      .gte("scheduled_date", from.toISOString())
      .in("calendar_id", calendarIds);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    successCallback();

    return Object.values(data).reduce(
      (acc, c) => {
        if (acc[c.calendar_id]) {
          if (acc[c.calendar_id][c.scheduled_date]) {
            return {
              ...acc,
              [c.calendar_id]: {
                ...acc[c.calendar_id],
                [c.scheduled_date]: {
                  ...acc[c.calendar_id][c.scheduled_date],
                  [c.recipe_id]: c,
                },
              },
            };
          } else {
            return {
              ...acc,
              [c.calendar_id]: {
                ...acc[c.calendar_id],
                [c.scheduled_date]: {
                  [c.recipe_id]: c,
                },
              },
            };
          }
        } else {
          return {
            ...acc,
            [c.calendar_id]: {
              [c.scheduled_date]: {
                [c.recipe_id]: c,
              },
            },
          };
        }
      },
      {} as {
        [key: string]: { [key: string]: TablesUpdate<"scheduled_recipes"> };
      },
    );
  },
);

export const calendarSlice = createSlice({
  name: "calendars",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalendar.fulfilled, (state, action) => {
        const calendar = action.payload;
        state.calendarsById[calendar.id] = calendar;
      })
      .addCase(removeRecipeFromDay.fulfilled, (state, action) => {
        const date = action.meta.arg.scheduledRecipe.scheduled_date;
        const recipeId = action.meta.arg.scheduledRecipe.recipe_id;
        const calendarId = action.meta.arg.scheduledRecipe.calendar_id;

        const scheduledRecipesForDay = state.scheduledRecipesPerCalendarPerDay;
        delete scheduledRecipesForDay[calendarId][date][recipeId];
        state.scheduledRecipesPerCalendarPerDay = scheduledRecipesForDay;
      })
      .addCase(fetchAllCalendars.fulfilled, (state, action) => {
        state.calendarsById = {
          ...state.calendarsById,
          ...action.payload,
        };
      })
      .addCase(insertCalendars.fulfilled, (state, action) => {
        state.calendarsById = {
          ...state.calendarsById,
          ...action.payload,
        };
      })
      .addCase(removeEditorFromCalendar.fulfilled, (state, action) => {
        const userToRemove = action.payload.userId;
        const calendarId = action.meta.arg.calendarId;
        state.calendarsById[calendarId] = {
          ...state.calendarsById[calendarId],
          editors: state.calendarsById[calendarId].editors.filter(
            (e) => e !== userToRemove,
          ),
        };
      })
      .addCase(followCalendar.fulfilled, (state, action) => {
        state.calendarsById[action.payload.calendarId].followers.push(
          action.payload.currentUser.id,
        );
      })
      .addCase(unfollowCalendar.fulfilled, (state, action) => {
        state.calendarsById[action.payload.calendarId].followers =
          state.calendarsById[action.payload.calendarId].followers.filter(
            (u) => u !== action.payload.currentUser.id,
          );
      })
      .addCase(updateCalendar.fulfilled, (state, action) => {
        const updatedCalendar = action.payload;
        state.calendarsById[updatedCalendar.id] = updatedCalendar;
      })
      .addCase(deleteCalendar.fulfilled, (state, action) => {
        const calendarId = action.payload.calendarId;
        delete state.calendarsById[calendarId];
        delete state.scheduledRecipesPerCalendarPerDay[calendarId];
      })
      .addCase(scheduleRecipeForDay.fulfilled, (state, action) => {
        const scheduledRecipe = action.payload.scheduledRecipe;
        const calendarId = scheduledRecipe.calendar_id;
        const recipeId = scheduledRecipe.recipe_id;
        const date = scheduledRecipe.scheduled_date;
        const newData = {
          [calendarId]: {
            [date]: {
              [recipeId]: scheduledRecipe,
            },
          },
        };
        merge(state.scheduledRecipesPerCalendarPerDay, newData);
      })
      .addCase(fetchScheduledRecipesForDateRange.fulfilled, (state, action) => {
        merge(state.scheduledRecipesPerCalendarPerDay, action.payload);
      });
  },
});

export const selectAllCalendars = (state: RootState) =>
  Object.values(state.calendarStore.calendarsById);

export const selectCalendarById = (state: RootState, calendarId?: string) =>
  calendarId ? state.calendarStore.calendarsById[calendarId] : undefined;

export const selectScheduledRecipesByDate = createSelector(
  [
    (state: RootState) => state.calendarStore.scheduledRecipesPerCalendarPerDay,
    (state: RootState, calendarIds: string[]) => calendarIds,
    (state: RootState, calendarIds: string[], date: string) => date,
  ],
  (
    rawValue: {
      [calendarId: string]: {
        [date: string]: {
          [recipeId: string]: ScheduledRecipe;
        };
      };
    },
    calendarIds: string[],
    date: string,
  ) => {
    return calendarIds.reduce((acc, c) => {
      if (rawValue[c] && rawValue[c][date]) {
        acc = acc.concat(Object.values(rawValue[c][date]));
      }
      return acc;
    }, [] as ScheduledRecipe[]);
  },
);

export default calendarSlice.reducer;
