import { Box } from "@mui/material";

import { Contributions } from "./types";

export function UserContributionsGraphTile({
  date,
  contributions,
}: {
  date: Date;
  contributions: Contributions;
}) {
  const count = contributions[date.toISOString().substring(0, 10)] ?? 0;
  const isFuture = date.getTime() > Date.now();
  // You hate this, everyone hates this, you could change it. Fuck it.
  const colorString =
    !isFuture && count > 0
      ? `rgba(226, 46, 37, ${Math.min(1.0, count / 4)})`
      : isFuture
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0,0,0,0.1)";
  return (
    <Box
      className="day"
      sx={{
        backgroundColor: colorString,
        width: 8,
        height: 8,
      }}
    />
  );
}
