import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { SupabaseClient } from "@supabase/supabase-js";

import type { RootState } from "../../common/store";
import { ApiThunkArgs, AuthedApiThunkArgs } from "../../common/types";
import type { ApiUser, CurrentUser, Profile, User } from "./types";

export interface UsersState {
  currentUser: CurrentUser | null;
  usersById: { [id: string]: User };
}

const initialState: UsersState = {
  currentUser: null,
  usersById: {},
};

export const toggleAccountVisibilityPreference = createAsyncThunk(
  "users/toggleAccountVisibilityPreference",
  async ({ currentUser, supabase }: {} & AuthedApiThunkArgs) => {
    const newValue = !currentUser.preferences.is_private;
    const newPreferences = {
      ...currentUser.preferences,
      is_private: newValue,
    };
    await supabase
      .from("profiles")
      .update({
        preferences: JSON.stringify(newPreferences),
      })
      .eq("id", currentUser.id);
    return {
      user: currentUser,
      newValue,
    };
  },
);

export const updateCurrentUsersProfile = createAsyncThunk(
  "users/updateCurrentUsersProfile",
  async ({
    currentUser,
    newAvatarUrl,
    newProfile,
    supabase,
    errorCallback,
  }: { newAvatarUrl?: string; newProfile: Profile } & AuthedApiThunkArgs) => {
    const updatedUser = {
      ...currentUser,
      ...newProfile,
    };
    const { error } = await supabase
      .from("profiles")
      .update({
        ...newProfile,
      })
      .eq("id", currentUser.id);
    if (error) {
      errorCallback(error.message);
    }

    return {
      ...updatedUser,
      // Avoids having to re-download the new avatar photo.
      avatar_url: newAvatarUrl ?? updatedUser.avatar_url,
    };
  },
);

export const setDefaultCalendarPreference = createAsyncThunk(
  "users/setDefaultCalendarPreference",
  async ({
    currentUser,
    supabase,
    calendarId,
    successCallback,
    errorCallback,
  }: { calendarId: string } & AuthedApiThunkArgs) => {
    const newPreferences = {
      ...currentUser.preferences,
      default_calendar_id: calendarId,
    };
    await supabase
      .from("profiles")
      .update({
        preferences: JSON.stringify(newPreferences),
      })
      .eq("id", currentUser.id);
    return {
      user: {
        ...currentUser,
        preferences: newPreferences,
      },
    };
  },
);

export const followUser = createAsyncThunk(
  "users/followUsers",
  async ({
    userToFollow,
    errorCallback,
    supabase,
    currentUser,
  }: { userToFollow: User } & AuthedApiThunkArgs) => {
    const profileFollowRow = {
      followed_id: userToFollow.id,
      follower_id: currentUser.id,
      added_ts: new Date().toISOString(),
    };

    const { error } = await supabase
      .from("profile_follows")
      .insert(profileFollowRow);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return {
      userToFollow: {
        ...userToFollow,
        followers: {
          ...userToFollow.followers,
          [currentUser.id]: currentUser.id,
        },
      },
      currentUser: {
        ...currentUser,
        followedUsers: {
          ...currentUser.followedUsers,
          [userToFollow.id]: userToFollow.id,
        },
      },
    };
  },
);

export const unfollowUser = createAsyncThunk(
  "users/unfollowUser",
  async ({
    errorCallback,
    supabase,
    currentUser,
    userToUnfollow,
  }: { userToUnfollow: User } & AuthedApiThunkArgs) => {
    const { error } = await supabase
      .from("profile_follows")
      .delete()
      .eq("followed_id", userToUnfollow.id)
      .eq("follower_id", currentUser.id);

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    delete currentUser.followedUsers[userToUnfollow.id];
    delete userToUnfollow.followers[currentUser.id];
    return {
      currentUser,
      userToUnfollow,
    };
  },
);

export const insertCurrentUser = createAsyncThunk(
  "users/insertCurrentUser",
  async ({ currentUser }: { currentUser: CurrentUser }) => {
    return {
      currentUser,
    };
  },
);

export const fetchUser = createAsyncThunk(
  "users/fetchUser",
  async ({
    supabase,
    userId,
  }: {
    supabase: SupabaseClient;
    userId: string;
  }) => {
    const { data: queryUserData, error: queryUserError } =
      await supabase.functions.invoke("users-get", {
        body: { userIds: [userId] },
      });
    if (queryUserError) throw queryUserError;

    // Download their avatar.
    const rawUser = queryUserData[0];
    const user = {
      ...rawUser,
      followers: (rawUser.followers as string[]).reduce(
        (acc, id) => {
          acc[id] = id;
          return acc;
        },
        {} as { [key: string]: string },
      ),
      followedUsers: (rawUser.followedUsers as string[]).reduce(
        (acc, id) => {
          acc[id] = id;
          return acc;
        },
        {} as { [key: string]: string },
      ),
    } as User;
    if (user.avatar_url) {
      const { data: rawAvatarUrl, error: avatarError } = await supabase.storage
        .from("avatars")
        .download(`${user.id}/${user.avatar_url}`);

      if (avatarError) {
        console.log(avatarError.message);
        // just don't set their avatar_url.
        return user;
      }
      return {
        ...user,
        avatar_url: URL.createObjectURL(rawAvatarUrl),
      } as User;
    }
    return user;
  },
);

export const fetchAllUsers = createAsyncThunk(
  "users/fetchAllUsers",
  async ({
    users,
    supabase,
    successCallback,
    errorCallback,
  }: { users?: string[] } & ApiThunkArgs) => {
    const { data: queryUserData, error: queryUserError } =
      await supabase.functions.invoke("users-get", {
        body: { userIds: users ? users : [] },
      });

    if (queryUserError) {
      errorCallback(queryUserError.message);
      throw queryUserError;
    }
    successCallback();

    return (queryUserData as ApiUser[]).reduce(
      (acc, u) => {
        acc[u.id] = {
          ...u,
          followers: u.followers.reduce(
            (acc, id) => {
              acc[id] = id;
              return acc;
            },
            {} as { [key: string]: string },
          ),
          followedUsers: u.followedUsers.reduce(
            (acc, id) => {
              acc[id] = id;
              return acc;
            },
            {} as { [key: string]: string },
          ),
        };
        return acc;
      },
      {} as { [key: string]: User },
    );
  },
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(insertCurrentUser.fulfilled, (state, action) => {
        const currentUser = action.payload.currentUser;
        state.usersById[currentUser.id] = currentUser;
        state.currentUser = currentUser;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const user = action.payload;
        state.usersById[user.id] = user;
        if (user.id === state.currentUser?.id) {
          state.currentUser = {
            ...state.currentUser,
            ...user,
          };
        }
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        const users = action.payload;
        Object.values(users).forEach((u) => {
          state.usersById[u.id] = u;
        });
        if (state.currentUser && users[state.currentUser.id]) {
          state.currentUser = {
            ...state.currentUser,
            ...users[state.currentUser.id],
          };
        }
      })
      .addCase(followUser.fulfilled, (state, action) => {
        const { currentUser, userToFollow } = action.payload;
        state.currentUser = currentUser;
        state.usersById = {
          ...state.usersById,
          [currentUser.id]: currentUser,
          [userToFollow.id]: userToFollow,
        };
      })
      .addCase(unfollowUser.fulfilled, (state, action) => {
        const { currentUser, userToUnfollow } = action.payload;
        state.currentUser = currentUser;
        state.usersById = {
          ...state.usersById,
          [currentUser.id]: currentUser,
          [userToUnfollow.id]: userToUnfollow,
        };
      })
      .addCase(updateCurrentUsersProfile.fulfilled, (state, action) => {
        const user = action.payload;
        state.currentUser = user;
        state.usersById[user.id] = user;
      })
      .addCase(setDefaultCalendarPreference.fulfilled, (state, action) => {
        const { user } = action.payload;
        // Note we don't need to worry about usersById here because we're
        // setting preferences which is only set on CurrentUser model.
        state.currentUser = {
          ...user,
        };
      })
      .addCase(toggleAccountVisibilityPreference.fulfilled, (state, action) => {
        const { user, newValue } = action.payload;
        const newPreferences = {
          ...user.preferences,
          is_private: newValue,
        };
        // Note we don't need to worry about usersById here because we're
        // setting preferences which is only set on CurrentUser model.
        state.currentUser = {
          ...user,
          preferences: newPreferences,
        };
      });
  },
});

export const selectAllUsers = createSelector(
  (state: RootState) => state.usersStore.usersById,
  (rawValue) =>
    Object.values(rawValue).sort((a, b) => {
      return a.username > b.username ? 1 : -1;
    }),
);

export const selectUserById = (state: RootState, userId?: string) =>
  userId ? state.usersStore.usersById[userId] : undefined;

export const selectCurrentUser = (state: RootState) =>
  state.usersStore.currentUser;

export default usersSlice.reducer;
