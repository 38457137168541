import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SupabaseClient } from "@supabase/supabase-js";

import type { RootState } from "../../common/store";
// Avoiding a circular dep below. Common's gonna be a headache.
import { ApiThunkArgs, LoadingState } from "../../common/types";
import { Recipe, RecipePhotoUpload } from "./types";

export interface RecipesState {
  recipes: { [key: string]: Recipe };
  recipePhotoUploads: { [key: string]: RecipePhotoUpload[] };
  newRecipeSavingState: LoadingState;
  newRecipeId: string | null;
}

const initialState: RecipesState = {
  recipes: {},
  recipePhotoUploads: {},
  newRecipeSavingState: LoadingState.NotStarted,
  newRecipeId: null,
};

export const fetchRecipePhotoUploads = createAsyncThunk(
  "recipes/fetchRecipePhotoUploads",
  async ({
    recipeId,
    errorCallback,
    supabase,
  }: { recipeId: string } & ApiThunkArgs) => {
    const { data, error } = await supabase
      .from("recipe_photos")
      .select()
      .eq("recipe_id", recipeId)
      .order("added_ts", { ascending: false });

    if (error) {
      errorCallback(error.message);
      throw error;
    }

    return data;
  },
);

export const fetchRecipes = createAsyncThunk(
  "recipes/fetchRecipe",
  async ({ recipeIds, supabase }: { recipeIds: string[] } & ApiThunkArgs) => {
    const { data, error } = await supabase.functions.invoke("recipes-get", {
      body: { recipeIds: recipeIds },
    });
    if (error) throw error;
    // Great.
    return data != null ? (data as Recipe[]) : [];
  },
);

export const fetchAllRecipes = createAsyncThunk(
  "recipes/fetchAllRecipes",
  async ({ supabase }: { supabase: SupabaseClient }) => {
    const { data, error } = await supabase.functions.invoke("recipes-get", {
      body: { olderThanTs: new Date().toISOString() },
    });
    if (error) throw error;
    // Great.
    return data != null ? (data as Recipe[]) : data;
  },
);

export const upsertRecipe = createAsyncThunk(
  "recipes/upsertRecipe",
  async ({ recipe }: { recipe: Recipe }) => {
    return recipe;
  },
);

export const flagRecipe = createAsyncThunk(
  "recipes/flagRecipe",
  async ({
    supabase,
    recipe,
  }: {
    supabase: SupabaseClient;
    recipe: Recipe;
  }) => {
    const { error: flagRecipeError } = await supabase
      .from("recipes")
      .update({ is_flagged: true })
      .eq("id", recipe.id);

    if (flagRecipeError) throw flagRecipeError;

    return {
      ...recipe,
      is_flagged: true,
    };
  },
);

export const saveNewRecipeByUrl = createAsyncThunk(
  "recipes/saveNewRecipeByUrl",
  async ({
    supabase,
    callback,
    errorCallback,
    recipeUrl,
  }: {
    recipeUrl: string;
    callback?: (newRecipe: Recipe) => void;
  } & ApiThunkArgs) => {
    const { data, error } = await supabase.functions.invoke("recipes-post", {
      body: { url: recipeUrl },
    });
    if (error) {
      // if a duplicate error, just operate like its fine.
      throw error;
    }
    const recipe = data;
    if (callback) {
      callback(recipe);
    }
    return recipe;
  },
);

// TODO: you couldn't make this happen in `reducers` for no known reason.
export const clearNewRecipeSavingState = createAsyncThunk(
  "recipes/clearNewRecipeSavingStatec",
  async () => {},
);

export const recipesSlice = createSlice({
  name: "recipes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearNewRecipeSavingState.fulfilled, (state) => {
        state.newRecipeId = null;
        state.newRecipeSavingState = LoadingState.NotStarted;
      })
      .addCase(fetchAllRecipes.fulfilled, (state, action) => {
        const recipes = action.payload;
        if (recipes === null) return;
        for (let recipe of recipes) {
          state.recipes[recipe.id] = recipe;
        }
      })
      .addCase(fetchRecipes.fulfilled, (state, action) => {
        const newRecipes = action.payload;
        for (let recipe of newRecipes) {
          state.recipes[recipe.id] = recipe;
        }
      })
      .addCase(flagRecipe.fulfilled, (state, action) => {
        const recipe = action.payload;
        // It's flagged, forget it.
        delete state.recipes[recipe.id];
      })
      .addCase(flagRecipe.rejected, (state) => {})
      .addCase(saveNewRecipeByUrl.fulfilled, (state, action) => {
        const recipe = action.payload;
        state.recipes[recipe.id] = recipe;
        state.newRecipeSavingState = LoadingState.Successful;
        state.newRecipeId = recipe.id;
      })
      .addCase(upsertRecipe.fulfilled, (state, action) => {
        const recipe = action.payload;
        state.recipes[recipe.id] = recipe;
      })
      .addCase(fetchRecipePhotoUploads.fulfilled, (state, action) => {
        const photoUploads = action.payload;
        state.recipePhotoUploads[action.meta.arg.recipeId] = photoUploads;
      });
  },
});

// export const {} = recipesSlice.actions;

export const selectRecipes = (state: RootState) => state.recipesStore.recipes;

export const selectNewRecipeSavingState = (state: RootState) =>
  state.recipesStore.newRecipeSavingState;

export const selectNewRecipeId = (state: RootState) =>
  state.recipesStore.newRecipeId;

export const selectRecipeById = (state: RootState, id: string) =>
  state.recipesStore.recipes[id];

export const selectRecipePhotoUploadsById = (state: RootState, id: string) =>
  state.recipesStore.recipePhotoUploads[id];

export default recipesSlice.reducer;
