import { useCallback, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { insertCalendars } from "../calendar";
import { ApiUser, CurrentUser, Preferences, User } from "./types";
import {
  fetchUser,
  insertCurrentUser,
  selectCurrentUser,
  selectUserById,
} from "./usersSlice";

export function useUser({ userId }: { userId?: string }) {
  const user = useAppSelector((state) => {
    return selectUserById(state, userId);
  });

  const appDispatch = useAppDispatch();
  const supabase = useClient();

  const fetchApiUser = useCallback(async () => {
    if (!user && userId !== "unknown" && userId) {
      appDispatch(fetchUser({ supabase, userId }));
    }
  }, [appDispatch, supabase, userId, user]);

  useMemo(async () => {
    if (!user) {
      fetchApiUser();
    }
  }, [fetchApiUser, user]);

  return user || null;
}

export function useCurrentUser({
  userId,
  successCallback,
  errorCallback,
}: {
  userId: string | undefined;
  successCallback: () => void;
  errorCallback: (errorType: string) => void;
}): CurrentUser | null {
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const supabase = useClient();

  const fetchCurrentUser = useCallback(async () => {
    if (!userId || userId === "unknown") return;

    const { data: users, error: queryUserError } =
      await supabase.functions.invoke("users-get", {
        body: { userIds: [userId] },
      });
    if (queryUserError) {
      errorCallback(queryUserError);
      throw queryUserError;
    }
    // Download their avatar.
    const rawUser = users[0] as ApiUser;
    let currentUser = {
      ...rawUser,
      followedUsers: rawUser.followedUsers.reduce(
        (acc, id) => {
          acc[id] = id;
          return acc;
        },
        {} as { [key: string]: string },
      ),
      followers: rawUser.followers.reduce(
        (acc, id) => {
          acc[id] = id;
          return acc;
        },
        {} as { [key: string]: string },
      ),
    } as User;

    if (
      currentUser.avatar_url &&
      !currentUser.avatar_url.includes("googleusercontent")
    ) {
      const { data: rawAvatarUrl, error: avatarError } = await supabase.storage
        .from("avatars")
        .download(`${currentUser.id}/${currentUser.avatar_url}`);

      if (avatarError) {
        errorCallback(avatarError.message);
      }

      currentUser = {
        ...currentUser,
        avatar_url: rawAvatarUrl
          ? URL.createObjectURL(rawAvatarUrl)
          : currentUser.avatar_url,
      } as User;
    }

    dispatch(
      insertCalendars({
        calendars: currentUser.calendars,
        supabase,
        errorCallback: console.log,
        successCallback: () => {},
      }),
    );

    // User's preferences
    const { data: prefData, error: prefError } = await supabase
      .from("profiles")
      .select("preferences")
      .eq("id", userId);
    if (prefError) {
      errorCallback(prefError.message);
      throw prefError;
    }

    // These are the default preference values
    let preferences: Preferences = { is_private: false };
    try {
      preferences = JSON.parse(prefData[0].preferences) as Preferences;
    } catch (exception) {
      // do nothing, parsing an empty preferences is OK.
    }

    dispatch(
      insertCurrentUser({
        currentUser: {
          ...currentUser,
          preferences,
        },
      }),
    );
  }, [dispatch, supabase, errorCallback, userId]);

  useMemo(() => {
    if (!user && userId) fetchCurrentUser();
  }, [fetchCurrentUser, user, userId]);

  return user;
}
