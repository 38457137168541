import { useEffect } from "react";

import {
  Card,
  CardContent,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { useAppDispatch } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { fetchRecipes } from "../recipes";
import { PostPhotoCarousel } from "./PostPhotoCarousel";
import { usePost } from "./hooks";
import { PostcardProps } from "./types";

export function GenericPostCard({ postId }: PostcardProps) {
  const post = usePost({ postId });
  // the display: 'table' below makes the card the height of its children.
  const dispatch = useAppDispatch();
  const supabase = useClient();
  useEffect(() => {
    if (!post) return;

    if (post.recipeIds.length) {
      dispatch(
        fetchRecipes({
          recipeIds: post.recipeIds,
          errorCallback: console.log,
          successCallback: () => {},
          supabase,
        }),
      );
    }
  }, [dispatch, post, supabase]);

  if (!post) return <Skeleton />;

  return (
    <Stack>
      <Card
        sx={{
          width: 1,
          display: "table",
          paddingBottom: "12px",
        }}
      >
        <Stack>
          {post.title && (
            <Typography variant="h6" sx={{ padding: 1 }}>
              {post.title}
            </Typography>
          )}
          {post.photos.length > 0 && <PostPhotoCarousel photos={post.photos} />}
          {post.contents && (
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {post.contents}
              </Typography>
            </CardContent>
          )}
        </Stack>
      </Card>
      <Divider />
    </Stack>
  );
}
