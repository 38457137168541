import { useCallback } from "react";
import { useNavigate } from "react-router";

import { Modal } from "@mui/base";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { selectCurrentUser } from "../users";
import { flagRecipe, selectRecipeById } from "./recipesSlice";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export function FlagRecipeModal({
  isOpen,
  recipeId,
  onClose,
}: {
  isOpen: boolean;
  recipeId: string;
  onClose: () => void;
}) {
  const recipe = useAppSelector((state) => {
    return selectRecipeById(state, recipeId);
  });
  const currentUser = useAppSelector(selectCurrentUser);
  const supabase = useClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleFlagRecipe = useCallback(() => {
    if (recipe && currentUser) {
      if (currentUser.id === recipe.addedBy) {
      }
      dispatch(flagRecipe({ supabase, recipe }));
    }
    onClose();
    navigate("/");
  }, [recipe, currentUser, dispatch, supabase, onClose, navigate]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Card sx={modalStyle}>
        <CardContent>
          <Stack gap={3} alignItems="center" justifyContent="space-between">
            <Typography>Flag Recipe?</Typography>
            <Typography>
              Flagging a recipe will hide it for now, until Sam can come back
              and either fix what made it broke or delete it.
            </Typography>
            <Stack direction="row" gap={1}>
              <Button onClick={handleFlagRecipe} variant="contained">
                Flag
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}
