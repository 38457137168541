import { useCallback, useEffect } from "react";

import { Rating, Skeleton, Stack, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../common";
import { useClient } from "../../common/supabase/hooks/useClient";
import { useRecipe } from "../recipes";
import { fetchRecipes } from "../recipes/recipesSlice";
import { selectCurrentUser } from "../users";
import {
  fetchRatingForRecipe,
  selectRatingForRecipe,
  setRatingForRecipe,
} from "./ratingsSlice";

export function RecipeRatingControl({ recipeId }: { recipeId: string }) {
  const recipe = useRecipe({ recipeId });

  const supabase = useClient();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const currentRating = useAppSelector((state) => {
    return selectRatingForRecipe(state, recipeId);
  });
  const handleChange = useCallback(
    (event: any, newValue: number | null) => {
      if (!currentUser) return;
      dispatch(
        setRatingForRecipe({
          recipeId: recipeId,
          supabase: supabase,
          currentUser: currentUser,
          rating: {
            score: newValue,
          },
          errorCallback: console.log,
          successCallback: () => {},
        }),
      );
      // re-fetch the whole recipe to get the newly calculated rating.
      dispatch(
        fetchRecipes({
          recipeIds: [recipeId],
          supabase: supabase,
          errorCallback: console.log,
          successCallback: () => {},
        }),
      );
    },
    [dispatch, currentUser, recipeId, supabase],
  );

  useEffect(() => {
    if (!currentUser) return;
    dispatch(
      fetchRatingForRecipe({
        recipeId: recipeId,
        supabase: supabase,
        currentUser: currentUser,
        errorCallback: console.log,
        successCallback: () => {},
      }),
    );
  }, [dispatch, recipeId, supabase, currentUser]);

  if (!recipe || !currentUser) return <Skeleton />;

  return (
    <Stack
      direction="row"
      sx={{ width: 1, padding: 1, paddingTop: "4px" }}
      gap={2}
      alignItems="center"
      justifyContent="center"
    >
      <Typography>Your rating:</Typography>
      <Rating
        size="large"
        value={currentRating?.score ?? 0}
        onChange={handleChange}
      />
    </Stack>
  );
}
