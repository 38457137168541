import "./Recipes.css";

import { useAppSelector } from "../../common";
import { RecipeGrid } from "./RecipeGrid";
import { selectRecipes } from "./recipesSlice";

import fuzzysort from "fuzzysort";

export function SearchableRecipeGrid({ searchText }: { searchText?: string }) {
  const rawRecipes = Object.values(useAppSelector(selectRecipes));
  const recipes =
    !searchText || searchText === ""
      ? rawRecipes.sort(
          (a, b) =>
            new Date(b.addedTs ?? 0).valueOf() -
            new Date(a.addedTs ?? 0).valueOf(),
        )
      : fuzzysort
          .go(searchText, rawRecipes, { key: "title" })
          .map((res) => res.obj);

  if (recipes.length === 0) return null;

  return <RecipeGrid recipes={recipes} />;
}
