import { Cancel } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { rouxTopChromeHeightPixels } from "../../common";

export function DrawerTopChrome({
  title,
  onClose,
}: {
  title?: string;
  onClose: () => void;
}) {
  return (
    <AppBar position="sticky" sx={{ height: rouxTopChromeHeightPixels }}>
      <Toolbar
        sx={{
          minHeight: rouxTopChromeHeightPixels,
          width: 1,
        }}
      >
        {title && (
          <Stack
            sx={{ width: 1, flexGrow: 1 }}
            direction="row"
            alignItems="center"
          >
            <Typography color="white" variant="h6">
              {title}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" justifyContent="end">
          <IconButton onClick={onClose}>
            <Cancel />
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
